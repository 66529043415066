import React, { useState } from 'react'
import RulesAnswer from './rulesAnswer/RulesAnswer'
import RulesQuestions from './rulesQuestions/RulesQuestions'
import s from "./RulesTab.module.css"
import menuData from './data.json';

const RulesTab = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const onSelectQuestion = (question) => {
        setSelectedQuestion(question);
    };

    const onQuestion = (item) => {
        setSelectedTitle(item)
      };

  return (
    <div className={s.container}>
        <div className={s.questions}>
            {menuData.questionsList.map((item, index) => (
                <RulesQuestions
                    key={index}
                    title={item.title}
                    questions={item.questions}
                    onSelectQuestion={onSelectQuestion}
                    onQuestion={onQuestion}
                    selectedQuestion={selectedQuestion}
                    selectedTitle={selectedTitle}
                />
            ))}
        </div>
        {selectedQuestion &&
            <div className={s.answer}>
                <RulesAnswer selectedQuestion={selectedQuestion} selectedTitle={selectedTitle} answers={menuData.answerList} />
            </div>
        }

    </div>
  )
}

export default RulesTab