import React, {useEffect, useState} from 'react';
import styles from './StatsHeaderChange.module.css'
import getSettingsAdminStatistics from "../../../api/settingsAdminList";
import { post_balancy_settings_upset } from "../../../api/balance";
import MyIcon from "../../UI/myIcon/MyIcon";
import ModalInfo from '../../modals/modalInfo/ModalInfo';
import { useToast } from 'rc-toastr';

const renderObj = {
    percent_for_team: {
        label: '% за команду',
        measure: '%'
    },
    sell_price: {
        label: 'Цена продажи',
        measure: '₽'
    },
    percent_for_referral: {
        label: '% за реферала',
        measure: '%'
    },
    exchange_price: {
        label: 'Цена обмена',
        measure: '₽'
    },
}

const textForModal = {
    sell_price: {
        title: "Цена продажи",
        text: "Цена продажи сейчас = ",
        placeholder: "Укажите новую цену",
        buttonText: "Изменить цену продажи",
    },
    percent_for_team: {
        title: "Изменить % за команду",
        text: "% за команду на данный момент = ",
        placeholder: "Укажите новый %",
        buttonText: "Изменить % за команду",
    },
    percent_for_referral: {
        title: "Изменить % за реферала",
        text: "% за рферала на данный момент = ",
        placeholder: "Укажите новый %",
        buttonText: "Изменить % за реферала",
    },
    exchange_price: {
        title: "Цена обмена",
        text: "Цена обмена сейчас = ",
        placeholder: "Укажите новую цену",
        buttonText: "Изменить цену обмена",
    },
}

const SharedStats = ({item}) => {
    return <div className={styles.sharedStats}>
        <MyIcon image={"miniLogo"} width={"27px"} height={"27px"} />
        <div>
            <span>1</span>
            <span>=</span>
            <span>{item.value}</span>
            <span style={{fontFamily: 'system-ui'}}>{renderObj?.[item.key]?.measure}</span>
        </div>
    </div>
}

const StatsHeaderChange = () => {
    const { toast } = useToast()
    const [stats, setStats] = useState([]);
    const [isModalInfo, setIsModalInfo] = useState(false)
    const [valueStat, setValueStat] = useState("")
    const [valueKey, setValueKey] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        title: "",
        text: "",
        placeholder: "",
        buttonText: ""
    })

    const openModalInfo = () => {
        setIsModalInfo(true);
      };
    
    const closeModalInfo = () => {
        setIsModalInfo(false);
    }; 

    const handleSubmit = async(val) => {
        if (isLoading) {
            return;
        }
        const response = {
            key: valueKey, 
            value: Number(val)
        }

        try {
            setIsLoading(true);
            await post_balancy_settings_upset(response)
            getSettingsStatistics()
        } catch (error) {
            toast.error("Ошибка при изменении!")
            console.error("handleSubmit", error)
        } finally {
            setIsLoading(false);
        }

        closeModalInfo()
        // title === "Удалить из команды" && deleteFromTeam()
        // title === "Пожаловаться" && reportToUser(val)
    }

    const getSettingsStatistics= async() => {
        try {
            const { data } = await getSettingsAdminStatistics();
            setStats(data.data);
        } catch (error) {
            console.error("getMainStatistics error", error)
        }
    }

    const editHandler= async(item) => {
        setValueKey(item)
        const takeValue = stats.filter((i) => i.key && i.key === item)[0]

        item === "sell_price" || item === "exchange_price" ? setValueStat(`${takeValue.value}₽`) : setValueStat(`${takeValue.value}%`)
        item === "sell_price" && setModalInfo(textForModal.sell_price) 
        item === "percent_for_team" && setModalInfo(textForModal.percent_for_team) 
        item === "percent_for_referral" && setModalInfo(textForModal.percent_for_referral) 
        item === "exchange_price" && setModalInfo(textForModal.exchange_price) 

        openModalInfo()
    }

    useEffect(() => {
        getSettingsStatistics()
    }, [])

    return (
        <ul className={styles.list}>
            {stats && stats.map((item) => (
                <li key={item.id} className={styles.card}>
                <h3 className={styles.cardTitle}>{renderObj[item.key].label}</h3>
                    <div className={styles.data}>
                        {item.key === 'sell_price' || item.key === 'exchange_price' ? <SharedStats item={item} /> : <div className={styles.statsItem}>
                            <p className={styles.count}>{item.value}</p>
                            {renderObj?.[item.key]?.measure && <p className={styles.count}>{renderObj?.[item.key].measure}</p>}
                        </div>}
                        <button onClick={() => editHandler(item.key)} className={styles.correctBtn}>
                            <MyIcon image={"edit"} width={"17px"} height={"17px"} />
                        </button>
                    </div>
            </li>))}

            {isModalInfo && valueStat && (
                <ModalInfo  
                    addInput
                    isOpen={openModalInfo} 
                    onClose={closeModalInfo}
                    title={modalInfo.title} 
                    text={`${modalInfo.text + valueStat}`} 
                    buttonText={modalInfo.buttonText} 
                    handleSubmit={handleSubmit}
                    placeholder={modalInfo.placeholder}
                />        
            )}
        </ul>
    );
};

export default StatsHeaderChange;