import React, { useEffect, useState } from 'react'
import MyIcon from '../UI/myIcon/MyIcon'
import s from "./Online.module.css"
import { user_online } from '../../api/user'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, setUserProfile } from '../../redux/slices/userSlice'

const Online = () => {
        const dispatch = useDispatch()
        const userInfoState = useSelector(selectUserProfile)
        const [isOnline, setIsOnline] = useState(null)
        const [isLoading, setIsLoading] = useState(false)

        useEffect(() => {
                userInfoState && userInfoState.online === 1 ? setIsOnline(true) : setIsOnline(false)
        }, [userInfoState])

        const changeStatus = async() => {
                if(isLoading) {
                        return
                }

                try {
                        const { data } = await user_online()
                        setIsLoading(true)
                        dispatch(setUserProfile(data.data))
                        setIsOnline(!isOnline)
                } catch (error) {
                        console.error(error)
                } finally {
                        setIsLoading(false)
                }
        }

        return (
        <div className={s.container}>
                <div className={s.settingsText}>СТАТУС НА СЕРВЕРЕ</div>
                        {isOnline ? (
                                <div className={s.online}>
                                        <div onClick={changeStatus} className={s.click}><MyIcon width={"44px"} height={"23px"} image={"online"}/></div>
                                        <div>Онлайн</div>
                                        <div className={s.info}>                
                                                <MyIcon 
                                                        image={"info"}
                                                        width={"24px"}
                                                        height={"24px"}
                                                />
                                        </div>
                                </div>
                        ) : (
                                <div className={s.online}>
                                        <div onClick={changeStatus} className={s.click}><MyIcon width={"44px"} height={"23px"} image={"offline"}/></div>
                                        <div>Офлайн</div>
                                        <div className={s.info}>                
                                                <MyIcon 
                                                        image={"info"}
                                                        width={"24px"}
                                                        height={"24px"}
                                                />
                                        </div>
                                </div>
                        )}
        </div>
        )
}

export default Online