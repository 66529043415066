import CreateUser from "../../components/createUser/CreateUser";


const Register = () => {

    return (
        <div style={{flex: 1}}>
            <CreateUser />
        </div>
    )
}

export default Register;