import React, { useState } from 'react'
import s from "./CreateCommands.module.css"
import MyIcon from '../../../UI/myIcon/MyIcon'
import MyButton from '../../../UI/myButton/MyButton'
import { post_create_team } from '../../../../api/referral'
import { validateReferralCode } from '../../../../settings/validation'
import { useToast } from 'rc-toastr'

const CreateCommands = ({ setRatesListIsUpdate, setHasTeam, getTeams }) => {
  const { toast } = useToast()
  const [teamName, setTeamName] = useState("")
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const onHandelSubmit = async () => {
    if (isLoading) {
      return
    }

    if (teamName.trim().length === 0) {
      setErrors("Введите название")
      return
    }
    try {
      setIsLoading(true)
      await post_create_team({name: teamName})
      setHasTeam(true)
      setRatesListIsUpdate(true)
      getTeams()
    } catch (error) {
      console.error("Create teams ", error)
      error.response.data.message ===
      "Access denied. Your account is not verified."
        ? toast.error("Ваша анкета на проверке")
        : toast.error(error.response.data.message);
        setTeamName("")
    } finally {
      setIsLoading(false)
      setTeamName("")
    }
  }
  
  const onKeyDown = (e) => {
    const value = e.target.value;
    setTeamName(value);
    validateReferralCode(value, setErrors);
  }

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.headerName}>
              <MyIcon image={"createTeams"} width={"18px"} height={"18px"} />
              Создание команды
          </div>
          <div>
              <MyIcon image={"info"} width={"24px"} height={"24px"} />
          </div>
        </div>        
      </div>

      <div className={s.wrapperMain}>
        <div className={s.main}>
            <div>Название команды</div>
            <div className={s.input}>
                <input
                  placeholder={"Введите название вашей команды"}
                  width={"100%"}
                  className={s.inputSettings}
                  value={teamName}
                  onChange={(e) => onKeyDown(e)} 
                />              
                <div className={s.icon}>
                  <MyIcon image={"changeFill"} width={"18px"} height={"18px"} />
                </div>
                {errors && <div className={s.error}>{errors}</div>}

            </div>
            <div className={s.button}>
              <MyButton onHandelSubmit={onHandelSubmit} text={"Создать команду"} width={"100%"} />
            </div>
        </div>
      </div>

    </div>
  )
}

export default CreateCommands