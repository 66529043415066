import React, { useEffect } from 'react';
import s from './ReferralTab.module.css';
import ReferralBalance from './firstBlockReferral/referralBalance/ReferralBalance';
import ReferralInfo from './firstBlockReferral/referralInfo/ReferralInfo';
import CreateCommands from './secondBlockReferral/createCommands/CreateCommands';
import CommandsList from './secondBlockReferral/commandsList/CommandsList';
import ReferralRates from './thirdBlockReferral/referralRates/ReferralRates';
import ReferralWinner from './thirdBlockReferral/referralWinner/ReferralWinner';
import { useState } from 'react';
import {
  get_my_team,
  get_referral_balance,
  get_team_list,
} from '../../api/referral';

const ReferralTab = () => {
  const [balanceCount, setBalanceCount] = useState(0);
  const [balanceListIsUpdate, setBalanceListIsUpdate] = useState(false);
  const [teamsListIsUpdate, setTeamsListIsUpdate] = useState(false);
  const [ratesListIsUpdate, setRatesListIsUpdate] = useState(false);
  const [referralOpen, setReferralOpen] = useState(true);
  const [myTeam, setMyTeam] = useState({});
  const [team, setTeam] = useState([]);
  const [hasTeam, setHasTeam] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getBalance = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await get_referral_balance();
      data.data && setBalanceCount(data.data.referralBalance);
    } catch (error) {
      console.error('Get referral balance ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTeams = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await get_my_team();
      setMyTeam(data.data);
      setHasTeam(true);
    } catch (error) {
      if (error.response.data.message === 'User is not a member of any team') {
        setMyTeam({});
        setHasTeam(false);
        getTeamsList();
        return false;
      }
      console.error('Get getTeams ', error);
      setMyTeam({});
      getTeamsList();
      setHasTeam(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getTeamsList = async () => {
    try {
      const { data } = await get_team_list();
      setTeam(data.data);
    } catch (error) {
      console.error('Get getTeams ', error);
    }
  };

  useEffect(() => {
    getBalance();
    getTeams();
  }, []);

  useEffect(() => {
    getBalance();
    getTeams();
  }, [hasTeam]);

  return window.innerWidth >= 1100 ? (
    <div className={s.container}>
      <div className={s.blockFirst}>
        <div className={s.referralBalance}>
          <ReferralBalance
            balanceCount={balanceCount}
            balanceListIsUpdate={balanceListIsUpdate}
            setBalanceListIsUpdate={setBalanceListIsUpdate}
          />
        </div>
        <div className={s.referralInfo}>
          <ReferralInfo />
        </div>
      </div>
      <div className={s.blockSecond}>
        {!hasTeam && !isLoading && (
          <div className={s.createCommands}>
            <CreateCommands
              setRatesListIsUpdate={setRatesListIsUpdate}
              getTeams={getTeams}
              setHasTeam={setHasTeam}
            />
          </div>
        )}
        <div className={hasTeam ? s.commandsList : s.commandsListNotTeam}>
          <CommandsList
            teamsListIsUpdate={teamsListIsUpdate}
            setTeamsListIsUpdate={setTeamsListIsUpdate}
            setRatesListIsUpdate={setRatesListIsUpdate}
            team={team}
            hasTeam={hasTeam}
            getTeams={getTeams}
            setHasTeam={setHasTeam}
            myTeam={myTeam}
          />
        </div>
      </div>
      <div className={s.blockThird}>
        <div className={s.referralRates}>
          <ReferralRates
            setRatesListIsUpdate={setRatesListIsUpdate}
            ratesListIsUpdate={ratesListIsUpdate}
          />
        </div>
        <div className={s.referralWinner}>
          <ReferralWinner />
        </div>
      </div>
    </div>
  ) : (
    <div className={s.mediaLayout}>
      <div className={s.buttons}>
        <div
          onClick={() => setReferralOpen(true)}
          className={`${referralOpen ? s.activeButton : s.button}`}
        >
          Реферальная программа
        </div>
        <div className={s.lineContainer}>
          <div className={s.line}></div>
        </div>
        <div
          onClick={() => setReferralOpen(false)}
          className={`${!referralOpen ? s.activeButton : s.button}`}
        >
          Команды
        </div>
      </div>
      {referralOpen ? (
        <div className={s.container}>
          <div className={s.blockFirst}>
            <div className={s.referralInfo}>
              <ReferralInfo />
            </div>
            <div className={s.referralBalance}>
              <ReferralBalance
                balanceCount={balanceCount}
                balanceListIsUpdate={balanceListIsUpdate}
                setBalanceListIsUpdate={setBalanceListIsUpdate}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <div className={s.blockSecond}>
            {!hasTeam && !isLoading && (
              <div className={s.createCommands}>
                <CreateCommands getTeams={getTeams} setHasTeam={setHasTeam} />
              </div>
            )}
            <div className={s.commandsList}>
              <CommandsList
                team={team}
                hasTeam={hasTeam}
                getTeams={getTeams}
                setHasTeam={setHasTeam}
                myTeam={myTeam}
              />
            </div>
          </div>
          <div className={s.blockThird}>
            <div className={s.referralRates}>
              <ReferralRates
                setRatesListIsUpdate={setRatesListIsUpdate}
                ratesListIsUpdate={ratesListIsUpdate}
              />
            </div>
            <div className={s.referralWinner}>
              <ReferralWinner />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralTab;
