import React, { useState, useEffect, useRef } from "react";
import s from "./PaginationHistory.module.css";
import { formatDateTime } from "../../../settings/formatDate";

import MyIcon from "../../UI/myIcon/MyIcon"
import { getBalancyHistory } from "../../../api/balance";

const PaginationHistory = ({ transactions, setTransactions }) => {
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
    container.addEventListener('scroll', handleScroll);

    return () => {
        container.removeEventListener('scroll', handleScroll);
    };
    }
  }, [page, pageLast, transactions]);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
        timer = setTimeout(() => {
        loadTransactions()
        }, 300);
    } 
    }

    return () => {
        clearTimeout(timer);
    };
  };

  const loadTransactions = async () => {
    try {
        setIsLoading(true);
        const { data } = await getBalancyHistory(page);
        setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
        setPage((prevPage) => prevPage + 1);
        setPageLast(data.pagination.lastPage);
        setIsLoading(false);
    } catch (error) {
        console.error("getBalancyExchangeHistory error", error)
    }
  };

  useEffect(() => {
    loadTransactions()
  }, [])

  return (
    <div ref={containerRef} className={s.paginationHistory}>
        {transactions.map((item, index) => (
            <div
                className={`${s.container} ${index % 2 === 0 ? s.evenBackground : s.oddBackground}`}
                key={item.id}
            >
                <div className={s.method}>{item.method.name}</div>
                <div className={s.count}>
                    <MyIcon
                        image={"miniLogo"} 
                        width={"19"}
                        height={"19"}
                    />
                    <div>x</div>
                    <div>{item.amount.toFixed(2)}</div>
                </div>
                <div>{formatDateTime(item.createdAt)}</div>
            </div>
        ))}
    </div>
  );
};

export default PaginationHistory;
