import React, {useState} from 'react'
import s from "./History.module.css"
import MyIcon from '../../UI/myIcon/MyIcon'
import PaginationHistory from '../paginationHistory/PaginationHistory'
import {balancy_history_filter} from '../../../api/balance'
import {truncateText} from '../../../settings/helpers'
import {tabsSwitcher} from "./tabsSwitcher/tabsSwitcher";

const History = () => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("");

    const filters = {
        "Все": "all",
        "Подарок": "gift",
        "Приватный чат": "private_chat",
        "Рефералы": "refferal",
        "Команды": "team"
    };

    const filterRequest = async (filter) => {
        const filterReq = filter && filter === "all" ? "" : filter !== "all" ? filter : ""

        const request = {
            filter: filterReq,
        }

        try {
            const {data} = await balancy_history_filter(request)
            if (data && data.data) {
                setTransactions(data.data)
            }
        } catch (error) {
            console.error("filterRequest", error)
            setTransactions([])
        }
    }

    const handleFilterSelect = (filter) => {
        setSelectedFilter(filter);
        setFilterVisible(false);
        filterRequest(filter)
    };

    return (
        <div className={s.container}>
            <div className={s.header}>
                <div className={s.headerTitle}>
                    <MyIcon
                        image={"down"}
                        width={"20px"}
                        height={"20px"}
                    />
                    <div>История начислений</div>
                </div>
                <div className={`${s.filter} ${filterVisible ? s.filterVisible : s.filterNotVisible}`}
                     onClick={() => setFilterVisible(!filterVisible)}>
                    {selectedFilter ? truncateText(Object.keys(filters).find(key => filters[key] === selectedFilter), 8) : "Все"}
                    <MyIcon
                        image={filterVisible ? "upArrow" : "downArrow"}
                        width={"16px"}
                        height={"16px"}
                    />

                    {filterVisible && (
                        <div className={s.filterList}>
                            {Object.keys(filters).map((filter) => (
                                <div
                                    key={filters[filter]}
                                    className={selectedFilter === filters[filter] ? s.selectedFilter : s.filterItem}
                                    onClick={() => handleFilterSelect(filters[filter])}
                                >
                                    {filter}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={s.headerSecond}>
                <div>Способ</div>
                <div>Сумма</div>
                <div>Дата и время</div>
            </div>
            <PaginationHistory transactions={transactions} setTransactions={setTransactions}/>
        </div>
    )
}

export default History