
// user 
export const GET_STATISTICS = "users/statistic";
export const REGISTRATION = "/auth/register";
export const REGISTRATION_CONFIRM = "/auth/confirm";
export const LOGIN = "/auth/login";
export const USER_INFO = "/users/profile";
export const USER_EDIT = "/users/edit";
export const USER_VERIFY = "users/verify/request";
export const ADD_AVATAR = "/users/avatar";
export const CHANGE_PASSWORD = "users/edit/password";
export const FORGOT_PASSWORD = "users/password-link";
export const RESET_PASSWORD = "users/reset-password";
export const CHANGE_EMAIL = "users/edit/email";
export const TAKE_OTHER_USER = "users/";
export const USER_ONLINE = "users/online";

//notifications
export const NOTIFICATIONS = "notifications/types";
export const NOTIFICATION_ALL = "notifications/type/all";
export const NOTIFICATION_GIFTS = "notifications/type/gifts";
export const NOTIFICATION_INVITATIONS = "notifications/type/invitations";
export const NOTIFICATION_REMINDERS = "notifications/type/reminders";
export const NOTIFICATION_COMPLAINTS = "notifications/type/complaints";
export const NOTIFICATION_DELETE = "notifications";
export const NOTIFICATIONS_COUNT = "notifications/unread/count";

//schedule
export const SCHEDULE_TIME_CANCEL = "schedule/time/cancel-booking";
export const SCHEDULE_TIME_BOOK = "schedule/time/book";
export const SCHEDULE_LIST = "schedule/list";

//balance
export const BALANCY_HISTORY = "balance/history";
export const BALANCY_USER = "balance/";
export const BALANCY_EXCHANGE = "balance/exchange";
export const BALANCY_WITHDRAWALS_HISTORY = "balance/withdrawal/history";
export const BALANCY_EXCHANGE_HISTORY = "balance/exchange/history";
export const BALANCY_EXCHANGE_WITHDRAWALS = "balance/withdrawals";
export const BALANCY_REFERRAL = "balance/referral";

//others
export const BALANCY_SETTINGS_UPSET = "settings/upsert";
export const BALANCY_SHOW_KEY = "settings/show/";

//chats
export const CHAT_LIST = "chats/list";
export const CHAT_CREATE = "chats/create";
export const CHAT_ID = "chats/";
export const CHATS_COUNT = "chats/unread/count";
export const CHATS_FILTER = "chats/list";
export const CHAT_PUBLIC = "chats/public";
export const CHECK_FRIEND = "friends/check";
export const FRIEND_DEL = "friends/delete";
export const FRIEND_ADD = "friends/request";

//admin
export const ADMIN_MAIN_STATISTICS = "admin/users/main-statistic";
export const ADMIN_MAIN_STATISTICS_SETTINGS = "settings/list";
export const ADMIN_LIST_HOSTES = "admin/users/statistics";
export const ADMIN_LIST_GAMERS = "/admin/balance/gamer/history";
export const ADMIN_LIST_GAMERS_EXPORT = "/admin/balance/export/gamer/history";
export const ADMIN_LIST_HOSTES_EXPORT = "/admin/users/statistics/export";

//teams
export const TEAMS_LIST = "/teams/list";
export const TEAMS_TOP_CURRENT_MONTH = "/teams/list/top-current-month";
export const TEAMS_TOP_LAST_MONTH = "/teams/list/top-last-month";
export const TEAMS_APLICATION_NEW = "/teams/applications/new";
export const TEAMS_MY = "/teams/my";
export const TEAMS_CREATE = "/teams";  //POST DELETE
export const TEAMS_JOIN = "/teams/apply-to-join";
export const TEAMS_REQUEST_JOIN = "/teams/applications/new";
export const TEAMS_COMPLAINTS = "/complaints/create/team";
export const TEAMS_USER_DEL = "/teams/member";
export const TEAMS_REFERRAL_COUNTS = "/users/referrals/count";

//video
export const VIDEO_ACTIVE_ID = "/videostream/active";
export const VIDEO_ACTIVE = "videochat/active";
export const VIDEO_REINVITE = "videochat/reinvite/request";

