import s from "../ChatPrivate.module.css";
import usernameImg from "../../../../images/username.svg";
import MyIcon from "../../../UI/myIcon/MyIcon";
import { isLink } from "../../../../settings/helpers";
import React, { useEffect, useRef, useState } from "react";
import { SubMenu } from "../../../subMenu/SubMenu";
import ModalInfo from "../../../modals/modalInfo/ModalInfo";
import { post_create_team_complaints } from "../../../../api/referral";

export const MsgItem = ({ userName, text, senderId, isLastMsg, setPrivateChatRoute, setPublicChatSelected, setChatId, setUserName,  getMessageInfo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalComplainingOpen, setModalComplainingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submenuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModalInfo = () => {
    setModalComplainingOpen(true);
  };

  const closeModalInfo = () => {
    setModalComplainingOpen(false);
  };

  const reportToUser = async (val) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await post_create_team_complaints({
        type: "Пожаловаться",
        userId: senderId,
        text: val,
      });
    } catch (error) {
      console.error("reportToUser ", error);
    } finally {
      setIsLoading(false);
      setModalComplainingOpen(false);
    }
  };

  return (
    <div className={`${s.messagePublic}`}>
      {isMenuOpen && (
        <SubMenu
          setIsOpenMiniMenu={setIsMenuOpen}
          isPublic
          userId={senderId}
          userName={userName}
          setModalComplainingOpen={setModalComplainingOpen}
          submenuRef={submenuRef}
          setPrivateChatRoute={setPrivateChatRoute}
          isLastMsg={isLastMsg}
          setPublicChatSelected={setPublicChatSelected}
          setChatId={setChatId}
          setUserName={setUserName}
          getMessageInfo={getMessageInfo}
        />
      )}

      {modalComplainingOpen && (
        <ModalInfo
          isOpen={openModalInfo}
          onClose={closeModalInfo}
          title={"Пожаловаться"}
          text={`Укажите причину жалобы на участницу ${userName}`}
          buttonText={"Пожаловаться"}
          handleSubmit={reportToUser}
          placeholder={"Опишите свою жалобу"}
        />
      )}

      <div className={s.publicItem}>
        <div className={s.headerPublicInChat}>
          <div className={s.userNamePublicInChat}>
            <img src={usernameImg} alt="usernameImg" />
            <div>{userName}</div>
          </div>
          <div
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ cursor: "pointer" }}
          >
            <MyIcon image={"public_chat_menu"} width={"21px"} height={"21px"} />
          </div>
        </div>
        <div className={s.adaptTextBlock}>
          {isLink(text) ? <a href={text}>{text}</a> : text}
        </div>
      </div>
    </div>
  );
};
