import React, { useState, useEffect, useRef } from "react";
import { formatDateTime } from "../../../settings/formatDate";

import s from "./PaginationHistoryExchange.module.css"
import MyIcon from "../../UI/myIcon/MyIcon";
import { getBalancyExchangeHistory } from "../../../api/balance";

const PaginationHistoryExchange = ({ getExchangeHistoryData, setGetExchangeHistoryData }) => {
  const containerRef = useRef(null);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
 
  useEffect(() => {
    if (getExchangeHistoryData) {
      setPage(1);
      setTransactions([])
      loadTransactions()
    }
  }, [getExchangeHistoryData])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
    container.addEventListener('scroll', handleScroll);

    return () => {
        container.removeEventListener('scroll', handleScroll);
    };
    }
  }, [page, pageLast, transactions]);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
        timer = setTimeout(() => {
        loadTransactions()
        }, 300);
    } 
    }

    return () => {
        clearTimeout(timer);
    };
  };

  const loadTransactions = async () => {
    try {
        setIsLoading(true);
        const { data } = await getBalancyExchangeHistory(getExchangeHistoryData ? 1 : page);
        setIsLoading(false);
        setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
        setPage((prevPage) => prevPage + 1);
        setPageLast(data.pagination.lastPage);
        setGetExchangeHistoryData(false)
    } catch (error) {
        console.error("getBalancyExchangeHistory error", error)
    }
  };

  useEffect(() => {
    loadTransactions()
  }, [])


  return (
    <div ref={containerRef} className={s.paginationExchangeHistory}>
        {transactions.map((item, index) => (
            <div
                className={`${s.container} ${index % 2 === 0 ? s.evenBackground : s.oddBackground}`}
                key={index}
            >
                <div className={s.count}>

                    <MyIcon
                        image={"miniLogo"} 
                        width={"19"}
                        height={"19"}
                    />
                    <div>x</div>
                    <div>{item.hearts}</div>
                </div>
                <div className={s.rightArrowRound}>
                    <MyIcon 
                      image={"rightArrowRound"}
                      width={"20px"}
                      height={"20px"}
                    />
                </div>
                <div className={s.countDol}>
                  <p
                    style={{
                      fontFamily: "system-ui",
                      color: "#FADC77",
                    }}
                  >
                    ₽
                  </p>
                    <div>{item.dollars.toFixed(2)}</div>
                </div>
                <div className={s.data}>{formatDateTime(item.createdAt)}</div>
            </div>
        ))}
    </div>
  );
};

export default PaginationHistoryExchange;
