import styles from './styles.module.css'

export const TabsSwitcher = ({step, setStep}) => {
    return (
        <div className={styles.wrap}>
            <div onClick={() => setStep(0)} className={`${step === 0 ? styles.active : ''} ${styles.item}`}>Баланс</div>
            <span></span>
            <div onClick={() => setStep(1)} className={`${step === 1 ? styles.active : ''} ${styles.item}`}>Начисления
            </div>
            <span></span>
            <div onClick={() => setStep(2)} className={`${step === 2 ? styles.active : ''} ${styles.item}`}>Вывод</div>
        </div>
    )
}