import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import s from "./UserProfileChange.module.css"

import {Field, Form, Formik} from "formik";
import {useEffect, useRef, useState} from "react";
import {fillUserValidate} from "../../../settings/validation";
import {add_avatar, user_edit, user_info, user_verify} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import RatesUser from "../../ratesUser/RatesUser";
import {useToast} from "rc-toastr";
import {TooltipIcon} from "../../tooltipIcon/tooltipIcon";

const fillUserSchema = fillUserValidate;
const MAX_ABOUT_CHARACTER_LIMIT = 120;

const UserProfileChange = () => {
    const {toast} = useToast()
    const navigate = useNavigate()
    const fileInputRef = useRef(null);
    const [userInterests, setUserInterests] = useState([])
    const [userAvatar, setUserAvatar] = useState(null)
    const [userCamera, setUserCamera] = useState(null)
    const [userRules, setUserRules] = useState(null)
    const [userMicrophone, setUserMicrophone] = useState(null)
    const [userRatis, setUserRatis] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [aboutCharacterCount, setAboutCharacterCount] = useState(0);
    const [fillUserErrors, setFillUserErrors] = useState({
        name: '',
        telegram: '',
        about: '',
        likes1: "",
        likes2: "",
        likes3: "",
        likes4: "",
        likes5: "",
        likes6: "",
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        telegram: '',
        about: '',
        likes1: '',
        likes2: '',
        likes3: '',
        likes4: '',
        likes5: '',
        likes6: '',
    });

    const goToUserInfo = () => {
        navigate("/user/user-info")
    }

    const requestUserInfo = async () => {
        try {
            const {data} = await user_info()
            const {
                username,
                telegram,
                about_me,
                interests,
                avatar,
                hasCamera,
                hasMicrophone,
                familiarWithRules,
            } = data.data;

            const likes = JSON.parse(interests)
            setUserAvatar(avatar)
            setUserInterests(likes);
            setUserRatis(data.data.rating)
            setInitialValues({
                name: username || '',
                telegram: telegram || '',
                about: about_me || '',
                likes1: likes.likes1 || '',
                likes2: likes.likes2 || '',
                likes3: likes.likes3 || '',
                likes4: likes.likes4 || '',
                likes5: likes.likes5 || '',
                likes6: likes.likes6 || '',
            });
            setUserMicrophone(hasMicrophone === 1 ? true : false)
            setUserCamera(hasCamera === 1 ? true : false)
            setUserRules(familiarWithRules === 1 ? true : false)
        } catch (error) {
            console.error("error userInfo get")
        }
    };

    const userInfoHandler = (values) => {
        if (isLoading) {
            return Promise.reject("Already loading");
        }

        const {
            name,
            telegram,
            about,
            likes1,
            likes2,
            likes3,
            likes4,
            likes5,
            likes6,
        } = values;

        const dataLikes = {
            likes1,
            likes2,
            likes3,
            likes4,
            likes5,
            likes6
        };

        const likesValues = [likes1, likes2, likes3, likes4, likes5, likes6];
        const nonEmptyLikesCount = likesValues.filter(like => like.trim() !== '').length;

        if (nonEmptyLikesCount < 3) {
            setFillUserErrors(prevErrors => ({
                ...prevErrors,
                likes1: "Заполните хотя бы три поля 'Интересы'",
                likes2: "Заполните хотя бы три поля 'Интересы'",
                likes3: "Заполните хотя бы три поля 'Интересы'",
            }));
            return Promise.reject("Not enough interests filled");
        }

        const requestVal = {
            "username": name,
            "telegram": telegram,
            "online": 1,
            "about_me": about,
            "interests": JSON.stringify(dataLikes),
            "hasCamera": userCamera ? 1 : 0,
            "hasMicrophone": userMicrophone ? 1 : 0,
            "familiarWithRules": userRules ? 1 : 0
        };

        setIsLoading(true);

        return Promise.all([
            user_edit(requestVal),
            user_verify()
        ])
            .then(([editResponse, verifyResponse]) => {
                setIsLoading(false);
                navigate("/user/user-info");
            })
            .catch((error) => {
                console.error("error userInfo change", error);
                setIsLoading(false);
                throw error;
            });
    };

    // const userInfoHandler = async (values) => {
    //     if (isLoading) {
    //         return
    //     }

    //     const {
    //         name,
    //         telegram,
    //         about,
    //         likes1,
    //         likes2,
    //         likes3,
    //         likes4,
    //         likes5,
    //         likes6,
    //     } = values;

    //     const dataLikes = {
    //         likes1,
    //         likes2,
    //         likes3,
    //         likes4,
    //         likes5,
    //         likes6
    //       };

    //     const likesValues = [likes1, likes2, likes3, likes4, likes5, likes6];
    //     const nonEmptyLikesCount = likesValues.filter(like => like.trim() !== '').length;

    //     if (nonEmptyLikesCount < 3) {
    //         setFillUserErrors(prevErrors => ({
    //             ...prevErrors,
    //             likes1: "Заполните хотя бы три поля 'Интересы'",
    //             likes2: "Заполните хотя бы три поля 'Интересы'",
    //             likes3: "Заполните хотя бы три поля 'Интересы'",
    //         }));
    //         return;
    //     }

    //     const requestVal = {
    //         "username": name,
    //         "telegram": telegram,
    //         "online": 1,
    //         "about_me": about,
    //         "interests": JSON.stringify(dataLikes),
    //         "hasCamera": userCamera ? 1 : 0,
    //         "hasMicrophone": userMicrophone ? 1 : 0,
    //         "familiarWithRules": userRules ? 1 : 0
    //     }

    //     try {
    //         setIsLoading(true)
    //         await user_edit(requestVal)
    //         await user_verify()
    //         navigate("/user/user-info")   
    //     } catch (error) {
    //       console.error("error userInfo change", error)
    //     } finally {
    //         setIsLoading(false)
    //     }
    // };

    const changeHandler = () => {
        setFillUserErrors({
            name: '',
            telegram: '',
            about: '',
            likes1: "",
            likes2: "",
            likes3: "",
            likes4: "",
            likes5: "",
            likes6: "",
        });
    }

    const addAvatar = () => {
        fileInputRef.current.click();
    }

    const handleFileUpload = async (e) => {
        if (isLoading) {
            return
        }
        const file = e.target.files[0];

        try {
            setIsLoading(true)
            const {data} = await add_avatar({file})
            toast.success("Аватарка загружается...")
            setUserAvatar(data.data.link)
        } catch (error) {
            console.error("error", error)
        } finally {
            setIsLoading(false)
        }
    };

    const checkIfAnyKeyHasValue = (obj) => {
        for (const key in obj) {
            if (obj[key] !== "") {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        initialValues.name === "" && requestUserInfo()
    }, [initialValues]);

    return (
        <div className={s.container}>
            <div className={s.header}>
                <div className={s.textHeader}>Профиль</div>
                <TooltipIcon
                    image={"info"}
                    width={"25px"}
                    height={"24px"}
                    position={'bottom'}
                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                />
            </div>

            <div className={s.avatarStar}>
                <div className={s.avatar}>
                    {userAvatar ? (
                        <div className={s.avatarAva}>
                            {window.innerWidth >= 821 ? (
                                <img
                                    style={{objectFit: 'cover'}}
                                    src={userAvatar}
                                    alt="avatar"
                                />
                            ) : (
                                <img
                                    style={{objectFit: 'cover'}}
                                    src={userAvatar}
                                    alt="avatar"
                                />
                            )}

                        </div>
                    ) : (
                        <div className={s.avatarImg}>
                            {window.innerWidth >= 821 ? (
                                <MyIcon
                                    image={"avatar"}
                                    width={"81.5px"}
                                    height={"81.5px"}
                                />
                            ) : (
                                <MyIcon
                                    image={"avatar"}
                                    width={"51.5px"}
                                    height={"51.5px"}
                                />
                            )}

                        </div>
                    )}


                    <div onClick={addAvatar} className={s.avatarAdd}>
                        {window.innerWidth >= 821 ? (
                            <>
                                <input
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={handleFileUpload}
                                    accept="image/*"
                                    ref={fileInputRef}
                                />
                                <MyIcon
                                    image={"addAvatar"}
                                    width={"27px"}
                                    height={"27px"}
                                />
                            </>

                        ) : (
                            <MyIcon
                                image={"addAvatar"}
                                width={"19px"}
                                height={"19px"}
                            />
                        )}

                    </div>
                </div>

                <RatesUser userInfoRates={userRatis}/>
            </div>

            {initialValues.name && checkIfAnyKeyHasValue(userInterests) &&
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={fillUserSchema}
                        onSubmit={values => userInfoHandler(values)}
                    >
                        {({errors, touched, values}) => {

                            let err = {
                                name: '',
                                telegram: '',
                                about: '',
                                likes1: '',
                                likes2: '',
                                likes3: '',
                                likes4: '',
                                likes5: '',
                                likes6: '',
                            };

                            if (fillUserErrors.likes1 || fillUserErrors.likes2 || fillUserErrors.likes3 || fillUserErrors.likes4 || fillUserErrors.likes5 || fillUserErrors.likes6 || fillUserErrors.name || fillUserErrors.telegram || fillUserErrors.about) {
                                err = fillUserErrors
                            } else {
                                err = errors
                            }

                            const nameError = err.name && touched.name;
                            const telegramError = err.telegram && touched.telegram;
                            const aboutError = err.about && touched.about;
                            const likes1Error = err.likes1 && touched.likes1;
                            const likes2Error = err.likes2 && touched.likes2;
                            const likes3Error = err.likes3 && touched.likes3;
                            const likes4Error = err.likes4 && touched.likes4;
                            const likes5Error = err.likes5 && touched.likes5;
                            const likes6Error = err.likes6 && touched.likes6;

                            return (
                                <Form>
                                    <div className={s.userFill}>
                                        <div className={s.inputsBlock}>
                                            <div className={s.input}>
                                                <div className={s.header}>
                                                    <div className={s.textInput}>Имя</div>
                                                    <TooltipIcon
                                                        image={"info"}
                                                        text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                    />
                                                </div>
                                                <div className={s.settingsIcon}>
                                                    <MyInput
                                                        type="text"
                                                        name="name"
                                                        placeholder="Введите имя"
                                                        // values={values.name}
                                                        label={'Имя'}
                                                        isError={nameError}
                                                        errorText={err.name}
                                                        resetErrors={changeHandler}
                                                        width={"100%"}
                                                        color={"#475063"}
                                                    />
                                                    <div className={s.iconInInput}>
                                                        <MyIcon
                                                            image={"inputName"}
                                                            width={"24px"}
                                                            height={"24px"}
                                                        />
                                                    </div>
                                                </div>
                                                {touched.name && errors.name && <div
                                                    style={{color: 'darkred', marginTop: "5px"}}>{errors.name}</div>}
                                            </div>


                                            <div className={s.input}>
                                                <div className={s.header}>
                                                    <div className={s.textInput}>Telegram</div>
                                                    <TooltipIcon
                                                        text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                        image={"info"}
                                                    />
                                                </div>
                                                <div className={s.settingsIcon}>
                                                    <MyInput
                                                        type="text"
                                                        name="telegram"
                                                        placeholder="Ваше имя пользователя"
                                                        values={values.name}
                                                        label={'Telegram'}
                                                        isError={telegramError}
                                                        errorText={err.telegram}
                                                        resetErrors={changeHandler}
                                                        width={"100%"}
                                                        color={"#475063"}
                                                    />
                                                    <div className={s.iconInInput}>

                                                        <TooltipIcon
                                                            image={"inputTelegram"}
                                                            width={"24px"}
                                                            height={"24px"}
                                                            text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                        />
                                                    </div>
                                                </div>
                                                {touched.telegram && errors.telegram && <div style={{
                                                    color: 'darkred',
                                                    marginTop: "5px"
                                                }}>{errors.telegram}</div>}
                                            </div>

                                        </div>

                                        <div className={s.about}>
                                            <div className={s.header}>
                                                <div className={s.textInput}>О себе</div>
                                                <TooltipIcon
                                                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                    image={"info"}
                                                />
                                            </div>
                                            <MyInput
                                                type="textarea"
                                                name="about"
                                                placeholder="Придумайте свою уникальную особенность &quot;Приношу удачу&quot;, &quot;Со мной не соскучишься&quot;"
                                                label={"О себе"}
                                                isError={aboutError}
                                                errorText={err.about}
                                                resetErrors={changeHandler}
                                                width={"100%"}
                                                color={"#475063"}
                                                height={"90px"}
                                                component={'textarea'}
                                                rows={'4'}
                                                isMaxLength={MAX_ABOUT_CHARACTER_LIMIT}
                                                onKeyDown={(e) => {
                                                    if (e.target.value.length <= MAX_ABOUT_CHARACTER_LIMIT) {
                                                        setInitialValues({...initialValues, about: e.target.value});
                                                        setAboutCharacterCount(e.target.value.length);
                                                    }
                                                }}
                                            />
                                            {touched.about && errors.about &&
                                                <div style={{color: 'darkred', marginTop: "5px"}}>{errors.about}</div>}
                                            {!errors.about && <div
                                                className={s.limit}>{`${aboutCharacterCount} / ${MAX_ABOUT_CHARACTER_LIMIT}`}</div>}
                                        </div>

                                        {window.innerHeight >= 821 ? (
                                            <div className={s.likes}>
                                                <div className={s.header}>
                                                    <div className={s.textInput}>Интересы</div>
                                                    <TooltipIcon
                                                        text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                        image={"info"}
                                                    />
                                                </div>
                                                <div className={s.likesInputs}>
                                                    <div className={s.likesInput}>
                                                        <MyInput
                                                            type="text"
                                                            name="likes1"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes1Error}
                                                            errorText={err.likes1}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes2"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes2Error}
                                                            errorText={err.likes2}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes3"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes3Error}
                                                            errorText={err.likes3}
                                                        />
                                                    </div>
                                                    {((touched.likes1 && errors.likes1) || (touched.likes2 && errors.likes2) || (touched.likes3 && errors.likes3))
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{errors.likes1 ? errors.likes1 : errors.likes2 ? errors.likes2 : errors.likes3 ? errors.likes3 : null}</span>}

                                                    {(fillUserErrors.likes1 || fillUserErrors.likes2 || fillUserErrors.likes3)
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{fillUserErrors.likes1 ? fillUserErrors.likes1 : fillUserErrors.likes2 ? fillUserErrors.likes2 : fillUserErrors.likes3 ? fillUserErrors.likes3 : null}</span>}


                                                    <div className={s.likesInput}>
                                                        <MyInput
                                                            type="text"
                                                            name="likes4"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes4Error}
                                                            errorText={err.likes4}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes5"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes5Error}
                                                            errorText={err.likes5}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes6"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"32%"}
                                                            isError={likes6Error}
                                                            errorText={err.likes6}
                                                        />
                                                    </div>
                                                    {((touched.likes4 && errors.likes4) || (touched.likes5 && errors.likes5) || (touched.likes6 && errors.likes6))
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{errors.likes4 ? errors.likes4 : errors.likes5 ? errors.likes5 : errors.likes6 ? errors.likes6 : null}</span>}

                                                    {(fillUserErrors.likes4 || fillUserErrors.likes5 || fillUserErrors.likes6)
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{fillUserErrors.likes4 ? fillUserErrors.likes4 : fillUserErrors.likes5 ? fillUserErrors.likes5 : fillUserErrors.likes6 ? fillUserErrors.likes6 : null}</span>}

                                                </div>
                                            </div>
                                        ) : (
                                            <div className={s.likes}>
                                                <div className={s.header}>
                                                    <div className={s.textInput}>Интересы</div>
                                                    <TooltipIcon
                                                        text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                        image={"info"}
                                                    />
                                                </div>
                                                <div className={s.likesInputs}>
                                                    <div className={s.likesInput}>
                                                        <MyInput
                                                            type="text"
                                                            name="likes1"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes1Error}
                                                            errorText={err.likes1}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes2"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes2Error}
                                                            errorText={err.likes2}
                                                        />
                                                    </div>
                                                    {((touched.likes1 && errors.likes1) || (touched.likes2 && errors.likes2))
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{errors.likes1 ? errors.likes1 : errors.likes2 ? errors.likes2 : null}</span>}

                                                    {(fillUserErrors.likes1 || fillUserErrors.likes2)
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{fillUserErrors.likes1 ? fillUserErrors.likes1 : fillUserErrors.likes2 ? fillUserErrors.likes2 : null}</span>}


                                                    <div className={s.likesInput}>
                                                        <MyInput
                                                            type="text"
                                                            name="likes3"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes3Error}
                                                            errorText={err.likes3}
                                                        />

                                                        <MyInput
                                                            type="text"
                                                            name="likes4"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes4Error}
                                                            errorText={err.likes4}
                                                        />
                                                    </div>
                                                    {((touched.likes3 && errors.likes3) || (touched.likes4 && errors.likes4))
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{errors.likes3 ? errors.likes3 : errors.likes4 ? errors.likes4 : null}</span>}

                                                    {(fillUserErrors.likes3 || fillUserErrors.likes4)
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{fillUserErrors.likes3 ? fillUserErrors.likes3 : fillUserErrors.likes4 ? fillUserErrors.likes4 : null}</span>}


                                                    <div className={s.likesInput}>
                                                        <MyInput
                                                            type="text"
                                                            name="likes5"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes5Error}
                                                            errorText={err.likes5}
                                                        />
                                                        <MyInput
                                                            type="text"
                                                            name="likes6"
                                                            placeholder=""
                                                            resetErrors={changeHandler}
                                                            width={"50%"}
                                                            isError={likes6Error}
                                                            errorText={err.likes6}
                                                        />
                                                    </div>
                                                    {((touched.likes5 && errors.likes5) || (touched.likes6 && errors.likes6))
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{errors.likes5 ? errors.likes5 : errors.likes6 ? errors.likes6 : null}</span>}

                                                    {(fillUserErrors.likes5 || fillUserErrors.likes6)
                                                        && <span style={{
                                                            color: 'darkred',
                                                            marginTop: "5px"
                                                        }}>{fillUserErrors.likes5 ? fillUserErrors.likes5 : fillUserErrors.likes6 ? fillUserErrors.likes6 : null}</span>}

                                                </div>
                                            </div>
                                        )}


                                        <div className={s.checkboxBlock}>
                                            <div className={s.checkboxs}>
                                                <label className={s.checkbox}>
                                                    <Field type="checkbox" name="camera" checked={userCamera}
                                                           onChange={(e) => setUserCamera(e.target.checked)}/>
                                                    <span>Наличие камеры</span>
                                                </label>
                                                <label className={s.checkbox}>
                                                    <Field type="checkbox" name="rules" checked={userRules}
                                                           onChange={(e) => setUserRules(e.target.checked)}/>
                                                    <span>С правилами ознакомлена</span>
                                                </label>
                                            </div>

                                            <label className={s.checkbox}>
                                                <Field type="checkbox" name="microfone" checked={userMicrophone}
                                                       onChange={(e) => setUserMicrophone(e.target.checked)}/>
                                                <span>Наличие микрофона</span>
                                            </label>
                                        </div>

                                        <div className={s.submitButton}>
                                            <MyButton type={"submit"} text={"Сохранить изменения"}/>
                                        </div>

                                        <div onClick={goToUserInfo} className={s.cancelButton}>
                                            <MyButton type="button" text={"Отменить"} background={"#16233A"}
                                                      color={"#62708E"} border={"1px solid var(--grey)"}/>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            }

        </div>
    )
}

export default UserProfileChange;