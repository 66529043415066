import {http} from './index'
import {ADMIN_MAIN_STATISTICS_SETTINGS} from './endpoints'

/**
 * Get statistics on admin page
 *
 */

export const getSettingsAdminStatistics = async () => {
    return await http().get(ADMIN_MAIN_STATISTICS_SETTINGS)
}

export default getSettingsAdminStatistics;