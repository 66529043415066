import { useSelector } from "react-redux";
import Settings from "../../../components/settings/Settings";
import Statistics from "../../../components/statistics/Statistics";
import UserProfile from "../../../components/userProfile/UserProfile";
import { selectIsAdminUser } from "../../../redux/slices/userSlice";

const UserInfo = () => {
  const isAdminState = useSelector(selectIsAdminUser);

  return (
    <div className="homePage">
      <div className="homePage__userProfile">
        <UserProfile isAdmin={isAdminState} />
      </div>

      <div className="homePage__statSettings">
        <Statistics isAdmin={isAdminState} />
        {!isAdminState && <Settings />}
      </div>
    </div>
  );
};

export default UserInfo;
