import { createSlice } from "@reduxjs/toolkit";

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState: {
    messagesReadedCount: {},
    chatIdRoom: 0,
    chatIdRoomInVideoCall: null,
    videoCallId: null,
    userNikName: "",
    userTime: "",
    notificationType: null,
    isNotificationCountChange: false,
    isPublicChat: false,
    publicChat: null,
    isVideoPublic: false,
  },
  reducers: {
    setMessagesReadedCount: (state, action) => {
      state.messagesReadedCount = action.payload
    },
    setChatIdRoom: (state, action) => {
      state.chatIdRoom = action.payload
    },
    setChatIdRoomInVideoCall: (state, action) => {
      state.chatIdRoomInVideoCall = action.payload
    },
    setUserNikName: (state, action) => {
      state.userNikName = action.payload
    },
    setUserTime: (state, action) => {
      state.userTime = action.payload
    },
    setNotificationType: (state, action) => {
      state.notificationType = action.payload
    },
    setIsNotificationCountChange: (state, action) => {
      state.isNotificationCountChange = action.payload
    },
    setIsPublicChat: (state, action) => {
      state.isPublicChat = action.payload
    },    
    setPublicChatId: (state, action) => {
      state.publicChat = action.payload
    },
    setVideoCallId: (state, action) => {
      state.videoCallId = action.payload
    },
    setIsVideoPublic: (state, action) => {
      state.isVideoPublic = action.payload
    },
  }
})

export const selectMessagesReadedCount = (state) => state.webSocket.messagesReadedCount;
export const selectChatIdRoom = (state) => state.webSocket.chatIdRoom;
export const selectChatIdRoomInVideoCall = (state) => state.webSocket.chatIdRoomInVideoCall;
export const selectVideoCallId = (state) => state.webSocket.videoCallId;
export const selectUserNikName = (state) => state.webSocket.userNikName;
export const selectUserTime = (state) => state.webSocket.userTime;
export const selectNotificationType = (state) => state.webSocket.notificationType;
export const selectIsNotificationCountChange = (state) => state.webSocket.isNotificationCountChange;
export const selectIsPublicChat = (state) => state.webSocket.isPublicChat;
export const selectPublicChatId = (state) => state.webSocket.publicChat;
export const selectIsVideoPublic = (state) => state.webSocket.isVideoPublic;

export const {
  setMessagesReadedCount,
  setChatIdRoom,
  setUserNikName,
  setUserTime,
  setNotificationType,
  setIsNotificationCountChange,
  setIsPublicChat,
  setPublicChatId,
  setVideoCallId,
  setChatIdRoomInVideoCall,
  setIsVideoPublic,
} = webSocketSlice.actions

export default webSocketSlice.reducer