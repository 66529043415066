import {http} from './index'
import {ADMIN_LIST_GAMERS, ADMIN_LIST_GAMERS_EXPORT, ADMIN_LIST_HOSTES, ADMIN_LIST_HOSTES_EXPORT} from './endpoints'

/**
 * Get hostes list for main statistics
 *
 */

export const getSettingsAdminStatistics = async ({page, startDate, endDate}) => {
    if (startDate && endDate) {
        return await http().get(`${ADMIN_LIST_HOSTES}?page=${page ? page : 1}&limit=15&endDate=${endDate}&startDate=${startDate}`)
    }
    return await http().get(`${ADMIN_LIST_HOSTES}?page=${page ? page : 1}&limit=15`)
}


export const get_gamers_export = async (userId, startDate, endDate) => {
    if (userId && startDate && endDate) {
        return await http().get(`${ADMIN_LIST_GAMERS_EXPORT}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`)
    } else if (userId && !startDate && !endDate) {
        return await http().get(`${ADMIN_LIST_GAMERS_EXPORT}?userId=${userId}`)
    } else {
        return await http().get(`${ADMIN_LIST_GAMERS_EXPORT}`)
    }
}

export const get_hostes_export = async (userId) => {
    if (userId) {
        return await http().get(`${ADMIN_LIST_HOSTES_EXPORT}?userId=${userId}`)
    } else {
        return await http().get(`${ADMIN_LIST_HOSTES_EXPORT}`)
    }
}

export const getGamersStatistics = async (page, search, endDate, startDate) => {
    if (search) {
        return await http().get(`${ADMIN_LIST_GAMERS}?page=${page ? page : 1}&limit=15&userId=${search}`)
    } else if (endDate && startDate) {
        return await http().get(`${ADMIN_LIST_GAMERS}?page=${page ? page : 1}&limit=15&userId=${search}&endDate=${endDate}&startDate=${startDate}`)
    } 
    else {
        return await http().get(`${ADMIN_LIST_GAMERS}?page=${page ? page : 1}&limit=15 `)
    }
}

export default {
    getSettingsAdminStatistics,
    getGamersStatistics,
    get_gamers_export,
    get_hostes_export
}