import Settings from "../../components/settings/Settings";
import Statistics from "../../components/statistics/Statistics";
import UserProfile from "../../components/userProfile/UserProfile";

const Home = ({fromAdmin}) => {
  return (
    <div className="homePage">
      <div className="homePage__userProfile">
        <UserProfile fromAdmin={fromAdmin} />
      </div>

      <div className="homePage__statSettings">
        <Statistics />
        <Settings />
      </div>
    </div>
  );
};

export default Home;
