import * as Yup from 'yup';
// import valid from 'card-validator';

const nameRegex = /^[a-zA-Z\s]*$/;
const REQUIRED = "Обязательное поле"
const TOO_SHORT = 'Очень короткое'
const TOO_LONG = 'Очень длинное'
const ONLY_SPACED = "Не может содержать только пробелы"
const TOO_MUCH_SPACED = "Не может быть столько пробелов подряд"
const ONLY_ENGLISH = "Только ввод английских символов"
const PASSWORD_MATCHES = "Пароли должны совпадать"

const requiredField = Yup.string()
.required(REQUIRED)
  .test('not-only-spaces', ONLY_SPACED, (value) => {
    return !/^\s+$/.test(value);
  })
  .test('no-consecutive-spaces', TOO_MUCH_SPACED, (value) => {
    return !/\s\s/.test(value);
  });

const likesField = Yup.string()
  .test('not-only-spaces', ONLY_SPACED, (value) => {
    return !/^\s+$/.test(value);
  })
  .test('no-consecutive-spaces', TOO_MUCH_SPACED, (value) => {
    return !/\s\s/.test(value);
  });

const inputNameSearchFieldValidate = Yup.string()
.test('not-only-spaces', ONLY_SPACED, (value) => {
  return !/^\s+$/.test(value);
})
.min(2, TOO_SHORT)
.test('no-consecutive-spaces', TOO_MUCH_SPACED, (value) => {
  return !/\s\s/.test(value);
});

const firstNameValidation = Yup.string()
  .required(REQUIRED)
  .min(3, TOO_SHORT)
  .max(50, TOO_LONG)
  .test('test-string',
    ONLY_SPACED,
    value => value?.trim() !== '')
  .matches(nameRegex, ONLY_ENGLISH)

const lastNameValidation = Yup.string()
  .required(REQUIRED)
  .min(3, TOO_SHORT)
  .max(50, TOO_LONG)
  .test('test-string',
    ONLY_SPACED,
    value => value?.trim() !== '')
  .matches(nameRegex, ONLY_ENGLISH)

const roleIdValidation = Yup.string()
  .required(REQUIRED)
  .max(1, TOO_LONG)
  .test('test-number',
    "Users's role is invalid",
    value => value === '1' || value === '2' || value === '3')


const emailValidation = Yup.string()
  .required(REQUIRED)
  .email('Неверный формат почты')

const passwordValidation = Yup.string()
  .required(REQUIRED)
  .min(8, 'Должно содержать минимум 8 символов')
  .max(20, 'Длинный пароль')
  .test('test-string',
    ONLY_SPACED,
    value => value?.trim() !== '')

const passwordConfirmValidation = Yup.string()
  .required(REQUIRED)
  .test('test-string',
    ONLY_SPACED,
    value => value?.trim() !== '')
  .oneOf([Yup.ref('password'), null], PASSWORD_MATCHES)

const passwordChangeValidation = Yup.string()
.required(REQUIRED)
.test('test-string',
  ONLY_SPACED,
  value => value?.trim() !== '')
.oneOf([Yup.ref("password_new"), null], PASSWORD_MATCHES)

const cartNumberValidation = Yup.string()
  .matches(/^[0-9]*$/, 'Введите только цифры')
  .required(REQUIRED)


const convertFieldValidation = Yup.string()
  .matches(/^[0-9]+$/, 'Введите только целые цифры')
  .required(REQUIRED)
  .min(1, 'Должно содержать минимум 1 символ')
  .test('is-positive', 'Введите число больше 0', (value) => parseFloat(value) > 0)


const termsValidation = Yup.boolean()
  .oneOf([true], 'Необходимо принять условия использования')

export const signUpValidate = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  password_confirmation: passwordConfirmValidation,
  terms: termsValidation,
});

export const resetEmailValidate = Yup.object().shape({
  email: emailValidation,
});

export const resetPasswordValidate = Yup.object().shape({
  password: passwordValidation,
  password_new: passwordValidation,
  password_new_confirmation: passwordChangeValidation,
});

export const resetPasswordNewPaswordValidate = Yup.object().shape({
  password: passwordValidation,
});

export const forgotPasswordValidate = Yup.object().shape({
  email: emailValidation,
});

export const signInValidate = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation
});

export const createNewUserValidate = Yup.object().shape({
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  email: emailValidation,
  password: passwordValidation,
  password_confirmation: passwordConfirmValidation,
  role_id: roleIdValidation,
});

export const fillUserValidate = Yup.object().shape({
  name: requiredField,
  telegram: requiredField,
  about: requiredField,
  likes1: likesField,
  likes2: likesField,
  likes3: likesField,
  likes4: likesField,
  likes5: likesField,
  likes6: likesField,
});

export const convertValidate = Yup.object().shape({
  count: convertFieldValidation,
});

export const inputNameSearchValidate = Yup.object().shape({
  count: inputNameSearchFieldValidate,
});

export const changeValidate = Yup.object().shape({
  cartNumber: cartNumberValidation,
  count: convertFieldValidation,
});

export const validateReferralCode = (value, setErrors) => {
  if (!value) {
    return setErrors('Обязательное поле');
  }
  if (/^\s+$/.test(value)) {
    return setErrors('Нельзя только пробелы вводить');
  }
  if (/\s\s/.test(value)) {
    return setErrors('Много пробелов подряд');
  }
  return setErrors("");
};

