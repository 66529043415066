import ForgotPass from "../../components/forgotPass/ForgotPass";

const Forgot = () => {

    return (
        <div style={{display: 'flex', flex: 1}}>
            <ForgotPass />
        </div>
    )
}

export default Forgot;