import React, { useState } from "react";
import styles from "./StatsItem.module.css";
import MyIcon from "../../../UI/myIcon/MyIcon";
import { addSpaceAfterTwoChars } from "../../../../settings/helpers";
import { formatDateTime } from "../../../../settings/formatDate";
import { useNavigate } from "react-router-dom";
import { user_other } from "../../../../api/user";
import { setUserProfileOther } from "../../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

const StatsItem = ({ items, activeBtnState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (id) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await user_other(id);
      dispatch(setUserProfileOther(data.data));
      localStorage.setItem("hostesId", id);
      navigate("/user/user-info");
    } catch (error) {
      console.error("user profile", error);
    } finally {
      setIsLoading(false);
    }
  };

  return activeBtnState === 0
    ? items &&
        items.map((item, i) => {
          const isDivider = styles.divider;
          return (
            <div
              onClick={() => handleClick(item.user_id)}
              className={
                items.length === 1 ? styles.tableRowOne : styles.tableRow
              }
              key={i}
            >
              <div
                className={`${styles.cell} ${isDivider} ${styles.avatarWrapper}`}
              >
                <div className={styles.avatar}>
                  {item.avatar ? (
                    <img
                      src={item?.avatar}
                      alt={item?.username}
                      width={32}
                      height={32}
                    />
                  ) : (
                    <MyIcon
                      image={"avatar"}
                      width={"23.5px"}
                      height={"23.5px"}
                    />
                  )}

                  <div
                    className={
                      Boolean(item.online)
                        ? styles.userOnline
                        : styles.userOffline
                    }
                  />
                </div>
                <div>{item?.username}</div>
              </div>
              <div className={`${styles.cell} ${styles.flex} ${isDivider}`}>
                <div>
                  <MyIcon image={"miniLogo"} width={"19px"} height={"19px"} />
                </div>
                <div>x {addSpaceAfterTwoChars(item?.referralHeartsSum)}</div>
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {Number(item?.avgRating).toFixed(1)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.clientCount && addSpaceAfterTwoChars(item?.clientCount)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.durationAmount &&
                  addSpaceAfterTwoChars(item?.durationAmount)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.withdrawalSum &&
                  addSpaceAfterTwoChars(item?.withdrawalSum)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.giftCount && addSpaceAfterTwoChars(item?.giftCount)}
              </div>
              <div className={`${styles.cell}`}>
                {item?.complaintCount &&
                  addSpaceAfterTwoChars(item?.complaintCount)}
              </div>
            </div>
          );
        })
    : items &&
        items.map((item, i) => {
          const isDivider = styles.divider;
          return (
            <div
              className={
                items.length === 1 ? styles.tableRowOne : styles.tableRow
              }
              key={i}
            >
              <div
                className={`${styles.cell} ${isDivider} ${styles.avatarWrapper}`}
              >
                <div className={styles.status}>
                  {item.status === "success" && (
                    <MyIcon
                      image={"success_gamers"}
                      alt={item?.status}
                      width={"16px"}
                      height={"16px"}
                    />
                  )}
                  {item.status === "errors" && (
                    <MyIcon
                      image={"errors_gamers"}
                      alt={item?.status}
                      width={"16px"}
                      height={"16px"}
                    />
                  )}
                </div>
                <div>{item?.trnId}</div>
              </div>
              <div className={`${styles.cell} ${styles.flex} ${isDivider}`}>
                {addSpaceAfterTwoChars(item?.user?.id)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item.amount && addSpaceAfterTwoChars(item?.amount)}$
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.hearts && addSpaceAfterTwoChars(item?.hearts)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.date && formatDateTime(item?.date, true)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.date && formatDateTime(item?.date, false, true)}
              </div>
              <div className={`${styles.cell} ${isDivider}`}>
                {item?.method && item?.method}
              </div>
              <div className={`${styles.cell}`}>{item?.type && item?.type}</div>
            </div>
          );
        });
};

export default StatsItem;
