import {http} from './index'
import { NOTIFICATIONS, NOTIFICATION_ALL, NOTIFICATION_COMPLAINTS, NOTIFICATION_DELETE, NOTIFICATION_GIFTS, NOTIFICATION_INVITATIONS, NOTIFICATION_REMINDERS } from './endpoints'



/**
 * notification_list
 **/

export const notification_list = async () => {
    return await http().get(NOTIFICATIONS)
}

export const notification_all = async (page) => {
    return await http().get(`${NOTIFICATION_ALL}?page=${page}`)
}

export const notification_gifts = async (page) => {
    return await http().get(`${NOTIFICATION_GIFTS}?page=${page}`)
}

export const notification_invitations = async (page) => {
    return await http().get(`${NOTIFICATION_INVITATIONS}?page=${page}`)
}

export const notification_reminders = async (page) => {
    return await http().get(`${NOTIFICATION_REMINDERS}?page=${page}`)
}

export const notification_complaints = async (page) => {
    return await http().get(`${NOTIFICATION_COMPLAINTS}?page=${page}`)
}

export const approve_notification = async (route) => {
    return await http().get(route)
}

export const cancel_notification = async (route) => {
    return await http().get(route)
}

export const delete_notification = async ({id}) => {
    return await http().delete(`${NOTIFICATION_DELETE}/${id}`)
}

export default {
    notification_list,
    notification_all,
    notification_gifts,
    notification_invitations,
    notification_reminders,
    notification_complaints,
    approve_notification,
    delete_notification,
}