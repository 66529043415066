import CreateUser from "../../components/createUser/CreateUser";
import ResetPassword from "../../components/resetPassword/ResetPassword";


const index = () => {

    return (
        <div>
            <ResetPassword />
        </div>
    )
}

export default index;