import React, { useState, useEffect, useRef } from 'react';
import { 
  notification_all, 
  notification_complaints, 
  notification_gifts, 
  notification_invitations, 
  notification_list, 
  notification_reminders 
} from '../../api/notifications';
import NotificationsList from "./notificationList/NotificationsList"
import NotificationType from "./notificationType/NotificationType"
import s from "./NotificationsTab.module.css"
import { useDispatch } from 'react-redux';
import { setIsNotificationCountChange, setNotificationType } from '../../redux/slices/webSocketSlice';
import { makeListUnique } from '../../settings/uniqList';
import { setUpdateCountChats } from '../../redux/slices/userSlice';
import { useWebConnectionSocket } from '../../socket/webSocketConnection';
import { useLocation } from 'react-router-dom';

const NotificationsItem = () => {
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation()
    const socketConnection = useWebConnectionSocket();  
    const [notifications, setNotifications] = useState([]);
    const [notificationItem, setNotificationItem] = useState({});
    const [notificationItemType, setNotificationItemType] = useState("all");
    const [notificationByType, setNotificationByType] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [countUnreadNotification, setCountUnreadNotification] = useState([]);  
    const [page, setPage] = useState(1);
    const [pageLast, setPageLast] = useState(1);
    const [timeoutId, setTimeoutId] = useState(null);

    const selectedNotificationRef = useRef(selectedNotification);

    useEffect(() => {
      selectedNotificationRef.current = selectedNotification;
  }, [selectedNotification]);

  useEffect(() => {
    location.pathname !== "/notifications" ? setSelectedNotification(null) : setSelectedNotification("all")
    location.pathname === "/notifications" && dispatch(setIsNotificationCountChange(true))

}, [location.pathname]);

    
    useEffect(() => {
      if (socketConnection) {

        socketConnection.on('new_notification', (notification) => {
          const currentSelectedNotification = selectedNotificationRef.current;
          (notification.type.type === currentSelectedNotification || currentSelectedNotification === "all") && socketConnection.emit("read_notification", { notificationId: notification.id })
          if (notification.type.type === currentSelectedNotification || currentSelectedNotification === "all") {
            socketConnection.on("notification_readed", (mes) => {     
              const updatedCountUnreadNotification = countUnreadNotification.filter(item => {
                if (item.type === mes.type.type || item.type === "all") {
                      return {
                        ...item,
                        unreadNotificationCount: Number(item.unreadNotificationCount) > 10 ? Number(item.unreadNotificationCount) - 10 : 0
                      };
                }
                return item;
              });
        
              setCountUnreadNotification(updatedCountUnreadNotification); 
      
              })
          }

          const updatedCountUnreadNotification = countUnreadNotification && countUnreadNotification.map(item => {
            if (item.type === notification.type.type || item.type === "all") {
              return {
                ...item,
                unreadNotificationCount: 1 + Number(item.unreadNotificationCount)
              };
            }
            return item;
          });
    
          setCountUnreadNotification(updatedCountUnreadNotification);

          if (notification.type.type === currentSelectedNotification || currentSelectedNotification === "all") {
              setNotificationByType(prevNotificationByType => makeListUnique(prevNotificationByType, notification));
          }
        })
        
      }
    }, [selectedNotification, selectedNotificationRef, countUnreadNotification])

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
    
        return () => {
          container.removeEventListener('scroll', handleScroll);
        };
      }
    }, [page, pageLast, notificationByType, notificationItem]);

    useEffect(() => {
      getNotificationList()
      getNotificationType("all")
      setSelectedNotification("all")
      setNotificationItem({id: 5, name: 'Все уведомления', type: 'all', status: 1, total: 11})
      dispatch(setIsNotificationCountChange(true))
    }, []);

    
    useEffect(() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (notificationItemType) {
        const id = setTimeout(() => {
          getNotificationList(notificationItemType);
        }, 500);
  
        setTimeoutId(id);      
      }

    }, [notificationItemType]);

    const handleScroll = () => {
      const container = containerRef.current;
      let timer = 0;

      if (page <= pageLast) {
        if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
          timer = setTimeout(() => {
            getNotificationType(notificationItemType)
          }, 300);
        } 
      }
  
        return () => {
          clearTimeout(timer);
        };
    };
  
    const getNotificationList = async () => {
      try {
        const { data } = await notification_list()
        setNotifications(data.data);

        const updatedCountUnreadNotification = data.data.map(item => ({
          type: item.type,
          unreadNotificationCount: item.total
        }));

        setCountUnreadNotification(updatedCountUnreadNotification);

      } catch (error) {
        console.error("Error notification_list", error)
      }
    }

    const getNotificationType = async (request, pageOne) => {
      setNotificationItemType(request);

      try {
        let res;
        switch (request) {
          case "all":
            res = await notification_all(pageOne ? pageOne : page);
            break;
          case "gifts":
            res = await notification_gifts(pageOne ? pageOne : page);
            break;
          case "reminders":
            res = await notification_reminders(pageOne ? pageOne : page);
            break;
          case "complaints":
            res = await notification_complaints(pageOne ? pageOne : page);
            break;
          case "invitations":
            res = await notification_invitations(pageOne ? pageOne : page);
            break;
          default:
            setNotificationByType([]);
            return;
        }
    
        const newNotificationData = res.data.data;            
        setNotificationByType((prevData) => {
          const existingIds = new Set(prevData.map((item) => item.id));
          const filteredNewData = newNotificationData.filter((item) => !existingIds.has(item.id));
          return [...prevData, ...filteredNewData];
        });
    
        setPage(res.data.pagination.nextPage ? res.data.pagination.nextPage : res.data.pagination.lastPage + 1);
        pageLast === 1 && setPageLast(res.data.pagination.lastPage);
      } catch (error) {
        console.error("Error getNotificationTypeAll", error);
      }
    } 

    const handleNotificationClick = (item) => {
        setNotificationByType([])
        setNotificationItem(item)
        setSelectedNotification(item.type);
        dispatch(setUpdateCountChats(true))
        dispatch(setIsNotificationCountChange(true))
        dispatch(setNotificationType(item.type))
        getNotificationList()
        getNotificationType(item.type, 1)
        setPage(1)
        setPageLast(1)
    };
  
    const sortedNotifications = [...notifications].sort((a, b) => {
      if (a.type === "all" && b.type !== "all") return -1;
      if (a.type !== "all" && b.type === "all") return 1;
      return 0;
    });

    return (
        <div className={s.notifications}>
            <div className={s.notifications__list}>
                <NotificationsList 
                  selectedNotification={selectedNotification} 
                  sortedNotifications={sortedNotifications} 
                  countUnreadNotification={countUnreadNotification}
                  handleNotificationClick={handleNotificationClick}  
                />
            </div>
            <div ref={containerRef} className={s.notifications__type}>
                <NotificationType page={page} notificationItem={notificationItem} notificationByType={notificationByType} getNotificationType={getNotificationType} />
            </div>
        </div>
    )
}

export default NotificationsItem