
import React from 'react'
import ChatTab from '../../components/chatTab/ChatTab'

const index = () => {
  return (
    <div>
        <ChatTab />
    </div>
  )
}

export default index