import MyIcon from "../../UI/myIcon/MyIcon";
import MyButton from "../../UI/myButton/MyButton";
import s from "./ModalUserProfileInfo.module.css"
import RatesUser from "../../ratesUser/RatesUser";
import { user_info, user_other } from "../../../api/user";
import { useEffect, useState } from "react";

const ModalUserProfileInfo = ({ 
    isOpen, 
    isNotModal, 
    onClose, 
    userDataMyId, 
    userDataRating, 
    userId, 
    userData, 
    requestName,
    hasTeam,
}) => {
    const [userInfo, setUserInfo] = useState({})
    const [userInterests, setUserInterests] = useState([])
    const [isShowButtonSend, setIsShowButtonSend] = useState(false)
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        if (isOpen) {
          const timeoutId = setTimeout(() => {
            setShouldRender(true);
          }, 200);
    
          return () => {
            clearTimeout(timeoutId);
          };
        }
      }, [isOpen]);

    useEffect(() => {
        userData && !userId && requestUserData()
    }, [userData])

    useEffect(() => {
        userId && !userData && requestUserInfo()
    }, [userId])

    useEffect(() => {
        userDataMyId !== userInfo.id ? setIsShowButtonSend(true) : setIsShowButtonSend(false)
    }, [userDataMyId, userInfo])

    const requestUserData = async () => {
        try {
            const res = await user_other(userData)
            setUserInfo(res.data.data)
            setUserInterests(JSON.parse(res.data.data.interests))
        } catch (error) {
            
        }
    }

    const requestUserInfo = async () => {
        try {
            const res = await user_info()
            setUserInfo(res.data.data)
            setUserInterests(JSON.parse(res.data.data.interests))
        } catch (error) {
            
        }
    }

    const closeModal = () => {
        onClose();
      };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
    };

    if (!shouldRender) {
        return null;
      }

    return (
        <div className={isNotModal ? s.containerProfile : s.modalOverlay} onClick={handleOverlayClick}>
            <div className={isNotModal ? s.modalProfile : s.modal}>
                {window.innerWidth <= 768 && (
                    <div className={s.mobileHeader}>
                        {isShowButtonSend && (
                            <div onClick={() => requestName((userId ? userId : userInfo.id), userInfo.username, userData)} className={s.icon}>
                                <MyIcon 
                                    image={"messageToUser"}
                                    text={"Отправить сообщение"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                            </div>                            
                        )}
                        {
                            !isNotModal && 
                                <button type="button" className={s.modalCloseMobile} onClick={onClose}>
                                    <MyIcon image={"close"} width={"16px"} height={"16px"} />
                                </button>
                        }
                    </div>

                )}

                <>
                    <div className={s.avatarRate}>
                        <div className={isNotModal ? s.avatarProfile : s.avatar}>
                            {userInfo?.avatar && 
                                <img
                                    style={{objectFit: 'cover'}} 
                                    src={userInfo.avatar}
                                    alt="avatar"
                                />
                            }
                        </div>
                        <div className={s.userNik}>
                            <div className={s.nikename}>{userInfo.username}</div>
                            {!isNotModal && <div className={s.line}></div>}
                            {!isNotModal && <MyIcon image={"telegramIcon"} width={"20px"} height={"20px"} />}
                            {!isNotModal && <div className={s.social}>{userInfo.telegram}</div>}
                        </div>
                        <RatesUser userInfoRates={userDataRating} isOtherProfile />
                    </div>

                    {window.innerWidth > 768 && (
                        isShowButtonSend && (
                            <div onClick={() => requestName((userId ? userId : userInfo.id), userInfo.username, userData)} className={s.button}>
                                <MyButton 
                                    text={"Отправить сообщение"}
                                    width={"182px"}
                                    height={"33px"}
                                    background={"rgba(98, 112, 142, 0.30)"}
                                    color={"#fff"}
                                />
                            </div>
                        )
                    )}

                    <div className={s.about}>
                        <div className={s.aboutText}>О себе</div>
                        <div className={s.aboutDescription}>{userInfo.about_me}</div>
                        {
                            window.innerWidth < 820 && 
                                <div className={s.lineMobileFirst}></div>
                        }
                    </div>

                    <div className={s.likes}>
                        <div className={s.likesText}>Интересы</div>
                        <div className={s.likesBlock}>
                            <div className={s.containerLikes}>
                            {Object.values(userInterests).map((like, index) => (
                                like !== "" &&
                                    <div key={index} className={s.like}>
                                        {like}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {
                            window.innerWidth < 820 && 
                                <div className={s.lineMobileSecond}></div>
                        }
                    </div>


                    {hasTeam &&  userDataMyId !== userData && isNotModal && window.innerWidth < 1100 && (
                        <div className={s.profileButtons}>
                            <div className={s.profileButton}>Пожаловаться</div>
                            {/* <div className={s.profileButton}>Выписать штраф</div> */}
                            <div className={s.profileButton}>Удалить из команды</div>
                        </div>  
                    )}
                </>
            </div>
        </div>
    )
}

export default ModalUserProfileInfo;