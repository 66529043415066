import React, { useState } from "react";
import s from "./ModalInfo.module.css";
import MyIcon from "../../UI/myIcon/MyIcon";
import MyButton from "../../UI/myButton/MyButton";
import { validateReferralCode } from "../../../settings/validation";

const ModalInfo = ({ placeholder, addInput, isOpen, onClose, title, text, buttonText, handleSubmit }) => {
  const [input, setInput] = useState("")
  const [errors, setErrors] = useState('');

    if (!isOpen) return null;

    const closeModal = () => {
      onClose();
    };
  
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };

    const onKeyDown = (e) => {
      const value = e.target.value;
      const parsedValue = value.replace(/[^0-9]/g, '')
      setInput(parsedValue);
      validateReferralCode(parsedValue, setErrors);
    }

    const onKeyDownTextarea = (e) => {
      const value = e.target.value;
      setInput(value);
      validateReferralCode(value, setErrors);
    }

  return (
        <div className={s.modalOverlay} onClick={handleOverlayClick}>
          <div className={s.modal}>
            <button type="button" className={s.modalClose} onClick={onClose}>
              <MyIcon image={"close"} width={"16px"} height={"16px"} />
            </button>
            <div>
                <h2 className={s.modalHeader}>{title}</h2>        
                <div className={addInput ? s.modalTextFull : s.modalText}>{text}</div>

                {title === "Пожаловаться" && (
                  <div className={s.submitBlock}>
                    <textarea
                        value={input}
                        onChange={(e) => onKeyDownTextarea(e)}     
                        placeholder={placeholder}
                        rows={4}
                        cols={40}
                        className={s.inputSettings}       
                    />
                  </div>                 
                )}

                {addInput && (
                  title === "Цена продажи" || title === "Цена обмена" ? (
                    <div className={s.inputBlocks}>
                      <MyIcon image={"miniLogo"} height={"30"} width={"30"} />
                      <div className={s.one}>1 =</div>
                      <input
                          pattern="[0-9]*"
                          value={input}
                          onChange={(e) => onKeyDown(e)}     
                          placeholder={placeholder}
                          className={s.inputSettings}       
                      />
                      <div className={s.inInput}>₽</div>
                    </div>                    
                  ) : (
                    <div className={s.inputBlock}>
                      <input
                          value={input}
                          onChange={(e) => onKeyDown(e)}     
                          placeholder={placeholder}
                          className={s.inputSettings}       
                      />
                  </div>
                  )
                )}

                {errors && <div className={s.error}>{errors}</div>}
                <div className={s.button}>
                    <MyButton onHandelSubmit={() => !errors && handleSubmit(input)} width={"100%"} text={buttonText} background={"#FFDC64"} color={"#051423"} />
                </div>          
            </div>
          </div>
        </div>
  );
};

export default ModalInfo;
