import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import s from "./MyCalendar.module.css"
import { getUserIdStatistics } from '../../api/adminStatistics';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../redux/slices/userSlice';
import { getMainAdminStatistics } from '../../api/adminStatistics';
import { getGamersStatistics, getSettingsAdminStatistics } from '../../api/getHostesList';
import { resetGamersStatistics, resetStatistics, setStatistics } from '../../redux/slices/adminStatSlice';

const MyCalendar = ({ setCalendarOpen, type }) => {
  const [dateRange, setDateRange] = useState([]);

  const dispatch = useDispatch()

  const onChange = (newDate) => {
    const dateObjectStart = new Date(newDate[0]);
    const dateObjectEnd = new Date(newDate[1]);

    const utcDateStringStart = dateObjectStart.toISOString();
    const utcDateStringEnd = dateObjectEnd.toISOString();
    searchRequest(utcDateStringStart, utcDateStringEnd)
    setCalendarOpen(false)
  };

  const searchRequest = async (startDate, endDate) => {
    try {
        if (type === 'HOSTES') {
          const { data } = await getSettingsAdminStatistics({startDate, endDate, page: 1})
          dispatch(resetStatistics(data.data))
        } else if (type === 'GAMERS') {
            const {data} = await getGamersStatistics(1, '', startDate, endDate);
            const newTransactions = data.data;
            dispatch(resetGamersStatistics(newTransactions))
        }
        
    } catch (error) {
        console.error("searchRequest", error)
    }
}

  return (
    <div className={s.calendarContainer}>
      <Calendar
        onChange={onChange}
        selectRange
        value={dateRange}
        className={s.customCalendar}
      />
    </div>
  );
};

export default MyCalendar;
