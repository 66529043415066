import {http} from './index'
import { SCHEDULE_LIST, SCHEDULE_TIME_BOOK, SCHEDULE_TIME_CANCEL } from './endpoints'

/**
 * Get schedule
 *
 */

export const getSchedule = async () => {
    return await http().get(SCHEDULE_LIST)
}

/**
 * Pst schedule
 *
 * @param {string} timeId - timeId
 */

export const postSchedule = async ({ timeId }) => {
    return await http().post(SCHEDULE_TIME_BOOK, { timeId })
}

/**
 * Pst schedule
 *
 * @param {string} timeId - timeId
 */

export const cancleSchedule = async ({ timeId }) => {
    return await http().post(SCHEDULE_TIME_CANCEL, { timeId })
}

export default {
    postSchedule,
    getSchedule,
    cancleSchedule,
}