import { useEffect, useState } from "react";
import { user_info } from "../../api/user";
import s from "./UserProfile.module.css";
import UserProfileFill from "./userProfileFill/UserProfileFill";
import UserProfileInfo from "./userProfileInfo/UserProfileInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserProfile,
  setUserProfile,
} from "../../redux/slices/userSlice";

const UserProfile = ({ isAdmin, fromAdmin }) => {
  const dispatch = useDispatch();
  const userDataState = useSelector(selectUserProfile);
  const [isData, setIsData] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userInterests, setUserInterests] = useState([]);
  const [loading, setLoading] = useState(false)

  const requestCheckUserVerify = async () => {
    setIsDone(false);
    try {
      setLoading(true)
      const res = await user_info();
      const data = res.data.data;
      if (data.username && data.about_me) {
        setIsData(true);
        setUserInfo(res.data.data);
        setUserInterests(JSON.parse(res.data.data.interests));
        dispatch(setUserProfile(res.data.data));
      }
      setIsDone(true);
    } catch (error) {} finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    requestCheckUserVerify();
  }, []);

  useEffect(() => {
    isAdmin &&
      setUserInfo(userDataState) &&
      setUserInterests(JSON.parse(userDataState.interests));
  }, [isAdmin, userDataState]);

  useEffect(() => {
    setIsDone(true);
  }, [isAdmin]);

  return (
    isDone && (
      <div className={s.container}>
        <div className={s.firstBlock}>
          {isData || isAdmin ? (
            <UserProfileInfo userData={userInfo} interests={userInterests} isAdmin={isAdmin} fromAdmin={fromAdmin} />
          ) : (
            <>
              {!loading ? (
                <UserProfileFill />
              ) : null}
            </>
          )}
        </div>
      </div>
    )
  );
};

export default UserProfile;
