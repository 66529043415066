import React from 'react'
import s from "./ReferralInfo.module.css"
import { useState, useEffect } from 'react'
import MyInput from '../../../UI/myInput/MyInput'
import MyButton from '../../../UI/myButton/MyButton'
import MyIcon from '../../../UI/myIcon/MyIcon'
import { user_edit, user_info } from "../../../../api/user"
import { get_my_referral_count } from "../../../../api/referral"
import { useToast } from 'rc-toastr'
import { validateReferralCode } from '../../../../settings/validation'

const ReferralInfo = () => {
  const location = window.location.host
  const { toast } = useToast()
  const [invites, setInvites] = useState(0)
  const [referralCode, setReferralCode] = useState("")
  const [referralCodeInput, setReferralCodeInput] = useState("")
  const [referralLink, setReferralLink] = useState("")
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const copyToClipboard = (textCopy) => {
    if (textCopy) {
      navigator.clipboard.writeText(textCopy).then(() => {
        toast.success('Скопировано в буфер обмена!');
      }).catch((error) => {
        console.error('Failed to copy: ', error);
      });
    }
  };

  const createReferralCode = async (val) => {
    if (isLoading) {
      return;
    }
    if (errors === "" && referralCodeInput !== "") {
      try {
        setIsLoading(true)
        await user_edit({referralCode: referralCodeInput})
        setReferralCodeInput("")
        requestUser()
      } catch (error) {
        console.error("creare referral code ", error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const requestReferralCount = async () => {
    try {
        const { data } = await get_my_referral_count()
        setInvites(data.data.count)
    } catch (error) {
        console.error(error)
    }
  }

  const requestUser = async () => {
    setReferralCodeInput("")
    try {
        const { data } = await user_info()
        if (data.data.referralCode) {
          setReferralCode(data.data.referralCode)
          setReferralLink(`${location}/register?referral-link=${data.data.referralCode}`)
        }
    } catch (error) {
        console.error(error)
    }
  }
  
  const onKeyDown = (e) => {
    const value = e.target.value;
    setReferralCodeInput(value);
    validateReferralCode(value, setErrors);
  }

  useEffect(() => {
    requestUser()
    requestReferralCount()
  }, [])

  return (
    <div className={s.container}>
        <div className={s.infoBlock}>
          <div className={s.header}>Приглашенных пользователей: <div className={s.colorWhite}>{invites}</div></div>

          <div className={s.codeBlock}>
            <div className={s.code}>
              <div className={s.colorWhite}>Ваш реферальный код</div>
              <MyIcon
                image={"info"}
                width={"19px"}
                height={"19px"}
              />
            </div>

            <div className={s.input}>
              <MyInput 
                  type="text"
                  name="referralCode"
                  value={referralCode}
                  label={'referralCode'}
                  width={"100%"}
                  isInput          
                  isdisabled                       
              />
              <div className={s.icon} onClick={() => copyToClipboard(referralCode)}>
                <MyIcon
                  image={"copy"}
                  width={"22px"}
                  height={"22px"}
                />
              </div>
            </div>
          </div>

          <div className={s.codeBlock}>
            <div className={s.code}>
              <div className={s.colorWhite}>Реферальная ссылка</div>
              <MyIcon
                image={"info"}
                width={"19px"}
                height={"19px"}
              />
            </div>

            <div className={s.input}>
              <MyInput 
                  type="text"
                  name="link"
                  value={referralLink}
                  label={'link'}
                  width={"100%"}
                  isInput          
                  isdisabled                          
              />
              <div className={s.icon} onClick={() => copyToClipboard(referralLink)}>
                <MyIcon
                  image={"copy"}
                  width={"22px"}
                  height={"22px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.line}></div>
        <div className={s.submitBlock}>
            <input 
                type="text"
                name="referralCode"
                placeholder={"Введите реферальный код"}
                value={referralCodeInput}
                onChange={(e) => onKeyDown(e)}     
                className={s.inputSettings}       
            />
            <MyButton onHandelSubmit={createReferralCode} text={"Подтвердить"} width={"100%"} />
            {errors && <div className={s.error}>{errors}</div>}
        </div>
    </div>
  )
}

export default ReferralInfo