import MyIcon from "../UI/myIcon/MyIcon";
import {Tooltip} from "../tooltip/tooltip";
import {useState} from "react";

export const TooltipIcon = ({text, image, width, height, position}) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    return (
        <div style={{position: 'relative'}}>
            <div
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
            >
                <MyIcon
                    image={image}
                    width={width || "18px"}
                    height={height || "18px"}
                    style={{cursor: 'pointer'}}
                />
            </div>
            {tooltipVisible && (<Tooltip position={position || 'top'} text={text}/>)}
        </div>
    )
}