import React from 'react';
import StatsHeaderStatic from "./statsHeaderStatic/StatsHeaderStatic";
import StatsControl from "./statsControl/StatsControl";
import styles from './GeneralStats.module.css'
import StatsTable from "./statsTable/StatsTable";
import StatsHeaderChange from "./statsHeaderChange/StatsHeaderChange";

const GeneralStats = () => {
    return (
        <section className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.headerStatic}>
                    <StatsHeaderStatic />
                    <StatsHeaderChange />
                </div>
                <div className={styles.control}>
                    <StatsControl />
                </div>
            </div>
            <div className={styles.table}>
                <StatsTable />
            </div>
        </section>
    );
};

export default GeneralStats;