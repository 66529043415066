import React, { useRef } from "react";
import { useState } from "react";
import { Form, Formik } from "formik";

import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import s from "./Change.module.css";
import { changeValidate } from "../../../settings/validation";
import { post_exchange_withdrawals } from "../../../api/balance";
import { useToast } from "rc-toastr";

const validationSchema = changeValidate;

const Change = ({ setGetWithDrawelHistoryData }) => {
  const { toast } = useToast();
  const formikRef = useRef(null);
  const [changeErr, setChangeErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorsCount, setErrorsCount] = useState({
    cartNumber: "",
    count: "",
  });

  const initialValues = {
    cartNumber: "",
    count: "",
  };

  const convertHandler = async (values) => {
    if (isLoading) {
      return;
    }

    const { count, cartNumber } = values;

    if (Number(count) < 2000) {
      toast.error("Введите сумму больше 2 000");
      return;
    }

    const request = {
      amount: Number(count),
      cardNumber: cartNumber.toString(),
    };

    try {
      setIsLoading(true);
      await post_exchange_withdrawals(request);
      setGetWithDrawelHistoryData(true);
      toast.success("Вывод средств прошел успешно");
      formikRef.current.resetForm();
    } catch (error) {
      error.response.data.message ===
        "You are trying to withdraw more than you have" &&
        setChangeErr("Не достаточно средств на счету!");
      error.response.data.message ===
        "Access denied. Your account is not verified." &&
        setChangeErr("Ваша анкета на проверке");
      console.error("convertHandler error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const changeHandler = () => {
    setChangeErr("");
    setErrorsCount({
      count: "",
      cartNumber: "",
    });
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <MyIcon image={"outMoney"} width={"18px"} height={"18px"} />
        <div>Вывод средств</div>
      </div>
      <div className={s.changeBlock}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => convertHandler(values)}
          innerRef={formikRef}
        >
          {({ errors, touched }) => {
            let err = {
              count: "",
              cartNumber: "",
            };

            if (errorsCount.count) {
              err = errorsCount;
            } else {
              err = errors;
            }

            const countError = err.count && touched.count;
            const cartNumberError = err.cartNumber && touched.cartNumber;

            return (
              <Form>
                <div className={s.outInputs}>
                  <div className={s.convertHeader}>
                    <div>Номер карты</div>
                    <div className={s.input}>
                      <MyInput
                        type="number"
                        name="cartNumber"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        isMaxLength={"16"}
                        isError={cartNumberError}
                        errorText={err.cartNumber}
                        resetErrors={changeHandler}
                        width={"100%"}
                      />
                    </div>
                  </div>

                  <div className={s.convertHeader}>
                    <div>Сумма</div>
                    <div>
                      <MyInput
                        type="number"
                        name="count"
                        placeholder="₽ 0.00"
                        isError={countError}
                        errorText={err.count}
                        resetErrors={changeHandler}
                        width={"100%"}
                      />
                    </div>
                  </div>
                </div>
                <div className={s.flexError}>
                  {touched.cartNumber && errors.cartNumber && (
                    <div
                      style={{
                        color: "darkred",
                        marginTop: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {errors.cartNumber}
                    </div>
                  )}
                  {touched.count && errors.count && (
                    <div
                      style={{
                        color: "darkred",
                        marginTop: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {errors.count}
                    </div>
                  )}
                  {changeErr !== "" && (
                    <div
                      style={{
                        color: "darkred",
                        marginTop: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {changeErr}
                    </div>
                  )}
                </div>

                <div>
                  <MyButton
                    type="submit"
                    width={"100%"}
                    text={"Вывести средства"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className={s.footer}>Минимальная сумма вывода 2000р</div>
        <div className={s.footer}>
          <MyIcon image={"info_2"} width={"24"} height={"24"} />
          <div>Вывод средств доступен только на банковскую карту</div>
        </div>
      </div>
    </div>
  );
};

export default Change;
