import { createSlice } from "@reduxjs/toolkit";

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    userRegister: {
        email: "",
        password: "",
        link: "/home",
        terms: false
    },
  },
  reducers: {
    setUserRegister: (state, action) => {
        state.userRegister.email = action.payload.email;
        state.userRegister.password = action.payload.password;
        state.userRegister.link = "/home";
        // state.userRegister.password_confirmation = action.payload.password_confirmation;
        state.userRegister.terms = action.payload.terms;
    },
  }
})

export const selectUserRegister = (state) => state.registration.userRegister;

export const {
    setUserRegister,
} = registrationSlice.actions

export default registrationSlice.reducer