import React, { useEffect, useState } from 'react'
import s from "./ReferralWinner.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon"
import firstPlace from "../../../../images/winnerFirst.svg"
import secondPlace from "../../../../images/winnerSecond.svg"
import thirdPlace from "../../../../images/winnerThird.svg"
import { get_top_last_month } from '../../../../api/referral'
import { addSpaceAfterTwoChars } from '../../../../settings/helpers'

const ReferralWinner = () => {
  const [winners, setWinners] = useState([])

  const getWinners = async () => {
    try {
      const { data } = await get_top_last_month()
      data.data.length !== 0 ? setWinners(data.data) : setWinners([]);
    } catch (error) {
      console.error("get winners ", error)
    }
  }

  useEffect(() => {
    getWinners()
  }, [])

  return (
    <div className={s.container}>
        <div className={s.header}>
          <div>
              <MyIcon 
                image={"winnerLogo"}
                width={"20px"}
                height={"20px"}
              />          
          </div>
          <p className={s.headerTitle}>
            Победители прошлого месяца
          </p>
          <div>
          <MyIcon
              image={"info"}
              width={"24px"}
              height={"24px"}
          />
          </div>
        </div>

        <div className={s.winnersBlock}>
            {winners && winners.map((winner, index) => (
                <div className={s.place} key={index}>
                  <div className={s.img}>
                    {index === 0 && <img src={firstPlace} alt='first' />}
                    {index === 1 && <img src={secondPlace} alt='second' />}
                    {index === 2 && <img src={thirdPlace} alt='third' />}
                  </div>
                  <div className={s.infoWinner}>
                    <div>{index + 1}. {winner.team_name.length > 10 ? `${winner.team_name.slice(0, 10)}...` : winner.team_name}</div>
                    <div className={s.count}>
                      <div>
                        <MyIcon
                            image={"miniLogo"}
                            width={"24px"}
                            height={"24px"}
                        />                    
                      </div>
                      <div>x {addSpaceAfterTwoChars(winner.totalHearts.toFixed(1))}</div>
                      {index === 0 && <div className={s.xTwo}>Множитель коинов х2</div>}
                      {index === 1 && <div className={s.xTwo}>Множитель коинов х1.5</div>}
                      {index === 2 && <div className={s.xTwo}>Множитель коинов х1.2</div>}
                    </div>
                  </div>
                </div> 
            ))}
        </div>
    </div>
  )
}

export default ReferralWinner