import React from "react";
import s from "./HistoryWithDrawal.module.css";
import MyIcon from "../../UI/myIcon/MyIcon";
import PaginationHistoryWithDrawal from "../paginationHistoryWithDrawal/PaginationHistoryWithDrawal";

const HistoryWithDrawal = ({
  getWithDrawelHistoryData,
  setGetWithDrawelHistoryData,
}) => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <MyIcon image={"historyWithDrawal"} width={"20px"} height={"20px"} />
        <div>История вывода</div>
      </div>

      <div className={s.headerMini}>
        <div className={s.headerStatus}>Состояние</div>
        <div className={s.headerAmount}>Сумма</div>
        <div className={s.headerCard}>Карта</div>
        <div className={s.headerData}>Дата и время</div>
      </div>
      <div style={{ overflowY: "auto" }}>
        <PaginationHistoryWithDrawal
          getWithDrawelHistoryData={getWithDrawelHistoryData}
          setGetWithDrawelHistoryData={setGetWithDrawelHistoryData}
        />
      </div>
    </div>
  );
};

export default HistoryWithDrawal;
