import MyButton from "../../UI/myButton/MyButton";
import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import s from "./ChangeEmail.module.css"

import { useSelector } from "react-redux";
import { useState } from "react";
import { selectUserProfile } from "../../../redux/slices/userSlice";
import ModalChangeEmail from "../../modals/modalChangeEmail/ModalChangeEmail";


const ChangeEmail = () => {
    const userProfile = useSelector(selectUserProfile)
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };     

    return (
        <>
            <div className={s.block}>
                <div className={s.blockText}>Почта</div>
                <div className={s.inputSettings}>
                    <MyInput 
                        type="text"
                        name="email"
                        placeholder={userProfile.email}
                        label={'Email'}
                        width={"100%"}
                        isInput          
                        isdisabled                          
                    />

                    <div className={s.icon}>
                        <MyIcon image={'email'} width={'22px'} height={'22px'} />
                    </div>
                </div>

                <div onClick={openModal} className={s.emailButton}>
                    <MyButton width={"100%"} text={"Изменить почту"} background={"#16233A"} color={"#62708E"} border={"1px solid var(--grey)"} />
                </div>
            </div>
            <ModalChangeEmail isOpen={isOpen} onClose={closeModal} />         
        </>

    )
}

export default ChangeEmail;