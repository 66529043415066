
import React from 'react'
import BalanceTap from '../../components/balanceTab/BalanceTap'

const Balance = () => {
  return (
        <BalanceTap />
  )
}

export default Balance