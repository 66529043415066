import MyButton from "../../UI/myButton/MyButton";
import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import s from "./ChangePassword.module.css"

import { useState } from "react";
import ModalChangePassword from "../../modals/modalChangePassword/ModalChangePassword";

const ChangePassword = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className={s.block}>
                <div className={s.headerBlock}>
                    <div className={s.blockText}>Пароль</div>
                </div>
                <div className={s.inputSettings}>
                    <MyInput 
                        type={'password'}
                        name="prePassword"
                        placeholder="********"
                        width={"100%"}     
                        isInput    
                        isdisabled                          
                    />
                    <div className={s.icon}>
                        <MyIcon image={'password'} width={'22px'} height={'22px'} />
                    </div>
                </div>

                <div onClick={openModal} className={s.emailButton}>
                    <MyButton type="button" width={"100%"} text={"Изменить пароль"} background={"#16233A"} color={"#62708E"} border={"1px solid var(--grey)"} />
                </div>
            </div>    
            <ModalChangePassword isOpen={isOpen} onClose={closeModal} /> 
            {/* <MyModal isOpen={isOpen} onClose={closeModal}></MyModal>    */}
        </>

    )
}

export default ChangePassword;