import React, {useState, useEffect} from 'react';

import styles from './StatsHeaderStatic.module.css'
import { getMainAdminStatistics } from "../../../api/adminStatistics";


const renderObj = {
    registered: {
        label: 'Зарегистрировано хостес',
        measure: ''
    },
    durationSum: {
        label: 'Минут в привате',
        measure: ''
    },
    balanceHistory: {
        label: 'Пополнения',
        measure: '₽'
    },
    profit: {
        label: 'Прибыль',
        measure: '₽'
    },
    online: {
        label: 'Хостес онлайн',
        measure: ''
    },
    countClients: {
        label: 'Всего клиентов',
        measure: '',
    },
    withdrawalHistory: {
        label: 'Выводы',
        measure: '₽',
    },
    gifts: {
        label: 'Подарки',
        measure: '₽',
    }
}

const StatsHeaderStatic = () => {
    const [stats, setStats] = useState({});

    const getMainStatistics= async() => {
        try {
            const { data } = await getMainAdminStatistics();
            setStats(data.data);
        } catch (error) {
            console.error("getMainStatistics error", error)
        }
    }

    useEffect(() => {
        getMainStatistics()
    }, [])


    return (
        <ul className={styles.list}>
            {Object.keys(renderObj).map((item) => (
                <li key={item} className={styles.card}>
                <h3 className={styles.cardTitle}>{renderObj[item].label}</h3>
                <div className={styles.data}>
                    <p className={styles.count}>{stats?.[item] || 0}</p>
                    {renderObj?.[item]?.measure && <p className={styles.count}>{renderObj?.[item].measure}</p>}
                </div>
            </li>))}
        </ul>
    );
};

export default StatsHeaderStatic;