import {http} from './index'
import { CHATS_COUNT, NOTIFICATIONS_COUNT } from './endpoints'


export const get_count_notification = async () => {
    return await http().get(NOTIFICATIONS_COUNT)
}

export const get_count_chat = async () => {
    return await http().get(CHATS_COUNT)
}

export default {
    get_count_notification,
    get_count_chat,
}