export const OPEN_USER_POPUP = "<openUserPopup>"
export const CLOSE_USER_POPUP = "</openUserPopup>"
export const OPEN_USER_CHECK = "<checkMarkGreenText>"
export const CLOSE_USER_CHECK = "</checkMarkGreenText>"
export const OPEN_NEW_LINE = "<newLine>"
export const CLOSE_NEW_LINE = "</newLine>"
export const OPEN_PROFILE_BLOCK = "<crossRedTex>"
export const CLOSE_PROFILE_BLOCK = "</crossRedTex>"

export const modifyStringWithLink = (inputString, popUp, popUpClose, setIsModalOpen) => {
  if (inputString.includes(popUp)) {
    const startIndex = inputString.indexOf(popUp);
    const endIndex = inputString.indexOf(popUpClose, startIndex + popUp.length);

    if (startIndex !== -1 && endIndex !== -1) {
      const beforeText = inputString.substring(0, startIndex);
      const popupText = inputString.substring(startIndex + popUp.length, endIndex);
      const afterText = inputString.substring(endIndex + popUpClose.length);

      return (
        <>
          {beforeText}
          <div style={{ color: 'yellow', cursor: "pointer" }} onClick={() => setIsModalOpen(true)}>
            {popupText}
          </div>
          {afterText}
        </>
      );
    }
  }

  return inputString;
};

export const modifyStringAdmin = (inputText) => {
  const checkMarkGreenTextRegex = /<checkMarkGreenText>(.*?)<\/checkMarkGreenText>/gs;
  const checkMarkRedTextRegex = /<crossRedTex>(.*?)<\/crossRedTex>/gs;

  const newLineRegex = /<newLine>(.*?)<\/newLine>/gs;

  const textWithGreen = inputText.includes("checkMarkGreenText") ? inputText.replace(checkMarkGreenTextRegex, (match, content) => {
    return `<div style="color: green;">${content}</div>`;

  }) : "";

  const textWithRed = inputText.includes("crossRedTex") ? inputText.replace(checkMarkRedTextRegex, (match, content) => {
    return `<div style="color: darkRed;">${content}</div>`;

  }) : "";

  const resText = textWithGreen ? textWithGreen : textWithRed

  const textWithNewLines = resText.replace(newLineRegex, (match, content) => {
    return `<br><div style="margin-left: -34px;">${content}</div>`;
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: textWithNewLines }} />
  );
};






