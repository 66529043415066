import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import s from "./UserProfileFill.module.css"

import { Form, Formik, Field } from "formik";
import { useEffect, useState } from "react";
import { fillUserValidate } from "../../../settings/validation";
import { add_avatar, user_edit, user_info, user_verify } from "../../../api/user";
import { useToast } from "rc-toastr"
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import RatesUser from "../../ratesUser/RatesUser";
import { setUserProfile } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import {TooltipIcon} from "../../tooltipIcon/tooltipIcon";

const fillUserSchema = fillUserValidate;
const MAX_ABOUT_CHARACTER_LIMIT = 120;

const UserProfileFill = () => {
    const dispatch = useDispatch()
    const { toast } = useToast()
    const fileInputRef = useRef(null);
    const navigate = useNavigate()
    const [userAvatar, setUserAvatar] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [aboutCharacterCount, setAboutCharacterCount] = useState(0);
    const [fillUserErrors, setFillUserErrors] = useState({
        name: '',
        telegram: '',
        about: '',
        likes1: "",
        likes2: "",
        likes3: "",
        likes4: "",
        likes5: "",
        likes6: "",
    });
  
    const initialValues = {
        name: '',
        telegram: '',
        about: '',
        likes1: '',
        likes2: '',
        likes3: '',
        likes4: '',
        likes5: '',
        likes6: '',
    };

    const userFillHandler = (values) => {
        if (isLoading) {
            return Promise.reject("Already loading");
        }
        const {
            name,
            telegram,
            about,
            likes1,
            likes2,
            likes3,
            likes4,
            likes5,
            likes6,
            camera,
            rules,
            microfone,
        } = values;

        const dataLikes = {
            likes1,
            likes2,
            likes3,
            likes4,
            likes5,
            likes6
          };

        const likesValues = [likes1, likes2, likes3, likes4, likes5, likes6];
        const nonEmptyLikesCount = likesValues.filter(like => like.trim() !== '').length;

        if (nonEmptyLikesCount < 3) {
            setFillUserErrors(prevErrors => ({
                ...prevErrors,
                likes1: "Заполните хотя бы три поля 'Интересы'",
                likes2: "Заполните хотя бы три поля 'Интересы'",
                likes3: "Заполните хотя бы три поля 'Интересы'",
            }));
            return Promise.reject("Not enough interests filled");
        }

        const request = {
            "username": name,
            "telegram": telegram,
            "online": 1,
            "about_me": about,
            "interests": JSON.stringify(dataLikes),
            "hasCamera": camera ? 1 : 0,
            "hasMicrophone": microfone ? 1 : 0,
            "familiarWithRules": rules ? 1 : 0
        }
        
        setIsLoading(true);

        return Promise.all([
            user_edit(request),
            user_verify()
        ])
        .then(([editResponse, verifyResponse]) => {
            setIsLoading(false);
            navigate("/user/user-info");
        })
        .catch((error) => {
            console.error("error userFill change", error);
            setIsLoading(false);
            throw error;
        });
    };

    const userInfo = async () => {        
        try {
            const { data } = await user_info() 
            dispatch(setUserProfile(data.data))
        } catch (error) {
          console.error("error userInfo")
        }
    };
    
    const changeHandler = () => {
        setFillUserErrors({
            name: '',
            telegram: '',
            about: '',
            likes1: "",
            likes2: "",
            likes3: "",
            likes4: "",
            likes5: "",
            likes6: "",
        });
    }  

    const addAvatar = () => {
        fileInputRef.current.click();
    }

    const handleFileUpload = async(e) => {
        if (isLoading) {
            return
        }
        const file = e.target.files[0];

        try {
            setIsLoading(true)
            const { data } = await add_avatar({ file })
            toast.success("Аватарка загружается...")
            setUserAvatar(data.link)
        } catch (error) {
            console.error("error", error)
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        userInfo()
    }, [])

    return (
        <div className={s.container}>
            <div className={s.header}>
                <div className={s.textHeader}>Профиль</div>
                <TooltipIcon
                    image={"info"}
                    width={"25px"}
                    height={"24px"}
                    position={'bottom'}
                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                />
            </div>

            <div className={s.avatarStar}>
                <div className={s.avatar}>
                    {userAvatar ? (
                            <div className={s.avatarAva}>
                                {window.innerWidth >= 821 ? (
                                    <img
                                        style={{objectFit: 'cover'}}  
                                        src={userAvatar}
                                        alt="avatar"
                                    />
                                ): (
                                    <img 
                                        style={{objectFit: 'cover'}} 
                                        src={userAvatar}
                                        alt="avatar"
                                    />
                                )}
        
                            </div>
                        ) : (
                            <div className={s.avatarImg}>
                                {window.innerWidth >= 821 ? (
                                    <MyIcon 
                                        image={"avatar"}
                                        width={"81.5px"}
                                        height={"81.5px"}
                                    />
                                ): (
                                    <MyIcon 
                                        image={"avatar"}
                                        width={"51.5px"}
                                        height={"51.5px"}
                                    />
                                )}

                            </div>                        
                        )}


                        <div onClick={addAvatar} className={s.avatarAdd}>
                            {window.innerWidth >= 821 ? (
                                <>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                        accept="image/*"
                                        ref={fileInputRef}
                                    />
                                    <MyIcon 
                                        image={"addAvatar"}
                                        width={"27px"}
                                        height={"27px"}
                                    />
                                </>

                            ) : (
                                <MyIcon 
                                    image={"addAvatar"}
                                    width={"19px"}
                                    height={"19px"}
                                />
                            )}
                    </div>
                </div>

                <RatesUser />
            </div>

            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={fillUserSchema}
                    onSubmit={values => userFillHandler(values)}
                >
                    {({ errors, touched }) => {

                        let err = {
                            name: '',
                            telegram: '',
                            about: '',
                        };

                        if (fillUserErrors.name || fillUserErrors.telegram || fillUserErrors.about) {
                        err = fillUserErrors
                        } else {
                        err = errors
                        }

                        const nameError = err.name && touched.name;
                        const telegramError = err.telegram && touched.telegram;
                        const aboutError = err.about && touched.about;

                        return (
                            <Form>
                                <div className={s.userFill}>
                                    <div className={s.inputsBlock}>
                                        <div className={s.input}>
                                            <div className={s.header}>
                                                <div className={s.textInput}>Имя</div>
                                                <TooltipIcon
                                                    image={"info"}
                                                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                />
                                            </div>
                                            <div className={s.settingsIcon}>
                                                <MyInput 
                                                    type="text"
                                                    name="name"
                                                    placeholder="Введите имя"
                                                    label={'Имя'}
                                                    isError={nameError}
                                                    errorText={err.name}
                                                    resetErrors={changeHandler} 
                                                    width={"100%"}
                                                    color={"#475063"}
                                                />
                                                <div className={s.iconInInput}>
                                                    <MyIcon 
                                                        image={"inputName"}
                                                        width={"24px"}
                                                        height={"24px"}
                                                    />
                                                </div>
                                            </div>
                                            {touched.name && errors.name && <div style={{ color: 'darkred', marginTop: "5px" }}>{errors.name}</div>}
                                        </div>


                                        <div className={s.input}>
                                            <div className={s.header}>
                                                <div className={s.textInput}>Telegram</div>
                                                <TooltipIcon
                                                    image={"info"}
                                                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                />
                                            </div>
                                            <div className={s.settingsIcon}>
                                                <MyInput 
                                                    type="text"
                                                    name="telegram"
                                                    placeholder="Ваше имя пользователя"
                                                    label={'Telegram'}
                                                    isError={telegramError}
                                                    errorText={err.telegram}
                                                    resetErrors={changeHandler}
                                                    width={"100%"} 
                                                    color={"#475063"}
                                                />
                                                <div className={s.iconInInput}>
                                                    <MyIcon 
                                                        image={"inputTelegram"}
                                                        width={"24px"}
                                                        height={"24px"}
                                                    />
                                                </div>
                                            </div>
                                            {touched.telegram && errors.telegram && <div style={{ color: 'darkred', marginTop: "5px" }}>{errors.telegram}</div>}
                                        </div>

                                    </div>

                                    <div className={s.about}>
                                        <div className={s.header}>
                                            <div className={s.textInput}>О себе</div>
                                            <TooltipIcon
                                                image={"info"}
                                                text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                            />
                                        </div>
                                        <MyInput 
                                            type="textarea"
                                            name="about"
                                            placeholder="Придумайте свою уникальную особенность &quot;Приношу удачу&quot;, &quot;Со мной не соскучишься&quot;"
                                            label={"О себе"}
                                            isError={aboutError}
                                            errorText={err.about}
                                            resetErrors={changeHandler} 
                                            width={"100%"}
                                            color={"#475063"}
                                            height={"90px"}
                                            component={'textarea'}
                                            rows={'4'}
                                            isMaxLength={MAX_ABOUT_CHARACTER_LIMIT}
                                            onKeyDown={(e) => {
                                                if (e.target.value.length <= MAX_ABOUT_CHARACTER_LIMIT) {
                                                    setAboutCharacterCount(e.target.value.length);
                                                }
                                            }}
                                        />
                                        {touched.about && errors.about && <div style={{ color: 'darkred', marginTop: "5px" }}>{errors.about}</div>}
                                        {!errors.about && <div
                                                className={s.limit}>{`${aboutCharacterCount} / ${MAX_ABOUT_CHARACTER_LIMIT}`}</div>}
                                    </div>

                                    {window.innerHeight >= 821 ? (
                                        <div className={s.likes}>
                                            <div className={s.header}>
                                                <div className={s.textInput}>Интересы</div>
                                                <TooltipIcon
                                                    image={"info"}
                                                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                />
                                            </div>
                                            <div className={s.likesInputs}>
                                                <div className={s.likesInput}>
                                                    <MyInput 
                                                        type="text"
                                                        name="likes1"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes2"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes3"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />
                                                </div>
                                                {((touched.likes1 && errors.likes1) || (touched.likes2 && errors.likes2) || (touched.likes3 && errors.likes3)) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{errors.likes1 ? errors.likes1 : errors.likes2 ? errors.likes2 : errors.likes3 ? errors.likes3 : null}</span>}

                                                {(fillUserErrors.likes1 || fillUserErrors.likes2 || fillUserErrors.likes3) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{fillUserErrors.likes1 ? fillUserErrors.likes1 : fillUserErrors.likes2 ? fillUserErrors.likes2 : fillUserErrors.likes3 ? fillUserErrors.likes3 : null}</span>}

                                                <div className={s.likesInput}>
                                                    <MyInput 
                                                        type="text"
                                                        name="likes4"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes5"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes6"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"32%"}
                                                    />                                                
                                                </div>
                                                {((touched.likes4 && errors.likes4) || (touched.likes5 && errors.likes5) || (touched.likes6 && errors.likes6)) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{errors.likes4 ? errors.likes4 : errors.likes5 ? errors.likes5 : errors.likes6 ? errors.likes6 : null}</span>}

                                                {(fillUserErrors.likes4 || fillUserErrors.likes5 || fillUserErrors.likes6) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{fillUserErrors.likes4 ? fillUserErrors.likes4 : fillUserErrors.likes5 ? fillUserErrors.likes5 : fillUserErrors.likes6 ? fillUserErrors.likes6 : null}</span>}


                                            </div>
                                        </div>
                                    ) : (
                                        <div className={s.likes}>
                                            <div className={s.header}>
                                                <div className={s.textInput}>Интересы</div>
                                                <TooltipIcon
                                                    image={"info"}
                                                    text={'Придумайте творческий имя-псевдоним для нашего проекта, хорошие примеры: Польза, Удача, Фортуна, Виктория, Олимпия, Афина, Надежда'}
                                                />
                                            </div>
                                            <div className={s.likesInputs}>
                                                <div className={s.likesInput}>
                                                    <MyInput 
                                                        type="text"
                                                        name="likes1"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes2"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />
                                                </div>
                                                {((touched.likes1 && errors.likes1) || (touched.likes2 && errors.likes2)) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{errors.likes1 ? errors.likes1 : errors.likes2 ? errors.likes2 : null}</span>}

                                                {(fillUserErrors.likes1 || fillUserErrors.likes2) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{fillUserErrors.likes1 ? fillUserErrors.likes1 : fillUserErrors.likes2 ? fillUserErrors.likes2 : null}</span>}



                                                <div className={s.likesInput}>
                                                    <MyInput 
                                                        type="text"
                                                        name="likes3"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes4"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />                                                
                                                </div>
                                                {((touched.likes3 && errors.likes3) || (touched.likes4 && errors.likes4)) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{errors.likes3 ? errors.likes3 : errors.likes4 ? errors.likes4 : null}</span>}

                                                {(fillUserErrors.likes3 || fillUserErrors.likes4) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{fillUserErrors.likes3 ? fillUserErrors.likes3 : fillUserErrors.likes4 ? fillUserErrors.likes4 : null}</span>}


                                                <div className={s.likesInput}>
                                                    <MyInput 
                                                        type="text"
                                                        name="likes5"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />
                                                    <MyInput 
                                                        type="text"
                                                        name="likes6"
                                                        placeholder=""
                                                        resetErrors={changeHandler} 
                                                        width={"50%"}
                                                    />                                                
                                                </div>
                                                {((touched.likes5 && errors.likes5) || (touched.likes6 && errors.likes6)) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{errors.likes5 ? errors.likes5 : errors.likes6 ? errors.likes6 : null}</span>}

                                                {(fillUserErrors.likes5 || fillUserErrors.likes6) 
                                                    && <span style={{ color: 'darkred', marginTop: "5px" }}>{fillUserErrors.likes5 ? fillUserErrors.likes5 : fillUserErrors.likes6 ? fillUserErrors.likes6 : null}</span>}

                                            </div>
                                        </div>                                        
                                    )}


                                    <div className={s.checkboxBlock}>
                                        <div className={s.checkboxs}>
                                            <label className={s.checkbox}>
                                                <Field type="checkbox" name="camera" value="1" />
                                                <span>Наличие камеры</span>
                                            </label>
                                            <label className={s.checkbox}>
                                                <Field type="checkbox" name="microfone" value="1" />
                                                <span>Наличие микрофона</span>
                                            </label>
                                        </div>

                                        <label className={s.checkbox}>
                                            <Field 
                                                type="checkbox" 
                                                name="rules" 
                                                value="1" 
                                            />
                                            <span>
                                                С правилами ознакомлена
                                            </span>
                                        </label> 
                                    </div>

                                    <div className={s.submitButton}>
                                        <MyButton text={"Запросить проверку"} />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default UserProfileFill;