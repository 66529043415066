
import React from 'react'
import ChatTab from '../../components/chatTab/ChatTab'


const index = () => {

  return (
      <ChatTab />
  )
}

export default index