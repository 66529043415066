import React from 'react';
import s from "./NotificationsList.module.css"
import MyIcon from "../../UI/myIcon/MyIcon"

const NotificationsList = ({ 
  sortedNotifications, 
  selectedNotification, 
  handleNotificationClick,
  countUnreadNotification 
}) => {

  return (
    <div className={s.notifications}>
        {sortedNotifications.map((notification) => (
          notification.type !== "admin" && notification.type !== "others" && notification.type !== "team_applications" && (
            <div
              className={`${selectedNotification === notification.type ? s.notificationItemActive : s.notificationItem}`}
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
            >
              <div className={`${selectedNotification === notification.type ? s.notificationNameActive : s.notificationName}`}>
                <MyIcon
                  image={notification.type} 
                  width={'20px'} 
                  height={'20px'}
                />
                {notification.name}
              </div>
              <div className={`${selectedNotification === notification.type ? s.notificationCountActive : s.notificationCount}`}>
                
                {countUnreadNotification.map((item, index) => (
                  item.type === notification.type && <div className={s.notificationCountBorder} key={index}>{item.unreadNotificationCount}</div>
                ))}

                {window.innerWidth <= 820 && selectedNotification === notification.type ? (
                  <MyIcon
                    image={"downArrow"} 
                    width={'17px'} 
                    height={'17px'}
                  />  
                ) : (
                  <MyIcon
                    image={"rightArrow"} 
                    width={'24px'} 
                    height={'24px'}
                  />  
                )} 
              </div>
            </div>
          )
        ))}        
    </div>
  );
};

export default NotificationsList;
