import {http} from './index'
import { BALANCY_EXCHANGE, BALANCY_EXCHANGE_HISTORY, BALANCY_EXCHANGE_WITHDRAWALS, BALANCY_HISTORY, BALANCY_SETTINGS_UPSET, BALANCY_SHOW_KEY, BALANCY_USER, BALANCY_WITHDRAWALS_HISTORY } from './endpoints'

export const getBalancyHistory = async (page) => {
    return await http().get(`${BALANCY_HISTORY}?page=${page ? page : 1}&limit=20`)

}

export const getBalancyWithDrawalsHistory = async (page) => {
    return await http().get(`${BALANCY_WITHDRAWALS_HISTORY}?page=${page ? page : 1}`)

}

export const getBalancyExchangeHistory = async (page) => {
    return await http().get(`${BALANCY_EXCHANGE_HISTORY}?page=${page ? page : 1}`)
}

export const get_balancy_user = async () => {
    return await http().get(BALANCY_USER)
}

export const post_balancy_exchange = async (hearts) => {
    return await http().post(BALANCY_EXCHANGE, { hearts })
}

export const post_balancy_settings_upset = async ({ key, value }) => {
    return await http().post(BALANCY_SETTINGS_UPSET, { key, value })
}

export const post_balancy_settings_show_key = async (key) => {
    return await http().get(`${BALANCY_SHOW_KEY}${key}`)
}

export const post_exchange_withdrawals = async ({ amount, cardNumber }) => {
    return await http().post(BALANCY_EXCHANGE_WITHDRAWALS, {amount, cardNumber})
}

export const balancy_history_filter = async ({ filter, page }) => {
    if (filter === "") {
        return await http().get(`${BALANCY_HISTORY}?page=${page ? page : 1}&limit=20`)
    } else {
        return await http().get(`${BALANCY_HISTORY}?method=${filter}`)
    }
}

export default {
    getBalancyHistory,
    getBalancyWithDrawalsHistory,
    getBalancyExchangeHistory,
    post_balancy_exchange,
    post_exchange_withdrawals,
    get_balancy_user,
    post_balancy_settings_upset,
    balancy_history_filter,
}