import React from 'react'
import s from "./ReferralRates.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon"
import PaginationReferral from '../paginationReferral/PaginationReferral'

const ReferralRates = ({ setRatesListIsUpdate, ratesListIsUpdate }) => {

  return (
    <div className={s.container}>
      <div className={s.header}>
            <MyIcon 
              image={"whiteStar"}
              width={"20px"}
              height={"20px"}
            />          
          Рейтинг команд за текущий месяц        
      </div>
      <PaginationReferral setRatesListIsUpdate={setRatesListIsUpdate} ratesListIsUpdate={ratesListIsUpdate} />
    </div>
  )
}

export default ReferralRates