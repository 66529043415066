import { createSlice } from "@reduxjs/toolkit";

export const loginLogOutSlice = createSlice({
  name: 'loginLogOut',
  initialState: {
    loginUser: {
        email: "",
        // password: "",
        isAuth: false,
    },
    accessToken: ""
  },
  reducers: {
    setLogin: (state, action) => {
        // state.loginUser.email = action.payload.email;
        state.loginUser.isAuth = true
    },
    setLogOut: (state, action) => {
      state.loginUser.isAuth = false;
      state.accessToken = null;

      localStorage.setItem('user', '{}');
      localStorage.setItem('access_token', '');
    },
    setAccessToken: (state, action) => {
      localStorage.setItem("access_token", action.payload);
      state.accessToken = action.payload.accessToken;
  },
  }
})

export const selectAuth = (state) => state.loginLogOut.loginUser.isAuth;
export const selectAccessToken = (state) => state.registration.accessToken;
export const selectUser = (state) => state.loginLogOut.loginUser;

export const {
  setLogin,
  setLogOut,
  setAccessToken
} = loginLogOutSlice.actions

export default loginLogOutSlice.reducer