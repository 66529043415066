import s from "./Settings.module.css"

import ChangeEmail from "./changeEmail/ChangeEmail";
import ChangePassword from "./changePassword/ChangePassword";

const Settings = () => {  
    return (
        <div className={s.container}>
            <div className={s.headerText}>Настройки</div>
            <div className={s.settingsBlocks}>
                <div className={s.block}>
                    <ChangeEmail />
                </div>
                <div className={s.block}>
                    <ChangePassword />
                </div>
                
            </div>
        </div>
    )
}

export default Settings;