
import React, { useState } from 'react'
import MyIcon from "../../UI/myIcon/MyIcon"
import s from "./RulesQuestions.module.css"


  const RulesQuestions = ({ title, questions, onSelectQuestion, onQuestion, selectedQuestion }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className={s.menuItem}>
        <div className={isOpen ? s.itemActive : s.item} onClick={toggleOpen}>
          <h3 className={isOpen ? s.headerTextActive : s.headerText}>{title}</h3>
          <span>{isOpen ? (
            <MyIcon
                image={"upArrow"}
                width={"18px"}
                height={"18px"}
            />
          ) : (
            <MyIcon
                image={"downArrow"}
                width={"18px"}
                height={"18px"}
        />
          )}</span>
        </div>
        {isOpen && (
          <div className={s.questions}>
            {questions.map((question, index) => (
              <div 
                className={`${question === selectedQuestion ? s.selectedQuestion : s.question}`} 
                key={index} 
              onClick={() => {
                onSelectQuestion(question);
                onQuestion(title);
              }}
                >
                    {question}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

export default RulesQuestions