
import React from 'react'
import GeneralStats from "../../components/generalStats/GeneralStats";

const GeneralStatistics = () => {
  return (
    <div style={{display: 'flex', flex: 1}}>
       <GeneralStats />
    </div>
  )
}

export default GeneralStatistics