import React, { useState } from "react";
import s from "./ModalChangePassword.module.css";
import MyIcon from "../../UI/myIcon/MyIcon";
import { Form, Formik } from "formik";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import { resetPasswordValidate } from "../../../settings/validation";
import { change_password } from "../../../api/user";

const changePasswordSchema = resetPasswordValidate;

const ModalChangePassword = ({ isOpen, onClose }) => {
  const [passwordErr, setPasswordErr] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordNewVisible, setPasswordNewVisible] = useState(false);
  const [passwordNewConfirmVisible, setPasswordNewConfirmVisible] = useState(false);
  const [changePasswordErrors, setChangePasswordErrors] = useState({
      password: '',
      password_new: '',
      password_new_confirmation: '',
  });

  const initialValues = {
      password: '',
      password_new: '',
      password_new_confirmation: '',
    };

  const changePasswordHandler = async (values) => {
    const {
      password,
      password_new,
      password_new_confirmation,
    } = values;

    const request = {
      "currentPassword": password,
      "newPassword": password_new,
      "newPasswordConfirmation": password_new_confirmation
   }
   
    try {
      await change_password(request)
      closeModal()
    } catch (error) {
      console.error("error changePassword", error.response.data.message)
      if (error.response.data.message === "Current password is invalid") {
        setChangePasswordErrors({
          password: 'Текущий пароль недействителен',
          password_new: '',
          password_new_confirmation: '',
        });
      } else {
        setPasswordErr(error.response.data.message);
      }
    }

  };

  const changeHandler = () => {
   setChangePasswordErrors({
      password: '',
      password_new: '',
      password_new_confirmation: '',
    });
  }       

  const showToggle = (values) => {
      values === "password_new_confirmation" && setPasswordNewConfirmVisible(!passwordNewConfirmVisible)
      values === "password_new" && setPasswordNewVisible(!passwordNewVisible)
      values === "password" && setPasswordVisible(!passwordVisible)
  }
    if (!isOpen) return null;

    const closeModal = () => {
      onClose();
    };
  
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };

  return (
        <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={values => changePasswordHandler(values)}
        >
            {({ errors, touched }) => {

            let err = {
                password: '',
                password_new: '',
                password_new_confirmation: '',
            };

            if (changePasswordErrors.password || changePasswordErrors.password_new || changePasswordErrors.password_new_confirmation) {
                err = changePasswordErrors
            } else {
                err = errors
            }

            const passwordError = err.password && touched.password;
            const passwordNewError = err.password_new && touched.password_new;
            const passwordNewConfirmationError = err.password_new_confirmation && touched.password_new_confirmation;

            return (
              <Form>
                <div className={s.modalOverlay} onClick={handleOverlayClick}>
                  <div className={s.modal}>
                    <button type="button" className={s.modalClose} onClick={onClose}>
                      <MyIcon image={"close"} width={"16px"} height={"16px"} />
                    </button>
                    <div>
                        <h2 className={s.modalHeader}>Изменить пароль</h2>        

                        <div className={s.inputSettings}>
                            <MyInput
                                type={passwordVisible ? 'text' : 'password'}
                                name="password"
                                placeholder="Текущий пароль"
                                label={'password'}
                                isError={passwordError}
                                errorText={err.password}
                                resetErrors={changeHandler}                                        
                            />
                            <div className={s.icon}>
                                <MyIcon                     
                                    onClick={() => showToggle("password")}
                                    image={'password'} 
                                    width={'22px'} 
                                    height={'22px'} 
                                />
                            </div>
                        </div>
                        {touched.password && errors.password && <div style={{ color: 'darkred', marginTop: "5px" }}>{errors.password}</div>}
                        {changePasswordErrors.password && <div style={{ color: 'darkred', marginTop: "5px" }}>{changePasswordErrors.password}</div>}

                        <div className={s.inputSettings}>
                            <MyInput 
                                type={passwordNewVisible ? 'text' : 'password'}
                                name="password_new"
                                placeholder="Новый пароль"
                                label={'password_new'}
                                isError={passwordNewError}
                                errorText={err.password_new}
                                resetErrors={changeHandler}                                        
                            />
                            <div className={s.icon}>
                                <MyIcon                     
                                    onClick={() => showToggle("password_new")}
                                    image={'password'} 
                                    width={'22px'} 
                                    height={'22px'} 
                                />
                            </div>
                        </div>
                        {touched.password_new && errors.password_new && <div style={{ color: 'darkred', marginTop: "5px" }}>{errors.password_new}</div>}

                        <div className={s.inputSettings}>
                            <MyInput 
                                type={passwordNewConfirmVisible ? 'text' : 'password'}
                                name="password_new_confirmation"
                                placeholder="Повторите новый пароль"
                                label={'password_new_confirmation'}
                                isError={passwordNewConfirmationError}
                                errorText={err.password_new_confirmation}
                                resetErrors={changeHandler}                                        
                            />
                            <div className={s.icon}>
                                <MyIcon                     
                                    onClick={() => showToggle("password_new_confirmation")}
                                    image={'password'} 
                                    width={'22px'} 
                                    height={'22px'} 
                                />
                            </div>
                        </div>
                        {touched.password_new_confirmation && errors.password_new_confirmation && <div style={{ color: 'darkred', marginTop: "5px"  }}>{errors.password_new_confirmation}</div>}

                        <div className={s.button}>
                            <MyButton width={"100%"} text={"Изменить пароль"} background={"#FFDC64"} color={"#051423"} />
                        </div>

                        {passwordErr !== "" && <div style={{ color: 'darkred', marginTop: "10px" }}>{passwordErr}</div>}
                                        
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
  );
};

export default ModalChangePassword;
