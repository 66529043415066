import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { setLogOut } from "./redux/slices/loginLogOutSlice";

// Layouts
import MainLayout from "./layouts/mainLayout/MainLayout";
import LoginLayout from "./layouts/loginLayout/LoginLayout";

// Pages
import {
  Home,
  Register,
  Login,
  Forgot,
  UserInfo,
  UserChange,
  Notifications,
  Rules,
  Schedule,
  Balance,
  Chat,
  NotFound,
  PrivateChat,
  ResetPassword,
  Referral,
  GeneralStatistics,
} from "./pages";

import "./App.css";
import { selectIsAdminUser } from "./redux/slices/userSlice";
import PrivateStreamPage from "./components/privateStream/PrivateStream";

function App() {
  const dispatch = useDispatch();
  const isAdminState = useSelector(selectIsAdminUser);
  const navigate = useNavigate();

  const isAccessToken = localStorage.getItem("access_token");

  const logOut = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const urlToken = queryParams.get("token");
    const urlReferralLink = queryParams.get("referral-link");

    if (urlToken) {
      navigate(`/?token=${urlToken}`);
    } else if (urlReferralLink) {
      navigate(`register/?referral-link=${urlReferralLink}`);
    } else {
      navigate("/");
    }

    try {
      dispatch(setLogOut());
    } catch (error) {
      dispatch(setLogOut());
    }
  };

  useEffect(() => {
    if (!isAccessToken) {
      logOut();
    }
  }, [isAccessToken]);

  return (
    <Routes>
      {isAccessToken ? (
        isAdminState ? (
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<GeneralStatistics />} />
            <Route path="/user/user-info" element={<UserInfo />} />
            <Route path="/user/user-change" element={<UserChange />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/:id" element={<PrivateChat />} />
            <Route path="/video-chat" element={<PrivateStreamPage />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/balance" element={<Balance />} />
            <Route element={<NotFound />} />
            <Route path="/account" element={<Home fromAdmin />} />
          </Route>
        ) : (
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/user/user-info" element={<UserInfo />} />
            <Route path="/user/user-change" element={<UserChange />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/balance" element={<Balance />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/chat/:id" element={<PrivateChat />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/video-chat" element={<PrivateStreamPage />} />
            <Route element={<NotFound />} />
          </Route>
        )
      ) : (
        <Route path="/" element={<LoginLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<NotFound />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;
