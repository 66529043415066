import MyIcon from '../UI/myIcon/MyIcon';
import MainMenuItems from '../mainMenuItems/MainMenuItems';

import s from './Navbar.module.css';
import logo from '../../images/logo.svg';
import crossMenu from '../../images/crossMenu.svg';
import mainMenuData from './data';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLogOut } from '../../redux/slices/loginLogOutSlice';
import { get_count_chat, get_count_notification } from '../../api/navbar';
import { reinvite_chat, get_active_video_chat, post_reinvite_request } from '../../api/video';
import {
  selectIsUpdateCountChats,
  selectUserId,
  selectUserProfile,
  setIsAdminUser,
  setIsMyStream,
  setIsStream,
  setUpdateCountChats,
  setUserProfile,
  setIsPrivateVideo,
  setCallerInfo,
} from '../../redux/slices/userSlice';
import {
  selectChatIdRoom,
  selectIsNotificationCountChange,
  selectNotificationType,
  selectPublicChatId,
  setChatIdRoom,
  setIsNotificationCountChange,
  setIsPublicChat,
  setIsVideoPublic,
} from '../../redux/slices/webSocketSlice';
import { useWebConnectionSocket } from '../../socket/webSocketConnection';
import { user_info, user_online } from '../../api/user';
import { get_video_active_id } from '../../api/video';
import Online from '../online/Online';
import UnicodeList from '../utfModal/UnicodeList';
import {
  customConfirm, socket
} from './../../lib/video/helpers';
import {PrivateHostes} from "../../lib/video/private";

let client = new PrivateHostes();

const Navbar = ({ setOpenNavbar, openNavbar }) => {
  const socketConnection = useWebConnectionSocket();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedNotificationState = useSelector(selectNotificationType);
  const userIdState = useSelector(selectUserId);
  const userProfileState = useSelector(selectUserProfile);
  const selectedIsNotificationCountChangeState = useSelector(
    selectIsNotificationCountChange,
  );
  const selectPublicChatIdState = useSelector(selectPublicChatId);
  const isUnpateChatsInfo = useSelector(selectIsUpdateCountChats);
  const chatIdRoom = useSelector(selectChatIdRoom);
  const selectedNotificationRef = useRef(selectedNotificationState);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedRules, setSelectedRules] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [countNotifications, setCountNotifications] = useState(0);
  const [countChats, setCountChats] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [checkVideoStream, setCheckVideoStream] = useState(false);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    if (userIdState != null) {
      get_active_video_chat({userId: userIdState})
          .then(({data: dataChat}: any) => {
            if (dataChat.data != undefined) {
              dispatch(setIsPrivateVideo(true))
              customConfirm(
                  `У Вас есть незавершеный звонок c ${dataChat.data.participants.find((userItem) => userItem.id != userIdState).username}, хотите продолжить?`,
              ).then((confirmed) => {
                if (confirmed) {
                  post_reinvite_request({
                    chatId: dataChat.data.id,
                    userId: dataChat.data.participants.find((userItem) => userItem.id == userIdState).id
                  });
                } else {
                  setTimeout(() => {
                    dispatch(setIsPrivateVideo(false))
                    const send = {
                      videochatId: dataChat.data.id,
                      participants: {
                        callerId: dataChat.data.participants.find((userItem) => userItem.id != userIdState).id,
                        receiverId: userIdState,
                      },
                    };
                    socket.emit('end', send);
                  }, 500)
                }
              });
            }
          })
    }
  }, [userIdState]);

  useEffect(() => {
    userIdState && videoStream();
  }, [userIdState]);

  useEffect(() => {
    !checkVideoStream &&
      videoId &&
      dispatch(setIsStream(true)) &&
      localStorage.setItem('video__stream_is_now_id', videoId);
    !videoId && localStorage.setItem('video__stream_is_now_id', '');
    checkVideoStream && dispatch(setIsMyStream(true));
  }, [checkVideoStream, videoId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const scrollToUp = (event) => {
    event.preventDefault();
    window.scroll(0, 0);
  };

  const handleMenuItemClick = (itemId) => {
    setOpenNavbar(false);
    setOpenMenu(false);
    setSelectedItem(itemId);

    if ((!isAdmin && itemId === 'account') || itemId === 'general-statistics') {
      navigate(`/`);
    } else {
      navigate(`/${itemId}`);
    }
  };

  const goToRules = (e) => {
    setOpenNavbar(false);
    setOpenMenu(false);
    setSelectedRules(true);
    scrollToUp(e);
    navigate('/rules');
  };

  const LogOut = async () => {
    dispatch(setIsVideoPublic(false));
    dispatch(setIsStream(false));
    userProfileState.online === 1 && (await user_online());
    socketConnection && socketConnection.emit('producerDisconnect');
    setOpenNavbar(false);
    setOpenMenu(false);
    localStorage.removeItem('access_token');
    localStorage.removeItem('timeZones');
    localStorage.removeItem('public_id');
    localStorage.removeItem('hostesId');
    dispatch(setLogOut());

    if (
      socketConnection &&
      (!localStorage.getItem('access_token') ||
        localStorage.getItem('access_token') === '')
    ) {
      chatIdRoom && socketConnection.emit('leave_room', chatIdRoom);
      socketConnection.emit('leave_main_room');
      // socketConnection.emit("end");
      socketConnection.disconnect();
    }
    window.location.reload(true);
    navigate('/');
  };

  const getNotificationCount = async () => {
    try {
      const { data } = await get_count_notification();
      setCountNotifications(data.data.count);
      dispatch(setIsNotificationCountChange(false));
    } catch (error) {
      console.error('get_count_notification error', error);
    }
  };

  const getChtsCount = async () => {
    try {
      const { data } = await get_count_chat();
      setCountChats(data.data.count);
      dispatch(setUpdateCountChats(false));
    } catch (error) {
      console.error('get_count_chat error', error);
    }
  };

  const videoStream = async () => {
    try {
      const { data } = await get_video_active_id();
      if (data && data.data) {
        setVideoId(data.data.id);
        Number(data.data.scheduleTime.user.id) === Number(userIdState) &&
        !isAdmin
          ? setCheckVideoStream(true)
          : setCheckVideoStream(false);
      }

      data &&
        data.data &&
        Number(data.data.scheduleTime?.user.id) === Number(userIdState) &&
        dispatch(setIsVideoPublic(true));
    } catch (error) {
      if (error.response?.data.message === 'Active stream not found!') {
        return false;
      }
      console.error('videoStream error', error);
    }
  };

  const userMy = async () => {
    try {
      const { data } = await user_info();

      if (
        data.data.roles &&
        data.data.roles.length !== 0 &&
        data.data.roles.filter((role) => role.id && role.id === 1).length !== 0
      ) {
        dispatch(setIsAdminUser(true));
        setIsAdmin(true);
      } else {
        dispatch(setIsAdminUser(false));
        setIsAdmin(false);
        dispatch(setUserProfile(data.data));
      }
    } catch (error) {
      console.error('userMy', error);
    }
  };

  useEffect(() => {
    !isAdmin && userMy();
  }, [isAdmin]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (selectedIsNotificationCountChangeState) {
      const id = setTimeout(() => {
        getNotificationCount();
      }, 500);

      setTimeoutId(id);
    }
  }, [selectedIsNotificationCountChangeState]);

  useEffect(() => {
    selectedNotificationRef.current = selectedNotificationState;
  }, [selectedNotificationState]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (isUnpateChatsInfo) {
      const id = setTimeout(() => {
        getChtsCount();
      }, 300);

      setTimeoutId(id);
    }
  }, [isUnpateChatsInfo]);

  useEffect(() => {
    getNotificationCount();
    getChtsCount();
  }, []);

  useEffect(() => {
    const pathname = location.pathname.replace('/', '');
    pathname === ''
      ? isAdmin
        ? setSelectedItem('general-statistics')
        : setSelectedItem('account')
      : setSelectedItem(location.pathname.replace('/', ''));
    pathname !== 'rules' && setSelectedRules(false);

    if (!socketConnection && location.pathname === '/chat') {
      dispatch(setIsPublicChat(true));
    }

    if (
      socketConnection &&
      location.pathname !== '/chat' &&
      selectPublicChatIdState
    ) {
      socketConnection.emit('leave_pub_room ', selectPublicChatIdState);
    }

    if (socketConnection && location.pathname !== '/chat' && chatIdRoom) {
      socketConnection.emit('leave_room', chatIdRoom);
    }

    if (location.pathname === '/chat') {
      getNotificationCount();
    }

    dispatch(setChatIdRoom(''));
  }, [location.pathname, isAdmin]);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('new_notification_recall', (notification) => {
        if (JSON.parse(notification.data).onlyCall) {
          dispatch(setCallerInfo({callerId: null, receiverId: null}));
          dispatch(setIsPrivateVideo(false))

          setTimeout(() => {
            dispatch(setIsPrivateVideo(true));
            dispatch(setCallerInfo({
              callerId: JSON.parse(notification.data).callerId,
              receiverId: JSON.parse(notification.data).receiverId,
            }));
          }, 500)
        } else {
          customConfirm(
              notification.message,
          ).then((confirmed) => {
            if (confirmed) {

              dispatch(setCallerInfo({callerId: null, receiverId: null}));
              dispatch(setIsPrivateVideo(false))

              setTimeout(() => {
                dispatch(setIsPrivateVideo(true));
                dispatch(setCallerInfo({
                  callerId: JSON.parse(notification.data).callerId,
                  receiverId: JSON.parse(notification.data).receiverId,
                }));
              }, 500)
            }
          });
        }
      });

      socketConnection.on('new_notification', (notification) => {
        const currentSelectedNotification = selectedNotificationRef.current;

        notification.type.type === currentSelectedNotification &&
          socketConnection.emit('read_notification', {
            notificationId: notification.id,
          });

        if (notification.type.type === currentSelectedNotification) {
          socketConnection.on('notification_readed', (mes) => {
            setCountNotifications((prev) => (prev > 0 ? prev - 1 : prev - 0));
          });
        }

        setCountNotifications((prev) => prev + 1);
      });

      socketConnection.on('chat_new_message', (message) => {
        socketConnection.on('message_readed', (mes) => {
          setCountChats((prev) => (prev > 0 ? prev - 1 : prev - 0));
        });
        setCountChats((prev) => prev + message.countAllUnreadMessages);
      });

      socketConnection.on('producerDisconnect', (video) => {
        dispatch(setIsStream(false));
        dispatch(setIsMyStream(false));
        dispatch(setIsVideoPublic(false));
      });
    }
  }, [socketConnection]);

  return window.innerWidth >= 1279 ? (
    <div className={s.container}>
      <div className={s.settings}>
        <div className={s.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={s.time}>
          <UnicodeList />
        </div>

        {isVisible && (
          <>
            <div className={s.account}>
              <div className={s.settingsText}>НАВИГАЦИЯ</div>
              <MainMenuItems
                isAdmin={isAdmin}
                items={mainMenuData.mainMenuNavigation}
                selectedItem={selectedItem}
                onItemClick={handleMenuItemClick}
              />
            </div>

            <div className={s.account}>
              <div className={s.settingsText}>АККАУНТ</div>
              <MainMenuItems
                isAdmin={isAdmin}
                countNotifications={countNotifications}
                countChats={countChats}
                items={mainMenuData.mainMenuData}
                selectedItem={selectedItem}
                onItemClick={handleMenuItemClick}
              />
            </div>
            <div className={s.account}>
              <Online />
            </div>
          </>
        )}
      </div>
      <div className={s.footer}>
        <div
          onClick={(e) => goToRules(e)}
          className={selectedRules ? s.footerItemsActive : s.footerItems}
        >
          <MyIcon image={'rulesOfServices'} width={'16px'} height={'16px'} />
          <div>Правила сервиса</div>
        </div>

        <div onClick={LogOut} className={s.footerItems}>
          <MyIcon image={'logOut'} width={'16px'} height={'16px'} />
          <div>Выход</div>
        </div>
      </div>
    </div>
  ) : (
    <div className={s.container}>
      <div className={s.containerHeader}>
        <div
          onClick={() => (setOpenMenu(!openMenu), setOpenNavbar(!openNavbar))}
        >
          {openMenu ? (
            <img src={crossMenu} alt="crossMenu" />
          ) : (
            <MyIcon image={'menu'} width={'24px'} height={'24px'} />
          )}
        </div>
        <div>
          <MyIcon image={'logo'} width={'152px'} height={'40px'} />
        </div>
        <div className={s.lineFull}></div>
      </div>

      {openMenu && (
        <div className={s.containerMenu}>
          <div className={s.settings}>
            <div className={s.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={s.time}>
              <UnicodeList />
            </div>

            <div className={s.account}>
              <div className={s.settingsText}>НАВИГАЦИЯ</div>
              <MainMenuItems
                isAdmin={isAdmin}
                items={mainMenuData.mainMenuNavigation}
                selectedItem={selectedItem}
                onItemClick={handleMenuItemClick}
              />
            </div>

            <div className={s.account}>
              <div className={s.settingsText}>АККАУНТ</div>
              <MainMenuItems
                isAdmin={isAdmin}
                countNotifications={countNotifications}
                countChats={countChats}
                items={mainMenuData.mainMenuData}
                selectedItem={selectedItem}
                onItemClick={handleMenuItemClick}
              />
              <div className={s.isOnline}>
                <Online />
              </div>
            </div>
          </div>
          <div className={s.footer}>
            <div
              onClick={(e) => goToRules(e)}
              className={selectedRules ? s.footerItemsActive : s.footerItems}
            >
              <MyIcon
                image={'rulesOfServices'}
                width={'16px'}
                height={'16px'}
              />
              <div>Правила сервиса</div>
            </div>

            <div onClick={LogOut} className={s.footerItems}>
              <MyIcon image={'logOut'} width={'16px'} height={'16px'} />
              <div>Выход</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
