import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setLogin } from '../../redux/slices/loginLogOutSlice';
import streamMenuImg from '../../images/video/checkHostes.svg';

// Styles
import s from './MainLayout.module.css';
import Navbar from '../../components/navbar/Navbar';
import ChatPublic from '../../components/publicChatModal/chatPublic/ChatPublic';
import {
  selectIsMyStream,
  selectIsPrivateVideo,
} from '../../redux/slices/userSlice';
import PrivatVideoChat from '../../components/privatVideoChat/PrivatVideoChat';
import { setIsPrivateVideo as setIsPrivateVideoInStorage } from '../../redux/slices/userSlice';

const MainLayout = () => {
  const dispatch = useDispatch();
  const isStreamState = useSelector(selectIsMyStream);
  const isPrivateVideoState = useSelector(selectIsPrivateVideo);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [openStream, setOpenStream] = useState(isStreamState);
  const [openPrivateVideo, setOpenPrivateVideo] = useState(isStreamState);
  const [isStream, setIsStream] = useState(false);
  const [isPrivateVideo, setIsPrivateVideo] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const location = useLocation();
  const isAuth = localStorage.getItem('access_token');

  useEffect(() => {
    if (isStreamState) {
      setIsStream(true);
      setOpenStream(true);
    } else {
      setIsStream(false);
      setOpenStream(false);
    }
  }, [isStreamState]);

  useEffect(() => {
    isPrivateVideoState ? setIsPrivateVideo(true) : setIsPrivateVideo(false);
    isPrivateVideoState
      ? setOpenPrivateVideo(true)
      : setOpenPrivateVideo(false);
  }, [isPrivateVideoState]);

  useEffect(() => {
    if (isAuth) {
      dispatch(setAccessToken(localStorage.getItem('access_token')));
      dispatch(setLogin(localStorage.getItem('user')));
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSetIsPrivateVideo = useCallback(
    (value) => {
      setIsPrivateVideo(value);
      dispatch(setIsPrivateVideoInStorage(value));
    },
    [dispatch],
  );

  return (
    <div
      style={{ minHeight: windowHeight }}
      className={`${
        location.pathname.includes('/chat') && windowWidth < 821
          ? s.containerMainChat
          : s.containerMain
      } ${s.mediaMain}`}
    >
      {windowWidth >= 1279 ? (
        <div className={s.itemNavbar}>
          <Navbar setOpenNavbar={setOpenNavbar} openNavbar={openNavbar} />
        </div>
      ) : (
        <div
          className={`${
            location.pathname.includes('/chat') && windowWidth < 821
              ? s.containerMainChat
              : s.containerMain
          } ${s.mediaMain}`}
        >
          <Navbar setOpenNavbar={setOpenNavbar} openNavbar={openNavbar} />
        </div>
      )}
      <div className={openNavbar ? s.itemChildrenOpen : s.itemChildren}>
        <Outlet />
        {isStream && (
          <div className={openStream ? s.stream : s.streamClose}>
            <ChatPublic
              closeStreamModal={() => setOpenStream(!openStream)}
              openStream={openStream}
            />
            <div
              className={s.streamMenuIn}
              onClick={() => setOpenStream(!openStream)}
            >
              <img src={streamMenuImg} alt="STREAM" />
            </div>
          </div>
        )}

        {isPrivateVideo && (
          <div
            className={openPrivateVideo ? s.modalStream : s.modalStreamClose}
          >
            <PrivatVideoChat
              setIsPrivateVideo={onSetIsPrivateVideo}
              openPrivateVideo={openPrivateVideo}
              isModal
            />
            <div
              className={s.streamMenuIn}
            >
              <img src={streamMenuImg} alt="VIDEO" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
