import React from "react";
import MyIcon from "../UI/myIcon/MyIcon";
import s from "./MainMenuItems.module.css";

const MainMenuItems = ({ isAdmin, countChats, countNotifications, items, selectedItem, onItemClick }) => {
  return (
    <div className={s.mainMenu}>
      {items.map((item) => (
        isAdmin ? (
            <div
              key={item.id}
              className={`${s.mainItems} ${selectedItem === item.id ? s.selected : s.notSelected}`}
              onClick={() => onItemClick(item.id)}
            >
              <MyIcon 
                image={item.image} 
                width={item.width} 
                height={item.height} 
              />
              <div className={`${selectedItem === item.id ? s.selected : s.text}`}>
                  <div>{item.label}</div>
                  {item.label === "Уведомления" && <div className={s.count}>{countNotifications}</div>}
                  {item.label === "Чат" && <div className={s.count}>{countChats}</div>}          
              </div>
          </div>
        ) : (item.label !== "Общая статистика" && (
                <div
                  key={item.id}
                  className={`${s.mainItems} ${selectedItem === item.id ? s.selected : s.notSelected}`}
                  onClick={() => onItemClick(item.id)}
                >
                  <MyIcon 
                    image={item.image} 
                    width={item.width} 
                    height={item.height} 
                  />
                  <div className={`${selectedItem === item.id ? s.selected : s.text}`}>
                      <div>{item.label}</div>
                      {item.label === "Уведомления" && <div className={s.count}>{countNotifications}</div>}
                      {item.label === "Чат" && <div className={s.count}>{countChats}</div>}          
                  </div>
                </div>
            )
          )
      ))}
    </div>
  );
};

export default MainMenuItems;