import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import io from 'socket.io-client';
import { socket } from './socket'; // Импорт из файла socket.js
import { selectAuth } from '../redux/slices/loginLogOutSlice';

const WebSocketContext = createContext();

export const useWebConnectionSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const isAuth = useSelector(selectAuth);
  const [socketConnection, setSocketConnection] = useState(null);
  const [authenticationToken, setAuthenticationToken] = useState(
    localStorage.getItem('access_token'),
  );

  useEffect(() => {
    isAuth
      ? setAuthenticationToken(localStorage.getItem('access_token'))
      : setAuthenticationToken(localStorage.getItem('access_token'));
  }, [isAuth]);

  useEffect(() => {
    // const currentToken = localStorage.getItem('access_token');
    // if (currentToken == token) return;

    // if (token) localStorage.setItem('access_token', token);
    // else localStorage.removeItem('access_token');

    if (socket) {
      if (isAuth && authenticationToken && authenticationToken !== '') {
        const onConnect = () => {
          console.log('Connected to WebSocket server');
        };
        // socket.io.opts.query = { token: authenticationToken }
        // socket.connect();
        // socket.on('connect', onConnect);

        socket.emit('setAuthToken', authenticationToken || '');
        socket.emit('join_main_room');

        setSocketConnection(socket);

        return () => {
          if (socket) {
            socket.off('join_main_room');
            socket.off('connect', onConnect);
            socket.emit('leave_main_room');
            // socket.disconnect();
          }
        };
      } 
      // else {
        // socket.emit("leave_main_room")
        // socket.disconnect();
      // }
    }
  }, [isAuth, authenticationToken]);

  return (
    <WebSocketContext.Provider value={socketConnection}>
      {children}
    </WebSocketContext.Provider>
  );
};
