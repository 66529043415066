
import React from 'react'
import RulesTab from '../../components/rulesTab/RulesTab'

const Rules = () => {
  return (
        <RulesTab />
  )
}

export default Rules