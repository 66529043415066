import React, {useEffect, useRef, useState} from "react";
import s from "./PublicStream.module.css";

import {socket} from "../../socket/socket";
import {useDispatch, useSelector} from "react-redux";
import {selectIsVideoPublic} from "../../redux/slices/webSocketSlice";
import {setIsMyStream, setIsPrivateVideo, setIsStream,} from "../../redux/slices/userSlice";
import {useToast} from "rc-toastr";

import {PublicProducer} from "../../lib/video/public";

let client = new PublicProducer();

function PublicStream({closeStreamModal, mutedRemote, setMutedRemote}) {
    const dispatch = useDispatch();
    const isVideoPublic = useSelector(selectIsVideoPublic);
    const videoRef = useRef<any>(null);
    const {toast} = useToast();

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [countConsumers, setCountConsumers] = useState<Number>(0);
    const [webrtcStatus, setWebrtcStatus] = useState<string>("");

    const [publicProducer, setPublicProducer] = useState(client);

    useEffect(() => {
        publicProducer.toggleAudio(!mutedRemote)
    }, [mutedRemote]);

    useEffect(() => {
        const onProducerDisconnect = async (video) => {
            publicProducer && (await publicProducer.close());
            dispatch(setIsStream(false));
            dispatch(setIsPrivateVideo(false));
            dispatch(setIsMyStream(false));
            videoRef.current = null;
        };

        publicProducer.events.on("stream", async (stream: MediaStream) => {
            if (videoRef.current) {
                videoRef.current.srcObject = await stream;
            }
        });

        publicProducer.events.on("connection", async (status: string) => {
            setWebrtcStatus(status);
        });

        socket.on("producerDisconnect", onProducerDisconnect);

        return () => {
            socket.off("producerDisconnect", onProducerDisconnect);
        };
    }, [publicProducer]);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        const onUpdateCountConsumers = (count: number) => {
            setCountConsumers(count);
        };

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("updateCountConsumers", onUpdateCountConsumers);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("updateCountConsumers", onUpdateCountConsumers);
        };
    }, []);

    useEffect(() => {
        if (!isVideoPublic) {
            publicProducer.close();
            dispatch(setIsStream(false));
        }
    }, [isVideoPublic]);

    useEffect(() => {
        isConnected && ["", "failed"].includes(webrtcStatus) && onBtnPublish();
    }, [isConnected, webrtcStatus]);

    async function onBtnPublish() {
        try {
            await publicProducer.createDevice();
            await publicProducer.startStream();
        } catch (error) {
            toast.error(
                "Вы не можете начать стрим потому что у вас заблокирован доступ к камере или к микрофону"
            );
            closeStreamModal();
        }
    }

    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <video
                    ref={videoRef}
                    autoPlay
                    // controls
                    muted
                    style={{width: "100%", height: "250px"}}
                ></video>

                <div style={{marginLeft: "20px"}}>
                    {/* <div>Connect to {environment.SOCKET_URL}</div>
          <div>Socket status: {isConnected ? "connected" : "off"}</div>
          <div>Webrtc status: {webrtcStatus}</div>

          <div>Consumers: {countConsumers + ""}</div> */}

                    {/* <div style={{ marginTop: "20px" }}>
            {isConnected && ["", "failed"].includes(webrtcStatus) && (
              <div>
                <button style={{marginRight: "20px"}} onClick={onBtnPublish}>
                  Stream
                </button>
              </div>
            )}
          </div> */}
                </div>
            </div>
        </div>
    );
}

export default PublicStream;
