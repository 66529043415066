import React, { useState, useEffect, useRef } from "react";
import { formatDateTime } from "../../../../settings/formatDate";

import s from "./PaginationHistoryReferral.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon";
import { get_referral_balance_history } from "../../../../api/referral";

const PaginationHistoryReferral = ({ balanceListIsUpdate, setBalanceListIsUpdate }) => {
  const containerRef = useRef(null);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLast, setPageLast] = useState(1);
 
  useEffect(() => {
    if (balanceListIsUpdate) {
      setPage(1);
      setTransactions([])
      loadTransactions()
    }
  }, [balanceListIsUpdate])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
    container.addEventListener('scroll', handleScroll);

    return () => {
        container.removeEventListener('scroll', handleScroll);
    };
    }
  }, [page, pageLast, transactions]);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
        timer = setTimeout(() => {
        loadTransactions()
        }, 300);
    } 
    }

    return () => {
        clearTimeout(timer);
    };
  };

  const loadTransactions = async () => {
    try {
        const { data } = await get_referral_balance_history(balanceListIsUpdate ? 1 : page);
        setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
        setPage((prevPage) => prevPage + 1);
        setPageLast(data.pagination.lastPage);
        setBalanceListIsUpdate(false)
    } catch (error) {
        console.error("getReferralHistory error", error)
    }
  };

  useEffect(() => {
    loadTransactions()
  }, [])

  return (
    <div ref={containerRef} className={s.paginationReferralHistory}>
        {transactions.length === 0 ? (
          <div className={s.empty}>Пригласите новых участниц что бы получать бонусы</div>
        ) : (
          transactions.map((item, index) => (
              <div
                  className={`${s.container} ${index % 2 === 0 ? s.evenBackground : s.oddBackground}`}
                  key={index}
              >
                  <div className={s.username}>{item.sender.username}</div>
                  <div className={s.count}>
                      <MyIcon
                          image={"miniLogo"} 
                          width={"19"}
                          height={"19"}
                      />
                      <div>x</div>
                      <div className={s.amount}>{item.amount}</div>
                  </div>
                  <div className={s.data}>{formatDateTime(item.createdAt)}</div>
              </div>
          ))         
        )}

    </div>
  );
};

export default PaginationHistoryReferral;
