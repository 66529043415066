import {http} from './index'
import { GET_STATISTICS } from './endpoints'

/**
 * Get statistics on main page for info on interface
 *
 */

export const getStatistics = async () => {
    return await http().get(GET_STATISTICS)
}

export default getStatistics;