export function makeListUnique(existingList, newItem) {
      const existingIds = new Set(existingList.map(item => item.id));
  
      if (existingIds.has(newItem.id)) {
        return existingList;
      }

      return [newItem, ...existingList];
}

export const getUniqueListId = (list) => {
  return list.reduce((uniqueArray, currentItem) => {
    const existingItem = uniqueArray.find((item) => item.id === currentItem.id);
    if (!existingItem) {
      return [...uniqueArray, currentItem];
    }
    return uniqueArray;
  }, []);
};

export const getUniqueListUserId = (list) => {
  return list.reduce((uniqueArray, currentItem) => {
    const existingItem = uniqueArray.find((item) => item.user_id === currentItem.user_id);
    if (!existingItem) {
      return [...uniqueArray, currentItem];
    }
    return uniqueArray;
  }, []);
};