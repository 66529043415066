import Settings from "../../../components/settings/Settings";
import Statistics from "../../../components/statistics/Statistics";
import UserProfileChange from "../../../components/userProfile/userProfileChange/UserProfileChange";


const UserChange = () => {

    return (
        <div className="homePage">
            <div className="homePage__userProfile">
                <UserProfileChange />
            </div>

            <div className="homePage__statSettings">
                <Statistics />
                <Settings />
            </div>
        </div>
    )
}

export default UserChange;