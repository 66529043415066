import MyInput from "../UI/myInput/MyInput";
import MyButton from "../UI/myButton/MyButton";
import MyIcon from "../UI/myIcon/MyIcon";

import mainImg from "../../images/register_img.svg";
import mainImgMobile from "../../images/register_img_mobile.svg";

import s from "./ResetPassword.module.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { resetPasswordNewPaswordValidate } from "../../settings/validation";
import { Form, Formik } from "formik";
import { reset_password } from "../../api/user";
import { useToast } from "rc-toastr";


const SignUpSchema = resetPasswordNewPaswordValidate;

const ResetPassword = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpErrors, setSignUpErrors] = useState({
      password: '',
    });

  const initialValues = {
      password: '',
    };

  const getTokenFromSearch = (search) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("token");
  };

  const signUpHandler = async (values) => {
    if (isLoading) {
      return;
    }
    const token = getTokenFromSearch(search);

    const {
      password,
    } = values;

    const regValues = {
      password,
      token
    }

    try {
      setIsLoading(true)
      await reset_password(regValues)
      navigate("/")
    } catch (error) {
      toast.error(error.response.data.message)
      console.error("res.data.message", error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  };

  const changeHandler = () => {
    setSignUpErrors({
      password: '',
    });
  }       

  const showToggle = () => {
    setPasswordVisible(!passwordVisible)
  }

    return (
        <div>
            <Formik
              initialValues={initialValues}
              validationSchema={SignUpSchema}
              onSubmit={values => signUpHandler(values)}
            >
              {({ errors, touched }) => {
                let err = {
                  password: '',
                };

                if (signUpErrors.passworn) {
                  err = signUpErrors
                } else {
                  err = errors
                }
                const passwordError = err.password && touched.password;
                return (
                    <Form>
                        <div className={s.container}>
                            <picture className={s.items}>
                                <source media="(max-width: 912px)" srcSet={mainImgMobile} />
                                <source media="(min-width: 913px)" srcSet={mainImg} />
                                <img className={s.background_image} src={mainImg} alt="img" />
                            </picture>
                            <div className={s.items}>
                                <div className={s.item}>
                                    <div className={s.itemHeader}>Введите новый пароль</div>
    
                                    <div className={s.inputSettings}>
                                        <MyInput 
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Пароль"
                                            label={'password'}
                                            isError={passwordError}
                                            errorText={err.password}
                                            resetErrors={changeHandler}                                        
                                        />
                                        <div className={s.icon}>
                                            <MyIcon                     
                                                onClick={showToggle}
                                                image={'password'} 
                                                width={'22px'} 
                                                height={'22px'} 
                                            />
                                        </div>
                                        {touched.password && errors.password && <div style={{ color: 'darkred' }}>{errors.password}</div>}
                                    </div>
    
                                    <MyButton type="submit" text="Подтвердить" background="" color={"var(--dark-blue)"} />

                                    <div className={s.notAccountBlock}>
                                        <div>Вернуться на логин</div>
                                        <div className={s.regLink}>
                                            <Link to={"/"}>сюда</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
              }}
            </Formik>
        </div>
    )
}

export default ResetPassword;