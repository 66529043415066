
import React from 'react'
import s from "./HistoryExchange.module.css"
import MyIcon from '../../UI/myIcon/MyIcon'
import PaginationHistoryExchange from '../paginationHistoryExchange/PaginationHistoryExchange'

const HistoryExchange = ({ getExchangeHistoryData, setGetExchangeHistoryData }) => {
  return (
    <div className={s.container}>
        <div className={s.header}>
            <MyIcon 
                image={"historyExchange"}
                width={"20px"}
                height={"20px"}
            />
            <div>
                История обмена
            </div>
        </div>

        <div className={s.headerMini}>
            <div>
                Отдали
            </div>
            <div>
                Получили
            </div>
            <div>
                Дата и время
            </div>
        </div>
            <PaginationHistoryExchange getExchangeHistoryData={getExchangeHistoryData} setGetExchangeHistoryData={setGetExchangeHistoryData} />
    </div>
  )
}

export default HistoryExchange