import React, { useState, useEffect, useRef } from "react";

import s from "./PaginationTeamsList.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon";
import { get_team_list, get_team_request_join, post_join_team } from "../../../../api/referral";
import { useToast } from "rc-toastr";

const PaginationTeamsList = ({ teamsListIsUpdate, setTeamsListIsUpdate }) => {
  const containerRef = useRef(null);
  const { toast } = useToast()
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLast, setPageLast] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [teamIdCheck, setTeamIdCheck] = useState(null)

 
  useEffect(() => {
    if (teamsListIsUpdate) {
      setPage(1);
      setTransactions([])
      loadTransactions()
    }
  }, [teamsListIsUpdate])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
    container.addEventListener('scroll', handleScroll);

    return () => {
        container.removeEventListener('scroll', handleScroll);
    };
    }
  }, [page, pageLast, transactions]);

  useEffect(() => {
    teamIdRequest()
    loadTransactions()
  }, [])

  const teamIdRequest = async () => {
    try {
      const { data } = await get_team_request_join()
      setTeamIdCheck(data.data)
    } catch (error) {
      console.error("joinTeam ", error)
    }
  }

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
        timer = setTimeout(() => {
        loadTransactions()
        }, 300);
    } 
    }

    return () => {
        clearTimeout(timer);
    };
  };

  const loadTransactions = async () => {
    try {
        setIsLoading(true);
        const { data } = await get_team_list(teamsListIsUpdate ? 1 : page);
        setIsLoading(false);
        setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
        setPage((prevPage) => prevPage + 1);
        setPageLast(data.pagination.lastPage);
        setTeamsListIsUpdate(false)
    } catch (error) {
        console.error("getReferralHistory error", error)
    }
  };

  const joinTeam = async(teamId) => {
    if (isLoading) {
      return Promise.reject("Already loading");
    }

    try {
      setIsLoading(true);
      await post_join_team({ teamId })
      teamIdRequest()
    } catch (error) {
      console.error("joinTeam ", error);
      error.response.data.message ===
      "Access denied. Your account is not verified."
        ? toast.error("Ваша анкета на проверке")
        : toast.error(error.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div ref={containerRef} className={s.paginationTeamsList} style={{ height: window.innerWidth >= 1280 ? window.innerHeight/1.72 : 'max-content', maxHeight:'250px', overflow:'auto'}}>
        {transactions && transactions.map((teamItem, index) => {
          const matchingTeam = teamIdCheck && teamIdCheck.find(item => item.team.id === teamItem.team_id);
          const isLast = index === transactions.length - 1;
            return (
                <div key={index} className={s.main} style={isLast ? { borderBottom: 'none' } : {}}>
                    {window.innerWidth > 1100 ? (
                        <div className={s.mainT}>
                            <div className={s.mainFirstTeam}>
                                <div>
                                    {index + 1}
                                </div>    
                                <div>
                                    {teamItem?.team_name.length > 10 ? `${teamItem?.team_name.slice(0, 10)}...` : teamItem?.team_name}
                                </div>        
                            </div>

                            <div className={s.headerCount}>
                                <MyIcon image={"starFill"} width={"16px"} height={"16px"} />
                                {teamItem.avgRating ? Number(teamItem.avgRating).toFixed(1) : 0}
                            </div>
                            <div className={s.headerCount}>
                                <MyIcon image={"miniLogo"} width={"19px"} height={"19px"} />
                                x {teamItem.totalHearts ? teamItem.totalHearts.toFixed(2) : 0}
                            </div>
                            
                            {matchingTeam ? (
                                <div className={`${s.buttonRequest} ${s.send}`}>
                                  Запрос отправлен
                                </div> 
                            ) : (
                                <div onClick={() => joinTeam(teamItem.team_id)} className={`${s.buttonRequest} ${s.notsend}`}>
                                  Отправить запрос
                                </div> 
                            )}
                        </div>
                    ) : (
                        <div className={s.mainT}>
                            <div className={s.mainFirstAdd}>
                                {index + 1}
                                <div>{teamItem?.team_name}</div>
                            </div>

                            <div className={s.mainSecond}>
                                <div className={s.mainMobile}>
                                    <div className={s.headerCountAdd}>
                                        <MyIcon image={"starFill"} width={"16px"} height={"16px"} />
                                        {teamItem.avgRating ? Number(teamItem.avgRating).toFixed(1) : 0}
                                    </div>
                                    <div className={s.headerCountAdd}>
                                        <MyIcon image={"miniLogo"} width={"19px"} height={"19px"} />
                                        x {teamItem.totalHearts ? teamItem.totalHearts.toFixed(2) : 0}
                                    </div>      

                                    {matchingTeam ? (
                                        <div className={`${s.buttonRequest} ${s.send}`}>
                                          Запрос отправлен
                                        </div> 
                                    ) : (
                                        <div onClick={() => joinTeam(teamItem.team_id)} className={`${s.buttonRequest} ${s.notsend}`}>
                                          Отправить запрос
                                        </div> 
                                    )}                    
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        })}
    </div>
  );
};

export default PaginationTeamsList;
