import React, { useEffect, useRef, useState } from 'react';
import s from './ChatPublic.module.css';
import { useWebConnectionSocket } from '../../../socket/webSocketConnection';
import {
  createUniqueArray,
  isLink,
  sortedData,
} from '../../../settings/helpers';
import infoImg from '../../../images/video/info.svg';
import crystalImg from '../../../images/video/crystal.svg';
import burgerMenu from '../../../images/video/burgerMenu.svg';
import send from '../../../images/video/send.svg';
import { selectUserId } from '../../../redux/slices/userSlice';
import { useSelector } from 'react-redux';
import voice from './img/voice.svg';
import microphoneOff from './img/microphone-off.svg';

import Rules from '../rules/Rules';
import { get_chat_id, get_chat_public } from '../../../api/chats';
import PublicStream from '../../publicStream/PublicStream';

const MAX_CHARACTER_LIMIT = 160;

const ChatPublic = ({ openStream, closeStreamModal }) => {
  const containerRef = useRef(null);
  const socketConnection = useWebConnectionSocket();
  const userIdState = useSelector(selectUserId);
  const [messageInput, setMessageInput] = useState('');
  const [checkMessage, setCheckMessage] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);
  const [characterCount, setCharacterCount] = useState(0);
  const [publicChatId, setPublicChatId] = useState(null);
  const [publicChatItems, setPublicChatItems] = useState([]);
  const [openRules, setOpenRules] = useState(false);
  const [upScroll, setUpScroll] = useState(false);
  const [reciveScrol, setReciveScrol] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [mutedRemote, setMutedRemote] = useState(false);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit('join_pub_room', publicChatId);
      socketConnection.on('receive_pub_message', (message) => {
        if (Number(publicChatId) === Number(message.chat.id)) {
          const res = {
            id: message.id,
            text: message.text,
            status: message.status,
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
            sender: message.sender,
          };
          setPublicChatItems((prev) => [...prev, res]);
        }
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      });
    }
  }, [publicChatId, containerRef.current]);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('public_chat_online', (online) => {
        setOnlineUsers(online);
      });
    }
  }, [socketConnection]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, pageLast, publicChatItems]);

  useEffect(() => {
    if (reciveScrol && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (upScroll && containerRef.current) {
      containerRef.current.scrollTop = 443;
    }
  }, [reciveScrol, upScroll, publicChatItems]);

  useEffect(() => {
    if (checkMessage && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [checkMessage]);

  useEffect(() => {
    if (messageInput !== '') {
      setCheckMessage(false);
      setUpScroll(false);
      setReciveScrol(false);
    }
  }, [messageInput]);

  useEffect(() => {
    getChatPublic();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
      if (container && container.scrollTop === 0) {
        setCheckMessage(false);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timer = setTimeout(() => {
          getMessageList(publicChatId, page);
        }, 300);

        setTimeoutId(timer);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    setCheckMessage(true);
    setReciveScrol(true);

    if (socketConnection && messageInput.trim() !== '') {
      const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');
      socketConnection.emit('send_pub_message', {
        userId: userIdState,
        chatId: publicChatId,
        message: cleanedMessageInput,
      });
      setMessageInput('');
    }
    setCharacterCount(0);
  };

  const getChatPublic = async () => {
    try {
      const { data } = await get_chat_public();
      setPublicChatId(data.data.id);
      getMessageList(data.data.id, 1);
      localStorage.setItem('public_id', data.data.id);
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  const getMessageList = async (id, pageNum) => {
    const param = {
      id,
      page: pageNum ? pageNum : '1',
    };

    try {
      pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
      pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
      const { data } = await get_chat_id(param);
      const sorted = sortedData(data.data);
      setPublicChatItems((prevPublicChatItems) => [
        ...sorted,
        ...publicChatItems,
      ]);
      setPageLast(data.pagination.lastPage);
      data.pagination.nextPage
        ? setPage(data.pagination.nextPage)
        : setPage(page + 1);
    } catch (error) {
      console.error('getMessageInfo err', error);
    }
  };

  return (
    <div className={`${openStream ? s.slideIn : s.closeModalStream}`}>
      <PublicStream
        mutedRemote={mutedRemote}
        setMutedRemote={setMutedRemote}
        closeStreamModal={closeStreamModal}
      />
      <div className={s.header}>
        <div>
          Online: <span>{onlineUsers}</span>
        </div>
        <div onClick={() => setOpenRules(!openRules)} className={s.info}>
          <img src={infoImg} alt="info" />
        </div>

        {openRules && (
          <div className={s.rules}>
            <Rules setOpenRules={setOpenRules} />
          </div>
        )}
      </div>

      <div className={`${s.chat}`}>
        <div
          ref={containerRef}
          className={`${s.container}`}
          style={{ height: '250px' }}
        >
          {publicChatItems &&
            createUniqueArray(publicChatItems, 'id').map((item, index) => (
              <div className={`${s.items}`} key={index}>
                <div className={s.item}>
                  <div className={s.headerChat}>
                    <div className={s.userName}>
                      <img src={crystalImg} alt="crystalImg" />
                      <div>{item.sender.username}</div>
                    </div>
                    <div>
                      <img src={burgerMenu} alt="burgerMenu" />
                    </div>
                  </div>
                  <div className={s.adaptTextBlock}>
                    {isLink(item.text) ? (
                      <a href={item.text}>{item.text}</a>
                    ) : (
                      item.text
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={s.footer}>
        <div className={s.messageBlock}>
          <div className={s.messageInput}>
            <input
              type="text"
              style={{ marginRight: 10 }}
              placeholder="Введите сообщение"
              value={messageInput}
              onChange={(e) => {
                if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                  setMessageInput(e.target.value);
                  setCharacterCount(e.target.value.length);
                }
              }}
              onKeyDown={handleKeyDown}
            />
            <img
              onClick={() => {
                setMutedRemote((state) => !state);
              }}
              src={mutedRemote ? microphoneOff : voice}
              alt="microphoneIcon"
              style={{ width: '26px', height: '26px' }}
            />
            <div className={s.send}>
              <div>{`${characterCount} / ${MAX_CHARACTER_LIMIT}`}</div>
              <div onClick={sendMessage}>
                <img src={send} alt="send" />
              </div>
            </div>
          </div>
          <div className={s.line}></div>
        </div>
      </div>
    </div>
  );
};

export default ChatPublic;
