import { Socket, io } from "socket.io-client";
import environment from "./environment";

const URL = environment.SOCKET_URL;

// export const socket = io(URL);
export const socket: Socket = io(URL, {
  transports: ['websocket'],
  upgrade: false,
});

export async function socketPromise(
  emit: string,
  data: any = {}
): Promise<any> {
  return new Promise((resolve, reject) => {
    socket.emit(emit, data, (resData: any) => {
      resolve(resData);
    });
  });
}
