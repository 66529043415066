import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../socket/socket";
import s from "./PublicVideo.module.css"
import streamImg from "../../images/stream.svg" 
import { selectIsStream } from "../../redux/slices/userSlice";
import { useSelector } from 'react-redux';

import { PublicConsumer } from "../../lib/video/public";


function PublicVideo() {
  const videoRef = useRef<any>(null);
  const isStreamState = useSelector(selectIsStream)
  
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [countConsumers, setCountConsumers] = useState<Number>(0);
  const [webrtcStatus, setWebrtcStatus] = useState<string>("");
  const [isStream, setIsStream] = useState(isStreamState);

  useEffect(() => {
    isStreamState ? setIsStream(true) : setIsStream(false)
  }, [isStreamState])

  const [publicConsumer, setPublicConsumer] = useState<
    PublicConsumer | undefined
  >();

  useEffect(() => {
    const onNewProducer = () => {
      if (publicConsumer) onBtnSubscribe();
    };

    socket.on("newProducer", onNewProducer);

    return () => {
      socket.off("newProducer", onNewProducer);
    };
  }, [publicConsumer]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    const onUpdateCountConsumers = (count: number) => {
      setCountConsumers(count);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("updateCountConsumers", onUpdateCountConsumers);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("updateCountConsumers", onUpdateCountConsumers);
    };
  }, []);

  useEffect(() => {
    if(isConnected && ["", "failed"].includes(webrtcStatus) && localStorage.getItem("video__stream_is_now_id")) {
      onBtnSubscribe()
    }
  }, [isConnected, webrtcStatus])

  async function onBtnSubscribe() {

    const newPublicConsumer = new PublicConsumer();
    setPublicConsumer(newPublicConsumer);

    newPublicConsumer.events.on("stream", async (stream: MediaStream) => {
      videoRef.current.srcObject = await stream;
    });

    newPublicConsumer.events.on("connection", async (status: string) => {
      setWebrtcStatus(status);
    });

    await newPublicConsumer.createDevice();
    await newPublicConsumer.joinStream();
  }

  return (
        <div className={s.container}>
          <div className={s.video} style={{  height: window.innerHeight / 4 }}>
            {isStream ? (
              <div style={{ position: "relative",  width: "100%", height: "100%", display: "flex" }}>
                <video
                  ref={videoRef}
                  autoPlay
                  controls
                  // muted
                  style={{  width: "100%", height: "100%" }}
                ></video>

                <div style={{ marginLeft: "20px" }}>
                  {/* <div>Connect to {environment.SOCKET_URL}</div>
                  <div>Socket status: {isConnected ? "connected" : "off"}</div>
                  <div>Webrtc status: {webrtcStatus}</div>

                  <div>Consumers: {countConsumers + ""}</div> */}

                  {/* <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                    {isConnected && ["", "failed"].includes(webrtcStatus) && (
                      <div>

                        <button style={{padding: "10px"}} onClick={onBtnSubscribe}>Subscribe</button>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            ) : (
              <div className={s.checkHostes}>
                <img src={streamImg} alt="streamImg" />
              </div> 
            )}

          </div>    
        </div>      
  );
}

export default PublicVideo;
