import styles from './PrivatVideoChat.module.css';
import arrow from './img/arrowDown.svg';
import share from './img/share.svg';
import remove from './img/remove.svg';
import add from './img/add.svg';
import info from './img/info.svg';
import redArrow from './img/arrowRed.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import PrivateStream from '../privateStream/PrivateStream';
import Chat from './chat/Chat';
import {
  friend_add,
  friend_del,
  get_chat_id,
  get_count_unread_message_for_chat_id,
  user_friend_check,
} from '../../api/chats';
import { sortedData } from '../../settings/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChatIdRoomInVideoCall,
  selectVideoCallId,
} from '../../redux/slices/webSocketSlice';
import Download from '../download/Download';
import { socket } from '../../socket/socket';
import { selectCallerInfo, setCallerInfo } from '../../redux/slices/userSlice';
import { ChatRulesBig } from '../chatRulesBig';
import Draggable from 'react-draggable';
import { PrivateHostes } from '../../lib/video/private';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css'

let client = new PrivateHostes();

const INITIAL_MODAL_HEIGHT = 723
const INITIAL_MODAL_WIDTH = 500

const INITIAL_HIDE_MODAL_HEIGHT = 422
const INITIAL_HIDE_MODAL_WIDTH = 500

const PrivatVideoChat = ({
  isModal,
  openPrivateVideo,
  setIsPrivateVideo,
  isPrivateVideo,
}) => {
  const dispatch = useDispatch();
  const chatIdRoomInVideoState = useSelector(selectChatIdRoomInVideoCall);
  const videoCallIdState = useSelector(selectVideoCallId);
  const selectCallerInfoState = useSelector(selectCallerInfo);
  const [activeItem, setActiveItem] = useState(1);
  const [chatsItem, setChatItems] = useState([]);
  const [reciveScrol, setReciveScrol] = useState(true);
  const [upScroll, setUpScroll] = useState(false);
  const [privateChat, setPrivateChat] = useState([]);
  const [countUnreadMessage, setCountUnreadMessage] = useState([]);
  const [checkMessageStatus, setCheckMessageStatus] = useState({});
  const [checkMessagesStatus, setCheckMessagesStatus] = useState({});
  const [chatIdRoomInVideo, setChatIdRoomInVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFriend, setIsFriend] = useState(0);
  const [microphoneBlock, setMicrophoneBlock] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [visibleRules, setVisibleRules] = useState(false);
  const [blockVisible, setVisibleBlock] = useState(true);
  const draggableRef = useRef(null);
  const dragContainerRef = useRef(null);
  const [dragContainerWidth, setDragContainerWidth] = useState(0);
  const [dragContainereHeight, setDragContainerHeight] = useState(0);
  const [privateHostes, setPrivateHostes] = useState(client)
  const [dragContainerPositions, setDragContainerPositions] = useState({top: 0, left: 0})

  const remoteVideoRef = useRef(null);


  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);

  const getMessageInfo = async (id, pageNum) => {
    const param = {
      id,
      page: pageNum ? pageNum : '1',
    };

    try {
      pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
      pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
      const { data } = await get_chat_id(param);
      const sorted = sortedData(data.data);
      setPrivateChat((prevPrivateChat) => [...sorted, ...prevPrivateChat]);
      setPageLast(data.pagination.lastPage);
      data.pagination.nextPage
        ? setPage(data.pagination.nextPage)
        : setPage(page + 1);
    } catch (error) {
      console.error('getMessageInfo err', error);
    }
  };

  const updatePrivateHostes = useCallback(() => {
    client = new PrivateHostes();
    setPrivateHostes(client);
  }, [])

  const getChatCount = async (id) => {
    try {
      const res = await get_count_unread_message_for_chat_id(id);
      const updatedCountUnreadMessage = countUnreadMessage.map((item) => {
        if (Number(item.chat_id) === Number(id)) {
          return {
            ...item,
            unreadMessageCount: res.data.data.count,
          };
        }
        return item;
      });

      setCountUnreadMessage(updatedCountUnreadMessage);
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  const getCheckFriends = async () => {
    try {
      const { data } = await user_friend_check({
        userId: selectCallerInfoState.receiverId,
      });
      setIsFriend(data.data.status);
    } catch (error) {
      console.error('error tafe friends', error);
    }
  };

  const addToFriend = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await friend_add({ userId: selectCallerInfoState.receiverId });
      setIsFriend(2);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFriend = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await friend_del({ userId: selectCallerInfoState.receiverId });
      setIsFriend(0);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    chatIdRoomInVideoState
      ? setChatIdRoomInVideo(true)
      : setChatIdRoomInVideo(false);
    chatIdRoomInVideoState && getMessageInfo(chatIdRoomInVideoState, 1);
  }, [chatIdRoomInVideoState]);

  useEffect(() => {
    setPrivateChat([]);
    selectCallerInfoState.receiverId && getCheckFriends();
  }, [chatIdRoomInVideoState, selectCallerInfoState]);

  useEffect(() => {
    if (checkMessageStatus && privateChat) {
      const updatedPrivateChat = privateChat.map((item) => {
        if (Number(item.id) === Number(checkMessageStatus.messsageId)) {
          return {
            ...item,
            ...checkMessageStatus,
          };
        }
        return item;
      });

      setPrivateChat(updatedPrivateChat);
    }
  }, [checkMessageStatus]);

  useEffect(() => {
    if (checkMessagesStatus && privateChat) {
      const updatedPrivateChats = privateChat.map((chat) => {
        if (checkMessagesStatus.messages.includes(chat.id)) {
          return { ...chat, status: 3 };
        }
        return chat;
      });
      setPrivateChat(updatedPrivateChats);
    }
  }, [checkMessagesStatus]);

  const handleClick = (num) => {
    setActiveItem(num);
    setVisibleBlock(true);
  };

  const handleEndVideoCall = () => {
    if (videoCallIdState) {
      socket.emit('end', { videochatId: videoCallIdState });
    } else {
      const send = {
        videochatId: videoCallIdState ? videoCallIdState : null,
        participants: {
          callerId: selectCallerInfoState.callerId,
          receiverId: selectCallerInfoState.receiverId,
        },
      };
      socket.emit('end', send);
    }

    privateHostes.events.emit("close");
    updatePrivateHostes()
    
    dispatch(setCallerInfo({ callerId: null, receiverId: null }));
    setIsPrivateVideo(false)
  };

  const fullScreen = () => {
    if (remoteVideoRef.current) {
      if (remoteVideoRef.current.requestFullscreen) {
        remoteVideoRef.current.requestFullscreen();
      } else if (remoteVideoRef.current.mozRequestFullScreen) {
        remoteVideoRef.current.mozRequestFullScreen(); // Firefox
      } else if (remoteVideoRef.current.webkitRequestFullscreen) {
        remoteVideoRef.current.webkitRequestFullscreen(); // Chrome, Safari & Opera
      } else if (remoteVideoRef.current.msRequestFullscreen) {
        remoteVideoRef.current.msRequestFullscreen(); // IE/Edge
      }
    }
  }

  useEffect(() => {
    const updateSize = () => {
      if (dragContainerRef.current) {
        const { clientWidth, clientHeight } = dragContainerRef.current;
        setDragContainerWidth(clientWidth);
        setDragContainerHeight(clientHeight);
      }
    };
    updateSize();

    const resizeObserver = new ResizeObserver(updateSize);

    if (dragContainerRef.current) {
      resizeObserver.observe(dragContainerRef.current);
    }

    return () => {
      if (dragContainerRef.current) {
        resizeObserver.unobserve(dragContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (dragContainerRef.current) {
      const [obj] = dragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
  }, [blockVisible])

  const onMainMenuDrag = () => {
    if (dragContainerRef?.current) {
      const [obj] = dragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
  }

  return (
    <div style={{ zIndex: 99999 }}>
      <Draggable
        bounds={{
          left:
            window.innerWidth <= 1700
              ? -window.innerWidth + dragContainerWidth + 100
              : -window.innerWidth + dragContainerWidth + 30,
          right: window.innerWidth <= 1700 ? 100 : 30,
          bottom:
            window.innerWidth <= 1700
              ? window.innerHeight - dragContainereHeight
              : window.innerHeight - dragContainereHeight,
          top:
            window.innerWidth <= 1700
              ? -(window.innerHeight - dragContainereHeight)
              : -(window.innerHeight - dragContainereHeight - 100),
        }}
        ref={draggableRef}
        handle={'.header'}
        onDrag={onMainMenuDrag}
        disabled={window.innerWidth <= 870}
      >
        <ResizableBox 
          width={!blockVisible 
            ? INITIAL_HIDE_MODAL_WIDTH 
            : INITIAL_MODAL_WIDTH} 
          height={!blockVisible 
            ? INITIAL_HIDE_MODAL_HEIGHT 
            : INITIAL_MODAL_HEIGHT} 
          minConstraints={[INITIAL_MODAL_WIDTH, INITIAL_MODAL_HEIGHT]} 
          maxConstraints={[INITIAL_MODAL_WIDTH + dragContainerPositions.left, INITIAL_MODAL_HEIGHT + dragContainerPositions.top]} 
          lockAspectRatio
          resizeHandles={!blockVisible ? [] : undefined}
        >
        <div
          ref={dragContainerRef}
          style={{ marginTop: blockVisible ? '0' : 'auto' }}
          className={`${styles.containerDrag} 
                ${openPrivateVideo ? styles.container : styles.closeModalStream} 
                ${blockVisible? styles.heightModalOpen : styles.heightModalClose}`}
        >
          <div className={`${styles.top} header`}>
            <p>Приватный видео чат</p>
            <img
              onClick={() => setVisibleBlock(!blockVisible)}
              className={styles.img}
              src={arrow}
              alt=""
            />
          </div>
          <div className={styles.imgContent}>
            <div className={styles.videoWrap}>
              <PrivateStream
                setOpenFullScreen={setOpenFullScreen}
                openFullScreen={openFullScreen}
                microphoneBlock={microphoneBlock}
                isModal={isModal}
                setActiveItem={setActiveItem}
                setIsPrivateVideo={setIsPrivateVideo}
                privateHostes={privateHostes}
                updatePrivateHostes={updatePrivateHostes}
                initialClient={client}
                remoteVideoRef={remoteVideoRef}
              />
            </div>
            <div className={styles.imgBottomContent}>
              <div className={styles.switcher}>
                <div className={styles.switcherBG}>
                  <p
                    onClick={() => handleClick(0)}
                    className={activeItem === 0 ? styles.active : ''}
                  >
                    Чат
                  </p>
                  <p
                    onClick={() => handleClick(1)}
                    className={activeItem === 1 ? styles.active : ''}
                  >
                    Меню
                  </p>
                </div>
              </div>
              <div className={styles.right}>
                <div
                  style={{ position: 'relative' }}
                  onClick={() => setShareModalOpen(!shareModalOpen)}
                  className={styles.share}
                >
                  <img src={share} alt="" />
                  {shareModalOpen && (
                    <div className={styles.shareModalOpen}>
                      <div onClick={fullScreen}>Развернуть</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!chatIdRoomInVideo && activeItem === 0 ? (
            <div className={styles.containerLoading}>
              <Download />
            </div>
          ) : (
            blockVisible &&
            activeItem === 0 && (
              <Chat
                chatsItem={chatsItem}
                setChatItems={setChatItems}
                setUpScroll={setUpScroll}
                reciveScrol={reciveScrol}
                setReciveScrol={setReciveScrol}
                setPrivateChat={setPrivateChat}
                chatId={chatIdRoomInVideoState}
                privateChat={privateChat}
                getMessageInfo={getMessageInfo}
                pageLast={pageLast}
                page={page}
                setMicrophoneBlock={setMicrophoneBlock}
                microphoneBlock={microphoneBlock}
                setCheckMessageStatus={setCheckMessageStatus}
                setCheckMessagesStatus={setCheckMessagesStatus}
                getChatCount={getChatCount}
                upScroll={upScroll}
              />
            )
          )}
          {blockVisible && (
            <>
              {activeItem === 1 && (
                <div className={styles.btnList}>
                  {isFriend === 1 && (
                    <div onClick={deleteFriend} className={styles.btn}>
                      Удалить из друзей <img src={remove} alt="" />
                    </div>
                  )}
                  {isFriend === 0 && (
                    <div onClick={addToFriend} className={styles.btn}>
                      Добавить в друзья <img src={add} alt="" />
                    </div>
                  )}
                  {isFriend === 2 && (
                    <div className={styles.btn}>
                      Ожидает подтверждение <img src={remove} alt="" />
                    </div>
                  )}
                  <div
                    onClick={() => setVisibleRules(!visibleRules)}
                    className={styles.btn}
                  >
                    Правила чата
                    <img src={info} alt="" />
                  </div>
                  <div onClick={handleEndVideoCall} className={styles.btn}>
                    Завершить видео чат <img src={redArrow} alt="" />
                  </div>
                </div>
              )}
            </>
          )}
          {visibleRules && (
            <div style={{ position: 'absolute', top: '0' }}>
              <ChatRulesBig setVisibleRules={setVisibleRules} />
            </div>
          )}
        </div>
        </ResizableBox>
      </Draggable>
    </div>
  );
};

export default PrivatVideoChat;
