import EventEmitter from 'events';
import {
  socketPromise,
  socket,
  TypeRecord,
  genMediaStream,
  getGuid,
  customConfirm,
  ICE_SERVERS,
} from './helpers';

class PrivateColl {
  public events = new EventEmitter();
  public peerConnection: RTCPeerConnection;
  public streams: MediaStream[] = [];
  public localStream: MediaStream | undefined;
  public uid: string = getGuid();

  public isAlreadyCalling = false;

  constructor() {
    this.peerConnection = new RTCPeerConnection({
      iceServers: ICE_SERVERS,
    });

    this.peerConnection.ontrack = ({ streams: [stream] }) =>
      this.onAddRemoteTrack(stream);

    socket.on('candidate', (data) => this.onCandidate(data));

    this.events.on('close', () => this.close());
  }

  async onAddRemoteTrack(stream: MediaStream) {
    this.streams.push(stream);
    this.events.emit('remote-stream', stream);
  }

  async onCandidate(data: { candidate: RTCIceCandidate }): Promise<void> {
    if (data?.candidate)
      this.peerConnection
        .addIceCandidate(new RTCIceCandidate(data.candidate))
        .catch(() => {});
  }

  async initLocalStream(type: TypeRecord): Promise<MediaStream> {
    const stream = await genMediaStream(type);

    this.events.emit('local-stream', stream);
    this.streams.push(stream);

    this.localStream = stream;

    stream
      .getTracks()
      .forEach((track) => this.peerConnection.addTrack(track, stream));

    return stream;
  }

  async close(): Promise<void> {
    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
    this.streams = [];
    this.localStream = undefined;

    this.peerConnection.onicecandidate = null;
    this.peerConnection.close();
  }

  async toggleVideo({ type, enable }: { type: TypeRecord; enable: boolean }) {
    if (!this.localStream) return;

    // Enable video stream
    // Create new camera/display stream and replace current stream in peerConnection
    if (enable) {
      const videoTrack = (
        await genMediaStream(type, false)
      ).getVideoTracks()[0];

      if (videoTrack) {
        this.localStream?.addTrack(videoTrack);

        for (const sender of this.peerConnection.getSenders())
          if (sender.track?.kind === 'video') sender.replaceTrack(videoTrack);
      }
    }
    // Disable camera/display stream
    else {
      await new Promise((resolve) => {
        this.localStream?.getVideoTracks().forEach((track) => {
          track.enabled = false;

          setTimeout(() => {
            this.localStream?.removeTrack(track);
            resolve(1);
          }, 1000);
        });
      });

      for (const sender of this.peerConnection.getSenders())
        if (sender.track?.kind === 'video') sender.track.stop();
    }
  }

  toggleAudio(isEnabled: boolean) {
    for (const sender of this.peerConnection.getSenders())
      if (sender?.track?.kind === 'audio') sender.track.enabled = isEnabled;
  }
}

export class PrivateClient extends PrivateColl {
  constructor() {
    super();

    const onOffer = (data: any) => this.onOffer(data);

    socket.on('offer', onOffer);

    this.events.on('close', () => {
      socket.off('offer', onOffer);
    });
  }

  async onOffer(data: {
    participants: {
      caller: {
        socketId: string;
        userId: number;
      };
      receiver: {
        socketId: string;
        userId: number;
      };
    };
    socketId: string;
    description: RTCSessionDescriptionInit;
    username: string;
  }) {
    const rejectCall = async () => {
      await socketPromise('rejectCall', {
        callerSocketId: data.participants.caller.socketId,
        receiverSocketId: data.participants.receiver.socketId,
      });
    };

    try {
      if (!this.isAlreadyCalling) {
        // const confirmed = window.confirm(
        //   `${data.username} wants to call you. Do accept this call?`,
        // );

        const confirmed = await customConfirm(
          `${data.username} wants to call you. Do accept this call?`,
        );

        if (!confirmed) return await rejectCall();

        await this.initLocalStream('screen');
        this.isAlreadyCalling = true;
      }

      await this.peerConnection.setRemoteDescription(
        new RTCSessionDescription(data.description),
      );

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(
        new RTCSessionDescription(answer),
      );

      socket.emit('answer', {
        participants: data.participants,
        description: answer,
      });
    } catch (error) {
      console.error('onOffer', error);
      await rejectCall();
    }
  }
}

export class PrivateHostes extends PrivateColl {
  public iceCandidates: RTCIceCandidate[] = [];

  public participants: any = {
    callerId: null,
    receiverId: null,
  };

  constructor() {
    super();

    const onAnswer = (data: any) => this.onAnswer(data);
    const onCallRejected = (data: any) => this.onCallRejected(data);

    socket.on('answer', onAnswer);
    socket.on('callRejected', onCallRejected);

    this.events.on('close', () => {
      socket.off('answer', onAnswer);
      socket.off('callRejected', onCallRejected);
    });
  }

  async callUser(callerId: number, receiverId: number, username: string) {
    await this.initLocalStream('camera');

    this.participants = { callerId, receiverId };

    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(
      new RTCSessionDescription(offer),
    );

    await socketPromise('offer', {
      description: offer,
      username,
      participants: {
        callerId,
        receiverId,
      },
    });

    this.peerConnection.onicecandidate = ({ candidate }) => {
      candidate?.candidate &&
        socket.emit('candidate', {
          candidate,
          participantId: callerId,
        });
    };
  }

  async restoreCall(callerId: number, receiverId: number) {
    console.log('callUser')

    const offer = await this.peerConnection.createOffer();

    this.peerConnection
        .setRemoteDescription(new RTCSessionDescription(offer))
        .catch((err) => {
          console.error(err);
        });

    if (!this.isAlreadyCalling) {
      this.callUser(
          this.participants.callerId,
          this.participants.receiverId,
          this.participants.username,
      );
      this.isAlreadyCalling = true;
    }
  }

  async onAnswer(data: {
    socketId: string;
    description: RTCSessionDescriptionInit;
    videochat: any;
  }) {
    if (data.videochat) {
      this.peerConnection
        .setRemoteDescription(new RTCSessionDescription(data.description))
        .catch((err) => {
          console.error(err);
        });

      if (!this.isAlreadyCalling) {
        this.callUser(
          this.participants.callerId,
          this.participants.receiverId,
          this.participants.username,
        );
        this.isAlreadyCalling = true;
      }
    }
  }

  async onCallRejected(data: { socketId: string }) {
    alert(`User: "Socket: ${data.socketId}" rejected your call.`);
    this.isAlreadyCalling = false;
  }
}
