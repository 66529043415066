
import React from 'react'
import s from "./RulesAnswer.module.css"

const RulesAnswer = ({ selectedQuestion, selectedTitle, answers }) => {
  const answer =  answers[selectedQuestion] || '';

  return (
    <div className={s.container}>
      <h3 className={s.header}>{selectedTitle}</h3>
      <div className={s.textBlock}>
        <div className={s.title}>{selectedQuestion}</div> 
        <div dangerouslySetInnerHTML={{__html: answer}} className={s.text}/>
      </div>
    </div>
  );
};

export default RulesAnswer