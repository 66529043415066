import { useNavigate } from "react-router-dom";
import MyIcon from "../../UI/myIcon/MyIcon";
import s from "./UserProfileInfo.module.css"
import RatesUser from "../../ratesUser/RatesUser";
import { user_other, user_verify } from "../../../api/user";
import { useEffect, useState } from "react";
import MyButton from "../../UI/myButton/MyButton";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdminUser, selectUserProfile, setUserProfileOther } from "../../../redux/slices/userSlice";
import { post_create_chat } from "../../../api/chats"
import { setChatIdRoom, setUserTime } from '../../../redux/slices/webSocketSlice';

const UserProfileInfo = ({ isAdmin, userData, interests, fromAdmin }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userDataState = useSelector(selectUserProfile)
    const isAdminState = useSelector(selectIsAdminUser)
    const [userInfo, setUserInfo] = useState(userData)
    const [userInterests, setUserInterests] = useState(interests)


    const goToChangeProfile = () => {
        navigate("/user/user-change")
    }

    const goBack = () => {
        navigate("/")
    }

    const hostesInfoRequest = async (id) => {
        try {
            const { data } = await user_other(id)
            dispatch(setUserProfileOther(data.data))
            setUserInfo(data.data)
            setUserInterests(JSON.parse(data.data.interests))            
        } catch (error) {
            console.error("user profile", error)
        }
    }

    const userInfoFill = async () => {
        if (userDataState.user_id) {
            setUserInfo(userDataState)
            setUserInterests(JSON.parse(userDataState.interests))            
        } else {
            hostesInfoRequest(localStorage.getItem("hostesId"))
        }

    }

    const userInfoHandler = async () => {
        try {
            await user_verify()
            navigate("/user/user-info")    
        } catch (error) {
          console.error("error userInfo change", error)
        }
    };

    const createChat = async () => {
        const userId = Number(localStorage.getItem("hostesId"))
        try {
          const { data } = await post_create_chat({userId})
          dispatch(setChatIdRoom(data.data.id))
          dispatch(setUserTime(data.data.createdAt))
          navigate("/chat")
        } catch (error) {
          
        }
      }

    useEffect(() => {
        (!fromAdmin && isAdminState) && userInfoFill()
    }, [isAdmin, isAdminState])

    return (
        <div className={s.container}>
            <div>
                <div className={s.header}>
                    {(isAdmin || isAdminState) ? (
                        <div onClick={goBack} className={s.headerBack}>
                            <MyIcon 
                                image={"back"}
                                width={"20px"}
                                height={"20px"}
                            />
                            <div style={{ fontWeight: '600' }} className={s.headerBackText}>
                                {fromAdmin ? 'Профиль администратора' : 'Профиль хостес'}
                            </div>
                        </div>
                    ) : (
                        <div className={s.headerText}>Профиль</div>
                    )}

                    {(fromAdmin || !isAdminState) && (
                        <div onClick={goToChangeProfile} className={isAdmin ? s.headerChangeAdmin : s.headerChange}>
                            <MyIcon 
                                image={"change"}
                                width={"16.5px"}
                                height={"16.5px"}
                            />
                            <div style={{ fontWeight: '600'}} className={s.headerChangeText}>
                                Редактировать
                            </div>
                        </div>                        
                    )}

                </div>       

                <div className={s.avatarRate}>
                    <div className={s.avatar}>
                        {userInfo.avatar && 
                            <img 
                                style={{objectFit: 'cover'}} 
                                src={userInfo.avatar}
                                alt="avatar"
                            />
                        }
                    </div>
                    <div className={s.userNik}>
                        <div className={s.nikename}>{userInfo.username}</div>
                        <div className={s.line}></div>
                        <MyIcon image={"telegramIcon"} width={"20px"} height={"20px"} />
                        <div className={s.social}>{userInfo.telegram}</div>
                    </div>
                    <RatesUser userInfoRates={userInfo.rating} />
                </div>

                {
                    window.innerWidth < 820 && 
                        <div className={s.lineMobile}></div>
                }

                <div className={s.about}>
                    <div className={s.aboutText}>О себе</div>
                    <div className={s.aboutDescription}>{userInfo.about_me}</div>
                </div>

                {
                    window.innerWidth < 820 && 
                        <div className={s.lineMobile}></div>
                }

                <div className={s.likes}>
                    <div className={s.likesText}>Интересы</div>
                    <div className={s.likesBlock}>
                        <div className={s.containerLikes}>
                        {Object.values(userInterests).map((like, index) => (
                            like !== "" &&
                                <div key={index} className={s.like}>
                                    {like}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {
                    window.innerWidth < 820 && 
                        <div className={s.lineMobile}></div>
                }

            </div>

            {
                userInfo.verified === 0 && (
                    <div className={s.footer}>
                        <div className={s.footerAnswer}>
                            <MyIcon 
                                image={"error"}
                                width={"18px"}
                                height={"18px"}
                            />
                            <div className={s.error}>Ваша анкета отклонена</div>
                        </div>
                        <div className={s.footerText}>(ваша анкета отклонена, вы можете подать ее повторно)</div>
                        <div onClick={() => userInfoHandler()}  className={s.footerButton}>
                            <MyButton text={"Запросить проверку"}/>
                        </div>

                    </div>
                )
            }

            {
                userInfo.verified === 1 && (
                    <div className={s.footer}>
                        <div className={s.footerAnswer}>
                            <MyIcon 
                                image={"success"}
                                width={"18px"}
                                height={"18px"}
                            />
                            <div className={s.good}>Ваша анкета принята</div>
                        </div>
                        <div className={s.footerText}>(поздравляем, теперь вы часть команды, можете приступать к работе)</div>
                    </div>
                )
            }

            {
                userInfo.verified === 2 && (
                    <div className={s.footer}>
                        <div className={s.footerAnswer}>
                            <MyIcon 
                                image={"waiting"}
                                width={"18px"}
                                height={"18px"}
                            />
                            <div className={s.waiting}>Ваша анкета на проверке</div>
                        </div>
                        <div className={s.footerText}>(дождитесь пока администратор проверит вашу анкету)</div>
                    </div>
                )
            }

            {(!fromAdmin && isAdminState) && (
                <div className={s.buttons}>
                    <MyButton 
                        width={"31%"} 
                        text={"Удалить хостес"} 
                        border={"1px solid var(--dark-theme-colors-second-color, #D12B7A)"} 
                        background={"transparent"} 
                        color={"#D12B7A"} 
                    />
                    
                    <MyButton 
                        width={"31%"} 
                        text={"Выписать штраф"} 
                        border={"1px solid var(--dark-theme-text-second-text, #62708E)"} 
                        background={"transparent"}
                        disabled
                        color={"#62708E"}  
                    />
                    <MyButton 
                        onHandelSubmit={createChat}
                        width={"31%"} 
                        text={"Отправить сообщение"} 
                        border={"1px solid var(--dark-theme-text-second-text, #62708E)"} 
                        background={"transparent"} 
                        color={"#62708E"} 
                    />
                </div>
            )}
        </div>
    )
}

export default UserProfileInfo;