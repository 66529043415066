import React, {useEffect, useRef, useState} from 'react';
import styles from './StatsTable.module.css'
import {TableHeader} from "./tableHeader/TableHeader";
import {getGamersStatistics, getSettingsAdminStatistics} from "../../../api/getHostesList";
import {useDispatch, useSelector} from 'react-redux';
import {
    selectActiveBtn,
    selectHostesInfoByIdSearch,
    selectSearchItem,
    selectUserInfoByIdSearch
} from '../../../redux/slices/adminSlice';
import StatsItem from './statsItem/StatsItem';
import {getUniqueListId, getUniqueListUserId} from '../../../settings/uniqList';
import { selectAdminStatistics, selectGamersStatistics, setGamersStatistics, setStatistics } from '../../../redux/slices/adminStatSlice';

const StatsTable = () => {
    const containerRef = useRef(null);
    const activeBtnState = useSelector(selectActiveBtn)
    const userInfoByIdSearchState = useSelector(selectUserInfoByIdSearch)
    const hostesInfoByIdSearchState = useSelector(selectHostesInfoByIdSearch)
    const searchItemState = useSelector(selectSearchItem)
    const statistics = useSelector(selectAdminStatistics)
    const gamersStatistics = useSelector(selectGamersStatistics)
    const [page, setPage] = useState(1);
    const [pageLast, setPageLast] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch()

    const handleScroll = () => {
        const container = containerRef.current;
        let timer = 0;

                if (page <= pageLast) {
            if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
                timer = setTimeout(() => {
                    activeBtnState === 1 ? getGamersListHandler() : getHostesListHandler()
                }, 300);
            }
        }
        return () => {
            clearTimeout(timer);
        };
    };

    const getHostesListHandler = async (pageNum) => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const {data} = await getSettingsAdminStatistics({page: pageNum ? pageNum : page});
            const newTransactions = data.data;
            dispatch(setStatistics(newTransactions))
            setPage((prevPage) => prevPage + 1);
            setPageLast(data.pagination.lastPage);
        } catch (error) {
            console.error("getMainStatistics error", error)
        } finally {
            setIsLoading(false);
        }
    }

    const getGamersListHandler = async (pageNum, search) => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const {data} = await getGamersStatistics(pageNum ? pageNum : page, search);
            const newTransactions = data.data;
            dispatch(setGamersStatistics(newTransactions))
            setPage((prevPage) => prevPage + 1);
            setPageLast(data.pagination.lastPage);
        } catch (error) {
            console.error("getMainStatistics error", error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        activeBtnState === 0 ? getHostesListHandler(1) : getGamersListHandler(1)
    }, [activeBtnState])

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page, pageLast]);

    useEffect(() => {
        if (activeBtnState === 0) {
            userInfoByIdSearchState && userInfoByIdSearchState.user_id 
            ? dispatch(selectAdminStatistics([userInfoByIdSearchState])) 
            : getHostesListHandler(1)
        } else if (activeBtnState === 1 && searchItemState) {
            setGamersStatistics([])
            getGamersListHandler(1, searchItemState)
        }
    }, [userInfoByIdSearchState, activeBtnState, searchItemState])

    useEffect(() => {
        activeBtnState === 1 && searchItemState === "" && getGamersListHandler(1)
    }, [searchItemState])

    return (
        <div className={styles.table}>
            <TableHeader activeBtnState={activeBtnState}/>
            <div ref={containerRef}
                // style={{height:
                //     window.innerWidth > 1840 ? window.innerHeight / 1.28
                //     : window.innerWidth > 1550 ? window.innerHeight / 1.3
                //     : window.innerWidth > 1450 ? window.innerHeight / 1.43
                //     : window.innerWidth > 850 ? window.innerHeight / 1.59
                //     : window.innerHeight / 1.59
                // }}
                 className={styles.mainTable}>
                <StatsItem
                    items={activeBtnState === 0 ? getUniqueListUserId(statistics) : getUniqueListId(gamersStatistics)}
                    activeBtnState={activeBtnState}/>
            </div>
        </div>

    );
};

export default StatsTable;