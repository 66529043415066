import React, { useMemo, useState } from "react";
import s from "./ScheduleItem.module.css";
import { cancleSchedule, postSchedule } from "../../../api/schedule";
import { useToast } from "rc-toastr";
import ModalUserProfileInfo from "../../modals/modalProfile/ModalUserProfileInfo";
import { post_create_chat } from "../../../api/chats";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setChatIdRoom,
  setUserNikName,
  setUserTime,
} from "../../../redux/slices/webSocketSlice";
import { formatTimeToHHMMSS } from "../../../settings/helpers";
import MyIcon from "../../UI/myIcon/MyIcon";
import { setFromSchedule, setFromScheduleUserId } from "../../../redux/slices/userSlice";

const ScheduleItem = ({
  items,
  userDataMyId,
  userDataRating,
  getScheduleData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedUser, setSelectedUser] = useState({});

  const openModal = (id) => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleTimeClick = (time, columnIndex) => {
    if (time.status !== 2) {
      if (selectedTime && selectedTime.time && selectedTime.time.id) {
        selectedTime.time.id === time.id
          ? setSelectedTime({})
          : setSelectedTime({ time, columnIndex });
      } else {
        setSelectedTime({ time, columnIndex });
        setSelectedUser({});
      }
    } else {
      setSelectedTime({});
      setSelectedUser({ time, columnIndex });
    }
  };

  const handleCancelClick = async () => {
    if (isLoading) {
      return;
    }
    const request = {
      timeId: selectedTime.time.id,
    };
    try {
      setIsLoading(true);
      await cancleSchedule(request);
      toast.success("Успешно отменена бронь");
      getScheduleData();
      setSelectedTime({});
    } catch (error) {
      console.error("handleCancelClick error", error.response.data.message);
      error.response.data.message ===
      "Access denied. Your account is not verified."
        ? toast.error("Аккаунт на проверке")
        : error.response.data.message ===
          "You can't cancel because it's not your time"
        ? toast.error("Не Ваше время!")
        : toast.error("Нельзя отменить бронь!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateClick = async () => {
    if (isLoading) {
      return;
    }
    const request = {
      timeId: selectedTime.time.id,
    };
    try {
      setIsLoading(true);
      await postSchedule(request);
      toast.success("Успешно добавлена бронь");
      getScheduleData();
      setSelectedTime({});
    } catch (error) {
      console.error("handleUpdateClick error", error.response.data.message);
      error.response.data.message ===
      "Access denied. Your account is not verified."
        ? toast.error("Аккаунт на проверке")
        : toast.error("Ошибка при бронировании");
    } finally {
      setIsLoading(false);
    }
  };

  const createMessage = async (userId, userName, userData) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await post_create_chat({ userId });
      dispatch(setChatIdRoom(data.data.id));
      dispatch(setUserNikName(userName));
      dispatch(setUserTime(data.data.createdAt));
      dispatch(setFromSchedule(data.data.id))
      dispatch(setFromScheduleUserId(userData))
      navigate("/chat");
    } catch (error) {
      console.error("createMessage error", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={`${s.container} ${s.gridContainer}`}>
      {/* {items && items.length !== 0 && items.map((item) => ( */}
      {items &&
        items.length !== 0 &&
        Object.entries(items).map(
          ([dayOfWeek, timeList], index) =>
            index < 14 && (
              <div className={s.item} key={dayOfWeek}>
                <div className={s.titleBlock}>
                  <div>{dayOfWeek}</div>
                  {/* <div>{formatDateWithOutYear(item.date)}</div>                       */}
                  {/* <div>{formatDateWithOutYear(item.date)}</div>                       */}
                </div>

                {window.innerWidth < 768 ? (
                  <div className={s.mobileTimeBlock}>
                    <div className={s.mobileTime}>
                      {timeList?.map(
                        (time, index) =>
                          (index === 0 || index === 1) && (
                            <div key={index} className={s.flexForLine}>
                              {/* <div onClick={() => handleTimeClick(time, item.date)} className={`${s.itemTime} ${selectedTime === time ? s.selectedTime : ''}`}> */}
                              <div
                                onClick={() => handleTimeClick(time, dayOfWeek)}
                                className={`${s.itemTime} ${
                                  selectedTime === time ? s.selectedTime : ""
                                }`}
                              >
                                <div className={`${s.timeGrid} ${s.flex}`}>
                                  {selectedTime?.time?.id === time.id ? (
                                    <>
                                      <div className={s.selectedTime}>
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.avaSelected}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={s.emptyRoundSelected}
                                        ></div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          time.user && time.user?.avatar
                                            ? s.timeOnOut
                                            : s.timeOnOutWhite
                                        }
                                      >
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.ava}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className={s.emptyRound}></div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {index === 0 && (
                                <div className={s.lineMini}></div>
                              )}
                            </div>
                          )
                      )}
                    </div>

                    <div className={s.line}></div>

                    <div className={s.mobileTime}>
                      {timeList?.map(
                        (time, index) =>
                          (index === 2 || index === 3) && (
                            <div key={index} className={s.flexForLine}>
                              <div
                                onClick={() => handleTimeClick(time, dayOfWeek)}
                                className={`${s.itemTime} ${
                                  selectedTime === time ? s.selectedTime : ""
                                }`}
                              >
                                <div className={`${s.timeGrid} ${s.flex}`}>
                                  {selectedTime?.time?.id === time.id ? (
                                    <>
                                      <div className={s.selectedTime}>
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.avaSelected}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={s.emptyRoundSelected}
                                        ></div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          time.user && time.user?.avatar
                                            ? s.timeOnOut
                                            : s.timeOnOutWhite
                                        }
                                      >
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.ava}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className={s.emptyRound}></div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {index === 2 && (
                                <div className={s.lineMini}></div>
                              )}
                            </div>
                          )
                      )}
                    </div>

                    <div className={s.line}></div>

                    <div className={s.mobileTime}>
                      {timeList?.map(
                        (time, index) =>
                          (index === 4 || index === 5) && (
                            <div key={index} className={s.flexForLine}>
                              <div
                                onClick={() => handleTimeClick(time, dayOfWeek)}
                                className={`${s.itemTime} ${
                                  selectedTime === time ? s.selectedTime : ""
                                }`}
                              >
                                <div className={`${s.timeGrid} ${s.flex}`}>
                                  {selectedTime?.time?.id === time.id ? (
                                    <>
                                      <div className={s.selectedTime}>
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.avaSelected}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={s.emptyRoundSelected}
                                        ></div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={
                                          time.user && time.user?.avatar
                                            ? s.timeOnOut
                                            : s.timeOnOutWhite
                                        }
                                      >
                                        {formatTimeToHHMMSS(time.timeStart)} -{" "}
                                        {formatTimeToHHMMSS(time.timeEnd)}
                                      </div>

                                      {time.user ? (
                                        <div
                                          onClick={() => openModal(time)}
                                          className={s.ava}
                                        >
                                          {time.user?.avatar ? (
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={time.user?.avatar}
                                              alt="ava"
                                            />
                                          ) : (
                                            <MyIcon
                                              image={"avatar"}
                                              width={"10.5px"}
                                              height={"10.5px"}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className={s.emptyRound}></div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {index === 4 && (
                                <div className={s.lineMini}></div>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ) : (
                  timeList?.map((time, index) => (
                    <div className={s.itemTime} key={index}>
                      <div
                        onClick={() => handleTimeClick(time, dayOfWeek)}
                        className={`${s.timeFlex}`}
                      >
                        {selectedTime?.time?.id === time.id ? (
                          <>
                            <div className={s.selectedTime}>
                              {formatTimeToHHMMSS(time.timeStart)} -{" "}
                              {formatTimeToHHMMSS(time.timeEnd)}
                            </div>

                            {time.user ? (
                              <div
                                onClick={() => openModal(time)}
                                className={s.avaSelected}
                              >
                                {time.user?.avatar ? (
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={time.user?.avatar}
                                    alt="ava"
                                  />
                                ) : (
                                  <MyIcon
                                    image={"avatar"}
                                    width={"10.5px"}
                                    height={"10.5px"}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className={s.emptyRoundSelected}></div>
                            )}
                          </>
                        ) : (
                          <>
                            {/* <div className={(time.status === 2 || time.user) && time.user?.avatar ? s.timeOnOut : s.timeOnOutWhite}> */}
                            <div
                              className={
                                time.status === 2 || time.user
                                  ? s.timeOnOut
                                  : s.timeOnOutWhite
                              }
                            >
                              {formatTimeToHHMMSS(time.timeStart)} -{" "}
                              {formatTimeToHHMMSS(time.timeEnd)}
                            </div>

                            {time.user ? (
                              <div
                                onClick={() => openModal(time)}
                                className={s.ava}
                              >
                                {time.user?.avatar ? (
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={time.user?.avatar}
                                    alt="ava"
                                  />
                                ) : (
                                  <MyIcon
                                    image={"avatar"}
                                    width={"23.5px"}
                                    height={"23.5px"}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className={s.emptyRound}></div>
                            )}
                          </>
                        )}
                      </div>
                      <div className={s.lineMini}></div>
                      {index < timeList.length - 1 && (
                        <div className={s.line}></div>
                      )}
                    </div>
                  ))
                )}

                {selectedTime &&
                  selectedTime.time?.user?.id &&
                  selectedTime.columnIndex === dayOfWeek && (
                    <button className={s.button} onClick={handleCancelClick}>
                      Отменить бронь
                    </button>
                  )}

                {selectedTime &&
                  !selectedTime.time?.user?.id &&
                  selectedTime.columnIndex === dayOfWeek && (
                    <button className={s.button} onClick={handleUpdateClick}>
                      Подать бронь
                    </button>
                  )}

                {(selectedTime.columnIndex === dayOfWeek ||
                  selectedUser.columnIndex === dayOfWeek) &&
                  isOpen && (
                    <ModalUserProfileInfo
                      requestName={createMessage}
                      userData={
                        selectedTime.time?.user?.id
                          ? selectedTime.time?.user?.id
                          : selectedUser.time?.user?.id
                      }
                      isOpen={isOpen}
                      onClose={closeModal}
                      userDataMyId={userDataMyId}
                      userDataRating={userDataRating}
                    />
                  )}
              </div>
            )
        )}
    </div>
  );
};

export default ScheduleItem;
