import React, { useState, useEffect, useRef } from "react";
import { formatDateTime } from "../../../settings/formatDate";

import s from "./PaginationHistoryWithDrawal.module.css"
import MyIcon from "../../UI/myIcon/MyIcon";
import { getBalancyWithDrawalsHistory } from "../../../api/balance";

const PaginationHistoryWithDrawal = ({ getWithDrawelHistoryData, setGetWithDrawelHistoryData }) => {
    const containerRef = useRef(null);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [pageLast, setPageLast] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (getWithDrawelHistoryData) {
          setPage(1);
          setTransactions([])
          loadTransactions()
        }
      }, [getWithDrawelHistoryData])

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
        container.addEventListener('scroll', handleScroll);
    
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
        }
    }, [page, pageLast, transactions]);

    const handleScroll = () => {
        const container = containerRef.current;
        let timer = 0;

        if (page <= pageLast) {
        if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
            timer = setTimeout(() => {
            loadTransactions()
            }, 300);
        } 
        }

        return () => {
            clearTimeout(timer);
        };
    };

    const loadTransactions = async () => {
        try {
            setIsLoading(true);
            const { data } = await getBalancyWithDrawalsHistory(getWithDrawelHistoryData ? 1 : page);
            setIsLoading(false);
      
            setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
            setPage((prevPage) => prevPage + 1);
            setPageLast(data.pagination.lastPage);
            setGetWithDrawelHistoryData(false)
        } catch (error) {
            console.error("getBalancyWithDrawalsHistory error", error)
        }
    };

    useEffect(() => {
        loadTransactions()
    }, [])
 
  return (
    <div ref={containerRef} style={{height: window.innerWidth >= 1700 ?  window.innerHeight/3 + 5 : window.innerHeight/3}} className={s.paginationHistoryWithDrawal}>
        {transactions.map((item, index) => (
            <div
                className={`${s.container} ${index % 2 === 0 ? s.evenBackground : s.oddBackground}`}
                key={index}
            >
                <div 
                    className={`${
                        item.status === "waiting" ? s.colorStatusGrey 
                        : item.status === "error" || item.status === "cancel" ? s.colorStatusRed 
                        : item.status === "success" || item.status === "approve" ? s.colorStatusYellow 
                        : ""}`}>
                        {item.status ==="waiting" && "Ожидается"}
                        {item.status ==="error" && "Ошибка"}
                        {item.status === "cancel" && "Отменено"}
                        {(item.status ==="success" || item.status === "approve") && "Успешно"}
                </div>
                <div className={s.count}>
                  <p
                    style={{
                      fontFamily: "system-ui",
                      color: "#FADC77",
                    }}
                  >
                    ₽
                  </p>
                    <div>{item.dollars.toFixed(2)}</div>
                </div>
                <div>{item.cardNumber}</div>
                <div>{formatDateTime(item.createdAt)}</div>
            </div>
        ))}
    </div>
  );
};

export default PaginationHistoryWithDrawal;
