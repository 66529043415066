import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import { injectReduxStore } from './utils/injectReduxStore';
import { ToastProvider } from 'rc-toastr'
import { BrowserRouter as Router } from 'react-router-dom';

import "rc-toastr/dist/index.css"

import './index.css';
import App from './App';
import { WebSocketProvider } from './socket/webSocketConnection';

injectReduxStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Provider store={store}>
      <ToastProvider config={{
        position: "bottom-right",
        // autoClose: 1000,
        // duration: 150,
        showIcon: false
      }} >
            <WebSocketProvider>
                <App />                   
            </WebSocketProvider>          
      </ToastProvider>
    </Provider>    
  </Router>

);

