import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userProfile: {
      about_me: "",
      avatar: null,
      confirmedAt: "",
      email: "",
      familiarWithRules: 0,
      gamerData: null,
      gamerId: null,
      hasCamera: 0,
      hasMicrophone: 1,
      id: null,
      interests: "",
      lastOnline: null,
      online: 0,
      rating: 0,
      referralCode: null,
      roles: [],
      telegram: "",
      username: "",
      verified: null,
    },
    userProfileOther: {
      about_me: "",
      avatar: null,
      confirmedAt: "",
      email: "",
      familiarWithRules: 0,
      gamerData: null,
      gamerId: null,
      hasCamera: 0,
      hasMicrophone: 1,
      id: null,
      interests: "",
      lastOnline: null,
      online: 0,
      rating: 0,
      referralCode: null,
      roles: [],
      telegram: "",
      username: "",
      verified: null,
    },
    callerInfo: {
      callerId: null,
      receiverId: null,
    },
    isUpdateCountChats: false,
    isAdminUser: false,
    isStream: false,
    isMyStream: false,
    isPrivateVideo: false,
    timeZone: "",
    fromSchedule: null,
    fromScheduleUserId: null,
    fromPublicChat: null,
    userNikNameFromPublic: null,
    userReferalId: null
  },
  reducers: {
    setUserProfile: (state, action) => {
        state.userProfile.about_me = action.payload.about_me;
        state.userProfile.avatar = action.payload.avatar;
        state.userProfile.confirmedAt = action.payload.confirmedAt;
        state.userProfile.email = action.payload.email;
        state.userProfile.familiarWithRules = action.payload.familiarWithRules;
        state.userProfile.gamerData = action.payload.gamerData;
        state.userProfile.gamerId = action.payload.gamerId;
        state.userProfile.hasCamera = action.payload.hasCamera;
        state.userProfile.hasMicrophone = action.payload.hasMicrophone;
        state.userProfile.id = action.payload.id;
        state.userProfile.interests = action.payload.interests;
        state.userProfile.lastOnline = action.payload.lastOnline;
        state.userProfile.rating = action.payload.rating;
        state.userProfile.referralCode = action.payload.referralCode;
        state.userProfile.roles = action.payload.roles;
        state.userProfile.telegram = action.payload.telegram;
        state.userProfile.username = action.payload.username;
        state.userProfile.verified = action.payload.verified;
        state.userProfile.online = action.payload.online;
    },
    setUserProfileOther: (state, action) => {
      state.userProfileOther.about_me = action.payload.about_me;
      state.userProfileOther.avatar = action.payload.avatar;
      state.userProfileOther.confirmedAt = action.payload.confirmedAt;
      state.userProfileOther.email = action.payload.email;
      state.userProfileOther.familiarWithRules = action.payload.familiarWithRules;
      state.userProfileOther.gamerData = action.payload.gamerData;
      state.userProfileOther.gamerId = action.payload.gamerId;
      state.userProfileOther.hasCamera = action.payload.hasCamera;
      state.userProfileOther.hasMicrophone = action.payload.hasMicrophone;
      state.userProfileOther.id = action.payload.id;
      state.userProfileOther.interests = action.payload.interests;
      state.userProfileOther.lastOnline = action.payload.lastOnline;
      state.userProfileOther.rating = action.payload.rating;
      state.userProfileOther.referralCode = action.payload.referralCode;
      state.userProfileOther.roles = action.payload.roles;
      state.userProfileOther.telegram = action.payload.telegram;
      state.userProfileOther.username = action.payload.username;
      state.userProfileOther.verified = action.payload.verified;
      state.userProfileOther.online = action.payload.online;
  },
    setCallerInfo: (state, action) => {
      state.callerInfo.callerId = action.payload.callerId
      state.callerInfo.receiverId = action.payload.receiverId
    },
    setFromSchedule: (state, action) => {
      state.fromSchedule = action.payload
    },
    setFromPublicChat: (state, action) => {
      state.fromPublicChat = action.payload
    },
    setFromScheduleUserId: (state, action) => {
      state.fromScheduleUserId = action.payload
    },
    setUpdateCountChats: (state, action) => {
      state.isUpdateCountChats = action.payload
    },
    setIsAdminUser: (state, action) => {
      state.isAdminUser = action.payload
    },
    setUserNikNameFromPublic: (state, action) => {
      state.userNikNameFromPublic = action.payload
    },
    setIsStream: (state, action) => {
      state.isStream = action.payload
    },
    setIsMyStream: (state, action) => {
      state.isMyStream = action.payload
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload
    },
    setIsPrivateVideo: (state, action) => {
      state.isPrivateVideo = action.payload
    },
    setUserReferalId: (state, action) => {
      state.userReferalId = action.payload
    }
  }
})

export const selectUserId = (state) => state.user.userProfile.id;
export const selectUserReferalId = (state) => state.user.userReferalId;
export const selectFromSchedule = (state) => state.user.fromSchedule;
export const selectUserNikNameFromPublic = (state) => state.user.userNikNameFromPublic;
export const selectFromPublicChat = (state) => state.user.fromPublicChat;
export const selectFromScheduleUserId = (state) => state.user.fromScheduleUserId;
export const selectUserProfile = (state) => state.user.userProfile;
export const selectUserProfileOther = (state) => state.user.userProfileOther;
export const selectIsUpdateCountChats = (state) => state.user.isUpdateCountChats;
export const selectIsAdminUser = (state) => state.user.isAdminUser;
export const selectIsStream = (state) => state.user.isStream;
export const selectIsMyStream = (state) => state.user.isMyStream;
export const selectIsPrivateVideo = (state) => state.user.isPrivateVideo;
export const selectCallerInfo = (state) => state.user.callerInfo;
export const selectTimeZone = (state) => state.user.timeZone;

export const {
  setUserProfile,
  setUserReferalId,
  setUserNikNameFromPublic,
  setFromPublicChat,
  setFromScheduleUserId,
  setUpdateCountChats,
  setIsAdminUser,
  setIsStream,
  setIsMyStream,
  setIsPrivateVideo,
  setCallerInfo,
  setTimeZone,
  setUserProfileOther,
  setFromSchedule,
} = userSlice.actions

export default userSlice.reducer