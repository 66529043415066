import React, {useEffect, useState} from 'react'
import s from "./BalanceTab.module.css"
import {get_balancy_user} from '../../api/balance'
import Convert from './convert/Convert'
import HistoryExchange from './historyExchange/HistoryExchange'
import Change from './change/Change'
import HistoryWithDrawal from './historyWithDrawal/HistoryWithDrawal'
import History from "./history/History"
import {TabsSwitcher} from "./history/tabsSwitcher/tabsSwitcher";

const BalanceTap = () => {
    const [userBalance, setUserBalance] = useState();
    const [getExchangeHistoryData, setGetExchangeHistoryData] = useState(false)
    const [getWithDrawelHistoryData, setGetWithDrawelHistoryData] = useState(false)
    const [step, setStep] = useState(0)
    const [isMobile, setIsMobile] = useState(window.innerWidth > 768)

    const getBalancyUser = async () => {
        try {
            const {data} = await get_balancy_user()
            setUserBalance(data.data)
        } catch (error) {
            console.error("getBalancyUser error", error)

        }
    }
    const handleResize = () => setIsMobile(window.innerWidth > 768)

    useEffect(() => {
        getBalancyUser()
    }, [])
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className={s.container}>
            {
                isMobile ? (
                    <>
                        <div className={s.history}><History/></div>
                        <div className={s.historyChange}>
                            <div className={s.historyChangeFirstBlock}>
                                <Convert setGetExchangeHistoryData={setGetExchangeHistoryData} userBalance={userBalance}
                                         setUserBalance={setUserBalance}/>
                            </div>
                            <div className={s.historyChangeSecondBlock}>
                                <HistoryExchange getExchangeHistoryData={getExchangeHistoryData}
                                                 setGetExchangeHistoryData={setGetExchangeHistoryData}/>
                            </div>
                        </div>
                        <div className={s.historyExit}>
                            <div className={s.historyExitFirstBlock}>
                                <Change setGetWithDrawelHistoryData={setGetWithDrawelHistoryData}/>
                            </div>
                            <div className={s.historyExitSecondBlock}>
                                <HistoryWithDrawal getWithDrawelHistoryData={getWithDrawelHistoryData}
                                                   setGetWithDrawelHistoryData={setGetWithDrawelHistoryData}/>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <TabsSwitcher step={step} setStep={setStep}/>
                        {step === 0 && <div className={s.historyChange}>
                            <div className={s.historyChangeFirstBlock}>
                                <Convert setGetExchangeHistoryData={setGetExchangeHistoryData} userBalance={userBalance}
                                         setUserBalance={setUserBalance}/>
                            </div>
                            <div className={s.historyChangeSecondBlock}>
                                <HistoryExchange getExchangeHistoryData={getExchangeHistoryData}
                                                 setGetExchangeHistoryData={setGetExchangeHistoryData}/>
                            </div>
                        </div>}
                        {step === 1 && <div className={s.history}><History/></div>}

                        {step === 2 && <div className={s.historyExit}>
                            <div className={s.historyExitFirstBlock}>
                                <Change setGetWithDrawelHistoryData={setGetWithDrawelHistoryData}/>
                            </div>
                            <div className={s.historyExitSecondBlock}>
                                <HistoryWithDrawal getWithDrawelHistoryData={getWithDrawelHistoryData}
                                                   setGetWithDrawelHistoryData={setGetWithDrawelHistoryData}/>
                            </div>
                        </div>}
                    </div>
                )
            }
        </div>
    )
}

export default BalanceTap