import React, { useEffect, useRef, useState } from 'react';
import s from './ChatPrivate.module.css';
import { formatTime } from '../../../settings/formatDate';
import MyIcon from '../../UI/myIcon/MyIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFromPublicChat,
  selectFromSchedule,
  selectFromScheduleUserId,
  selectUserReferalId,
  setUpdateCountChats,
  setUserReferalId,
} from '../../../redux/slices/userSlice';
import {
  selectPublicChatId,
  setChatIdRoom,
  setMessagesReadedCount,
} from '../../../redux/slices/webSocketSlice';
import { useWebConnectionSocket } from '../../../socket/webSocketConnection';
import {
  createUniqueArray,
  groupMessagesByDay,
  isLink,
} from '../../../settings/helpers';
import PublicVideo from '../../publicVideo/PublicVideo';
import { user_friend_check } from '../../../api/chats';
import { MsgItem } from './msgItem/MsgItem';
import { SubMenu } from '../../subMenu/SubMenu';
import { post_create_team_complaints } from '../../../api/referral';
import ModalInfo from '../../modals/modalInfo/ModalInfo';

const ChatPrivate = ({
  setPublicChatItems,
  setLastMessage,
  getMessageInfo,
  setLastId,
  setPrivateChat,
  publicChatItems,
  privateChat,
  chatId,
  chatsItem,
  userName,
  pageLast,
  checkRequest,
  publicChat,
  page,
  publicChatSelected,
  userId,
  setPrivateChatRoute,
  setCheckMessageStatus,
  setCheckMessagesStatus,
  setPublicChatSelected,
  setChatId,
  getChatCount,
  setReciveScrol,
  upScroll,
  setUpScroll,
  reciveScrol,
  setUserName
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const selectPublicChatIdState = useSelector(selectPublicChatId);
  const selectFromScheduleState = useSelector(selectFromSchedule);
  const selectFromScheduleIdState = useSelector(selectFromScheduleUserId);
  const selectFromPublicChatState = useSelector(selectFromPublicChat);
  const selectUserReferalIdState = useSelector(selectUserReferalId);
  const [messageInput, setMessageInput] = useState('');
  const [checkMessage, setCheckMessage] = useState(false);
  const [disable, setDisable] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const socketConnection = useWebConnectionSocket();
  const [characterCount, setCharacterCount] = useState(0);
  const [checkFriend, setCheckFriend] = useState(null);
  const [modalComplainingOpen, setModalComplainingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const MAX_CHARACTER_LIMIT = 160;

  useEffect(() => {
    (publicChatSelected || chatId) && setDisable(false);
  }, [publicChatSelected, chatId]);

  useEffect(() => {
    if (socketConnection && publicChatSelected) {
      socketConnection.on('receive_pub_message', (message) => {
        if (Number(publicChat.id) === Number(message.chat.id)) {
          setLastMessage(message);

          const res = {
            id: message.id,
            text: message.text,
            status: message.status,
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
            sender: message.sender,
          };
          setPublicChatItems((prev) => [...prev, res]);
        }
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      });
    }
  }, [publicChat, publicChatSelected, containerRef.current]);

  useEffect(() => {
    if (socketConnection && chatId && !publicChatSelected) {
      socketConnection.emit('join_room', chatId);
      socketConnection.on('receive_message', (message) => {
        if (Number(chatId) === Number(message.chat.id)) {
          message.sender.id !== userId &&
            socketConnection.emit('read_message', {
              messageId: message.id,
              chatId,
            });
          dispatch(setMessagesReadedCount(message));

          setLastMessage(message);

          const res = {
            id: message.id,
            text: message.text,
            status: message.status,
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
            sender: message.sender,
          };
          setPrivateChat((prev) => [...prev, res]);
          setLastId((prev) => prev + 1);
        }
      });

      socketConnection.on('message_readed', (message) => {
        setCheckMessageStatus(message);
      });

      socketConnection.on('messages_readed', (message) => {
        setCheckMessagesStatus(message);
      });
    }
    getFriends();
  }, [chatId, publicChatSelected, containerRef.current]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, pageLast, privateChat, publicChatItems, publicChatSelected]);

  useEffect(() => {
    if (reciveScrol && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (upScroll && containerRef.current) {
      containerRef.current.scrollTop = 443;
    }
  }, [reciveScrol, upScroll, privateChat, publicChatItems]);

  useEffect(() => {
    if (checkMessage && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [checkMessage]);

  useEffect(() => {
    if (messageInput !== '') {
      setCheckMessage(false);
      setUpScroll(false);
      setReciveScrol(false);
    }
  }, [messageInput]);

  useEffect(() => {
    if (window.innerHeight >= 1366) {
      containerRef.current.style.height = `${window.innerHeight * 0.76}px`;
    } else if (window.innerHeight >= 1180) {
      containerRef.current.style.height = `${window.innerHeight * 0.8}px`;
    } else if (window.innerHeight >= 1024) {
      containerRef.current.style.height = `${window.innerHeight * 0.77}px`;
    } else if (window.innerHeight >= 914) {
      containerRef.current.style.height = `${window.innerHeight * 0.73}px`;
    } else if (window.innerHeight >= 896) {
      containerRef.current.style.height = `${window.innerHeight * 0.73}px`;
    } else if (window.innerHeight >= 844) {
      containerRef.current.style.height = `${window.innerHeight * 0.71}px`;
    } else if (window.innerHeight >= 800) {
      containerRef.current.style.height = `${window.innerHeight * 0.74}px`;
    } else if (window.innerHeight >= 740) {
      containerRef.current.style.height = `${window.innerHeight * 0.67}px`;
    } else if (window.innerHeight >= 720) {
      containerRef.current.style.height = `${window.innerHeight * 0.66}px`;
    } else if (window.innerHeight >= 667) {
      containerRef.current.style.height = `${window.innerHeight * 0.63}px`;
    } else if (window.innerHeight >= 600) {
      containerRef.current.style.height = `${window.innerHeight * 0.56}px`;
    } else {
    }
  }, []);

  useEffect(() => {
    getFriends();
  }, [selectFromScheduleState, selectFromPublicChatState]);

  const openModalInfo = () => {
    setModalComplainingOpen(true);
  };

  const closeModalInfo = () => {
    setModalComplainingOpen(false);
  };

  const reportToUser = async (val) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await post_create_team_complaints({
        type: 'Пожаловаться',
        userId: chatsItem.filter(
          (chat) => Number(chat.chat_id) === Number(chatId),
        )[0]?.userId,
        text: val,
      });
    } catch (error) {
      console.error('reportToUser ', error);
    } finally {
      setIsLoading(false);
      setModalComplainingOpen(false);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast && !checkRequest) {
      if (!publicChatSelected && container && container.scrollTop === 0) {
        setCheckMessage(false);
        timer = setTimeout(() => {
          getMessageInfo(chatId, page);
          dispatch(setUpdateCountChats(true));
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          const id = setTimeout(() => {
            getChatCount(chatId);
          }, 1000);

          setTimeoutId(id);
        }, 300);
      }

      if (publicChatSelected && container && container.scrollTop === 0) {
        setCheckMessage(false);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timer = setTimeout(() => {
          getMessageInfo(selectPublicChatIdState, page, true);
        }, 300);

        setTimeoutId(timer);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    setCheckMessage(true);
    setReciveScrol(true);
    if (publicChatSelected) {
      if (
        publicChatSelected &&
        socketConnection &&
        messageInput.trim() !== ''
      ) {
        const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');
        socketConnection.emit('send_pub_message', {
          userId,
          chatId: publicChat.id,
          message: cleanedMessageInput,
        });
        setMessageInput('');
      }
    }
    if (!publicChatSelected && socketConnection && messageInput.trim() !== '') {
      const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');
      socketConnection.emit('send_message', {
        chatId,
        message: cleanedMessageInput,
      });
      setMessageInput('');
    }
    setCharacterCount(0);
  };

  const clickBack = () => {
    setChatId(null);
    setPrivateChatRoute(true);
    setPublicChatSelected(false);
    dispatch(setChatIdRoom(null));
  };

  const getFriends = async () => {
    try {
      if (selectFromScheduleState) {
        const { data } = await user_friend_check({
          userId: Number(selectFromScheduleIdState),
        });
        setCheckFriend(data.data.status);
      } else if (selectFromPublicChatState) {
        const { data } = await user_friend_check({
          userId: Number(selectFromPublicChatState),
        });
        setCheckFriend(data.data.status);
      } else if (selectUserReferalIdState) {
        const { data } = await user_friend_check({
          userId: Number(selectUserReferalIdState),
        });
        setCheckFriend(data.data.status);
        dispatch(setUserReferalId(null));
      } else {
        const takeUserIdOther =
          chatsItem &&
          chatsItem.filter((chat) => Number(chat.chat_id) === Number(chatId))[0]
            ?.userId;
        if (takeUserIdOther) {
          const { data } = await user_friend_check({ userId: takeUserIdOther });
          setCheckFriend(data.data.status);
        }
      }
    } catch (error) {
      console.error('error tafe friends', error);
    }
  };

  const groupedMessages = groupMessagesByDay(
    createUniqueArray(privateChat, 'id'),
  );
  const [subMenuVisible, setSubMnuVisible] = useState(false);
  const submenuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setSubMnuVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={s.slideIn}>
      {modalComplainingOpen && (
        <ModalInfo
          isOpen={openModalInfo}
          onClose={closeModalInfo}
          title={'Пожаловаться'}
          text={`Укажите причину жалобы на участницу ${userName}`}
          buttonText={'Пожаловаться'}
          handleSubmit={reportToUser}
          placeholder={'Опишите свою жалобу'}
        />
      )}
      {window.innerWidth > 820 ? (
        !publicChatSelected ? (
          <div className={s.header}>
            <div>
              <div>{userName}</div>
              {userName && (
                <div className={s.span}>
                  {checkFriend === 0 && 'Вы не друзья'}
                  {checkFriend === 1 && 'Вы друзья'}
                  {checkFriend === 2 && 'Ожидается подтверждение'}
                </div>
              )}
            </div>
            {chatId && (
              <div className={s.userId}>
                <div>
                  ID:{' '}
                  <span style={{ color: 'white' }}>
                    {
                      chatsItem.filter(
                        (chat) => Number(chat.chat_id) === Number(chatId),
                      )[0]?.userId
                    }
                  </span>
                </div>
                <div
                  style={{
                    position: 'relative',
                    width: '29px',
                    height: '29px',
                  }}
                >
                  <MyIcon
                    onClick={() => setSubMnuVisible(!subMenuVisible)}
                    image={'miniMenu'}
                    width={'29px'}
                    height={'29px'}
                  />
                  {subMenuVisible && (
                    <SubMenu
                      submenuRef={submenuRef}
                      setIsOpenMiniMenu={setSubMnuVisible}
                      userId={
                        chatsItem.filter(
                          (chat) => Number(chat.chat_id) === Number(chatId),
                        )[0]?.userId
                      }
                      userName={userName}
                      withOutProfile
                      setModalComplainingOpen={setModalComplainingOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={s.headerPublic}>Чат общего стрима</div>
        )
      ) : !publicChatSelected ? (
        <div className={s.headerMobile}>
          <div onClick={clickBack}>
            <MyIcon image={'back'} width={'19px'} height={'19px'} />
          </div>
          <div className={s.headerText}>
            <div>{userName}</div>
            {userName && (
              <div className={s.span}>
                {checkFriend === 0 && 'Вы не друзья'}
                {checkFriend === 1 && 'Вы друзья'}
                {checkFriend === 2 && 'Ожидается подтверждение'}
              </div>
            )}
          </div>
          <div style={{ position: 'relative', width: '29px', height: '29px' }}>
            <MyIcon
              onClick={() => setSubMnuVisible(!subMenuVisible)}
              image={'miniMenu'}
              width={'29px'}
              height={'29px'}
            />
            {subMenuVisible && (
              <SubMenu
                submenuRef={submenuRef}
                setIsOpenMiniMenu={setSubMnuVisible}
                userId={
                  chatsItem.filter(
                    (chat) => Number(chat.chat_id) === Number(chatId),
                  )[0]?.userId
                }
                userName={userName}
                withOutProfile
                setModalComplainingOpen={setModalComplainingOpen}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={s.headerMobile}>
          <div onClick={clickBack}>
            <MyIcon image={'back'} width={'19px'} height={'19px'} />
          </div>
          <div className={s.headerPublic}>Чат общего стрима</div>
          <div style={{ position: 'relative' }}></div>
        </div>
      )}

      {publicChatSelected ? (
        <div ref={containerRef} className={`${s.container}`}>
          {publicChatItems &&
            createUniqueArray(publicChatItems, 'id').map((item, index) => (
              <MsgItem
                userName={item.sender.username}
                text={item.text}
                senderId={item.sender.id}
                key={index}
                isLastMsg={publicChatItems.length === index + 1}
                setPrivateChatRoute={setPrivateChatRoute}
                setPublicChatSelected={setPublicChatSelected}
                setChatId={setChatId}
                setUserName={setUserName}
                getMessageInfo={getMessageInfo}
              />
            ))}
          <div className={`${s.streamImg}`}>
            <PublicVideo />
          </div>
        </div>
      ) : (
        <div ref={containerRef} className={s.container}>
          {Object.keys(groupedMessages).map((dayOfWeek) => (
            <div className={s.wrapper} key={dayOfWeek}>
              <div className={s.dayOfWeek}>
                <div>{dayOfWeek}</div>
              </div>
              {privateChat &&
                groupMessagesByDay(createUniqueArray(privateChat, 'id'))[
                  dayOfWeek
                ].map((item, index) => (
                  <div className={`${s.message}`} key={index}>
                    <div
                      className={`${
                        userId === item.sender?.id
                          ? s.rightContainer
                          : s.leftContainer
                      }`}
                    >
                      <div
                        className={`${
                          userId === item.sender?.id ? s.right : s.left
                        }`}
                      >
                        <div className={s.adaptTextBlock}>
                          {isLink(item.text) ? (
                            <a href={item.text}>{item.text}</a>
                          ) : (
                            item.text
                          )}
                        </div>
                        <div
                          className={`${
                            userId === item.sender?.id
                              ? s.rightStatusData
                              : s.leftStatusData
                          }`}
                        >
                          <div className={s.icon}>
                            {item.status === 3 && (
                              <MyIcon
                                width={'20px'}
                                height={'20px'}
                                image="readMessage"
                              />
                            )}
                            {item.status === 2 && (
                              <MyIcon
                                width={'20px'}
                                height={'15px'}
                                image="sendMessage"
                              />
                            )}
                          </div>
                          <div>{formatTime(item.createdAt)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}

      <div className={s.footer}>
        {checkFriend === 1 || publicChatSelected ? (
          <div className={s.messageBlock}>
            <div className={s.messageInput}>
              <input
                type="text"
                style={{ marginRight: 10 }}
                placeholder="Введите сообщение"
                value={messageInput}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                    setMessageInput(e.target.value);
                    setCharacterCount(e.target.value.length);
                  }
                }}
                onKeyDown={handleKeyDown}
                disabled={disable}
              />
              <div className={s.send}>
                <div>{`${characterCount} / ${MAX_CHARACTER_LIMIT}`}</div>
                <div onClick={sendMessage}>
                  <MyIcon
                    image={'sendMessageToUser'}
                    width={'20px'}
                    height={'20px'}
                  />
                </div>
              </div>
            </div>
            <div className={s.line}></div>
          </div>
        ) : (
          checkFriend === 0 || checkFriend === 2 ? (
            <div className={s.notFriendText}>
              <p>
                Вы не можете отправить сообщение этому пользователю, посколько
                не являетесь друзьями
              </p>
            </div>
          ):'')
        }
      </div>
    </div>
  );
};

export default ChatPrivate;
