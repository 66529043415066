import { useLocation } from "react-router-dom";
import LoginUser from "../../components/loginUser/LoginUser";
import { registration_confirm } from "../../api/user";
import { useEffect } from "react";

const Login = () => {
  const { search } = useLocation();

  const getTokenFromSearch = (search) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get("token");
  };

  const token = getTokenFromSearch(search);

  useEffect(() => {
    search.includes("token") && checkRegister();
  }, [search]);

  const checkRegister = async () => {
    try {
      await registration_confirm({ token });
    } catch (error) {
      console.error("checkRegister", error);
    }
  };

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <LoginUser />
    </div>
  );
};

export default Login;
