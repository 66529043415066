import { Outlet } from 'react-router-dom'
import s from './LoginLayout.module.css';

const LoginLayout = () => {
  return (
      <div className={s.container}>
        <Outlet />
      </div>
  );
}

export default LoginLayout;
