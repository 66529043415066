import EventEmitter from 'events';
import { Device } from 'mediasoup-client';
import { Transport } from 'mediasoup-client/lib/types';

import { isMobile, socketPromise } from './helpers';

const MSCONFIG = {
  client: {
    videoProducer: {
      encodings: [
        { maxBitrate: 100000 },
        { maxBitrate: 300000 },
        { maxBitrate: 900000 },
      ],
      codecOptions: {
        videoGoogleStartBitrate: 1000,
      },
    },
  },
};

window.localStorage.setItem('debug', 'mediasoup-client:ERROR*');

class MediaClient {
  public device: Device | undefined;
  public transport: Transport | undefined;
  public events = new EventEmitter();
  public stream = new MediaStream();

  async createDevice() {
    const routerRtpCapabilities = await socketPromise(
      'getRouterRtpCapabilities',
    );

    this.device = new Device();
    await this.device.load({ routerRtpCapabilities });
  }

  async close(): Promise<void> {
    if (this.transport) this.transport.close();
    if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
  }
}

export class PublicConsumer extends MediaClient {
  async joinStream() {
    if (!this.device) return;

    const transportOptions = await socketPromise('createConsumerTransport');

    if (transportOptions.error) {
      console.error(transportOptions.error);
      return;
    }

    const transport = this.device.createRecvTransport(transportOptions);
    this.transport = transport;

    transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      socketPromise('connectConsumerTransport', {
        transportId: transport.id,
        dtlsParameters,
      })
        .then(callback)
        .catch(errback);
    });

    transport.on('connectionstatechange', async (state) => {
      switch (state) {
        case 'connecting':
          this.events.emit('connection', 'connecting');
          break;

        case 'connected':
          this.events.emit('connection', 'connected');
          this.events.emit('stream', this.stream);

          await socketPromise('resume');
          break;

        case 'failed':
          transport.close();
          this.events.emit('connection', 'failed');
          break;

        default:
          this.events.emit('connection', state);
          break;
      }
    });

    this.consume(transport);
  }

  private async consume(transport: any): Promise<void> {
    if (!this.device) return Promise.reject('No device');

    const produces: any[] = await socketPromise('consume', {
      rtpCapabilities: this.device.rtpCapabilities,
    });

    for (const { producerId, id, kind, rtpParameters } of produces) {
      const consumer = await transport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions: {},
      });

      this.stream.addTrack(consumer.track);
    }
  }
}

export class PublicProducer extends MediaClient {
  async startStream() {
    if (!this.device) return;

    const transportOptions = await socketPromise('createProducerTransport');

    if (transportOptions.error) {
      console.error(transportOptions.error);
      return;
    }

    const transport = this.device.createSendTransport(transportOptions);
    this.transport = transport;

    transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      socketPromise('connectProducerTransport', { dtlsParameters })
        .then((data) => {
          callback();
        })
        .catch(errback);
    });

    transport.on(
      'produce',
      async ({ kind, rtpParameters }, callback, errback) => {
        socketPromise('produce', {
          transportId: transport.id,
          kind,
          rtpParameters,
        })
          .then(({ id }) => callback({ id }))
          .catch(errback);
      },
    );

    transport.on('connectionstatechange', async (state) => {
      switch (state) {
        case 'connecting':
          this.events.emit('connection', 'connecting');
          break;

        case 'connected':
          this.events.emit('connection', 'connected');
          this.events.emit('stream', this.stream);

          break;

        case 'failed':
          transport.close();
          this.events.emit('connection', 'failed');
          break;
      }
    });

    this.stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    if (!isMobile)
      (
        await navigator.mediaDevices.getDisplayMedia({
          audio: true,
        })
      )
        .getAudioTracks()
        .forEach((track) => this.stream.addTrack(track));

    for (const track of this.stream.getAudioTracks())
      await transport.produce({ track });

    for (const track of this.stream.getVideoTracks())
      await transport.produce({
        track,
        // ...MSCONFIG.client.videoProducer,
      });
  }

  async toggleAudio(enabled = true) {
    const tracks = this.stream.getAudioTracks();
    if (tracks[0]) tracks[0].enabled = enabled;
  }
}
