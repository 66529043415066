import React, { useState, useEffect } from 'react';
import s from './ChatTab.module.css';
import Chats from './chats/Chats';
import ChatPrivate from './chatPrivate/ChatPrivate';
import {
  chat_filter_and_search,
  get_chat_id,
  get_chat_list,
  get_chat_public,
  get_count_unread_message_for_chat_id,
} from '../../api/chats';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../redux/slices/userSlice';
import { useLocation } from 'react-router-dom';
import {
  selectPublicChatId,
  setPublicChatId,
} from '../../redux/slices/webSocketSlice';
import { useWebConnectionSocket } from '../../socket/webSocketConnection';
import {
  selectFilters,
  selectUserSearch,
} from '../../redux/slices/searchSlice';
import { sortedData } from '../../settings/helpers';

const ChatTab = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = useSelector(selectUserId);
  const selectPublicChatIdState = useSelector(selectPublicChatId);
  const selectFiltersState = useSelector(selectFilters);
  const selectUserSearchState = useSelector(selectUserSearch);
  const [privateChat, setPrivateChat] = useState([]);
  const [publicChatItems, setPublicChatItems] = useState([]);
  const [chatsItem, setChatItems] = useState([]);
  const [publicChat, setPublicChat] = useState([]);
  const [userName, setUserName] = useState('');
  const [userMyId, setUserMyId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [lastMessage, setLastMessage] = useState('');
  const [lastId, setLastId] = useState(null);
  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);
  const [checkRequest, setCheckRequest] = useState(false);
  const [checkRequestChatItems, setCheckRequestChatItems] = useState(false);
  const [privateChatRoute, setPrivateChatRoute] = useState(true);
  const [checkMessageStatus, setCheckMessageStatus] = useState({});
  const [checkMessagesStatus, setCheckMessagesStatus] = useState({});
  const [countUnreadMessage, setCountUnreadMessage] = useState([]);
  const [chatTimeCreate, setChatTimeCreate] = useState('');
  const [publicChatSelected, setPublicChatSelected] = useState(false);
  const [reciveScrol, setReciveScrol] = useState(true);
  const [filterTake, setFilterTake] = useState(selectFiltersState);
  const [userSearchTake, setUserSearchTake] = useState(selectUserSearchState);
  const [upScroll, setUpScroll] = useState(false);
  const socketConnection = useWebConnectionSocket();

  const getMessageInfo = async (id, pageNum, isPublic) => {
    const param = {
      id,
      page: pageNum ? pageNum : '1',
    };

    try {
      pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
      pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
      const { data } = await get_chat_id(param);
      console.log(param,'---------');
      const sorted = sortedData(data.data);
      !isPublic &&
        setPrivateChat((prevPrivateChat) => [...sorted, ...prevPrivateChat]);
      isPublic &&
        setPublicChatItems((prevPublicChatItems) => [
          ...sorted,
          ...publicChatItems,
        ]);
      setLastId(data.data[0]?.id);
      setPageLast(data.pagination.lastPage);
      data.pagination.nextPage
        ? setPage(data.pagination.nextPage)
        : setPage(page + 1);
      setCheckRequest(false);
    } catch (error) {
      console.error('getMessageInfo err', error);
    }
  };

  const filterRequest = async (username, filter) => {
    const filterReq =
      filter && filter === 'allChats'
        ? ''
        : filter !== 'allChats'
        ? filter
        : '';

    const request = {
      filter: filterReq,
      username,
    };

    try {
      const { data } = await chat_filter_and_search(request);
      if (data && data.data) {
        setChatItems(data.data);
      }
    } catch (error) {
      console.error('filterRequest', error);
      setChatItems([]);
    }
  };

  const getChats = async () => {
    try {
      const { data } = await get_chat_list();
      setChatItems(data.data);
      const updatedCountUnreadMessage = data.data.map((chat) => ({
        chat_id: chat.chat_id,
        unreadMessageCount: chat.unreadMessageCount,
      }));
      setCountUnreadMessage(updatedCountUnreadMessage);
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  const getChatCount = async (id) => {
    try {
      const { data } = await get_count_unread_message_for_chat_id(id);
      const updatedCountUnreadMessage = countUnreadMessage.map((item) => {
        if (Number(item.chat_id) === Number(id)) {
          return {
            ...item,
            unreadMessageCount: data.data.count,
          };
        }
        return item;
      });

      setCountUnreadMessage(updatedCountUnreadMessage);
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  const getChatPublic = async () => {
    try {
      const { data } = await get_chat_public();
      setPublicChat(data.data);
      dispatch(setPublicChatId(data.data.id));
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  useEffect(() => {
    setFilterTake(selectFiltersState);
  }, [selectFiltersState]);

  useEffect(() => {
    setUserSearchTake(selectUserSearchState);
  }, [selectUserSearchState]);

  useEffect(() => {
    filterRequest(userSearchTake, filterTake);
  }, [lastMessage]);

  useEffect(() => {
    if (checkRequestChatItems) {
      getChats();
      setCheckRequestChatItems && setCheckRequestChatItems(false);
    }
  }, [checkRequestChatItems]);

  useEffect(() => {
    getChats();
    getChatPublic();
  }, []);

  useEffect(() => {
    setPrivateChat([]);
  }, [chatId]);

  useEffect(() => {
    setPublicChatItems([]);
  }, [publicChatSelected]);

  useEffect(() => {
    if (location.pathname === '/chat') {
      selectPublicChatIdState &&
        socketConnection &&
        socketConnection.emit('join_pub_room', selectPublicChatIdState);
    }
  }, [location.pathname, selectPublicChatIdState]);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('receive_pub_message', (message) => {
        if (publicChat && Number(publicChat.id) === Number(message.chat.id)) {
          const res = {
            id: publicChat.id,
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
            latestMessage: {
              lastMessage: message.text,
              messageCreatedAt: message.createdAt,
              messageSender: message.sender.username,
              messageStatus: message.status,
            },
          };

          setPublicChat(res);
        }
      });
    }
  }, [publicChat]);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('chat_new_message', (message) => {
        const isChatIdExist = chatsItem.some(
          (item) => Number(item.chat_id) === Number(message.chat.id),
        );

        if (!isChatIdExist) {
          setCheckRequestChatItems && setCheckRequestChatItems(true);
        }

        const res = {
          chat_id: message.chat.id,
          lastMessage: message.text,
          messageDate: message.createdAt,
          messageStatus: message.status,
          unreadMessageCount: message.countChatUnreadMessages,
          userAvatar: message.sender.avatar,
          userId: message.sender.id,
          username: message.sender.username,
        };

        const updatedChatsItem = chatsItem.map((item) => {
          if (item.chat_id === message.chat.id) {
            return {
              ...item,
              ...res,
            };
          }
          return item;
        });

        setChatItems(updatedChatsItem);

        const updatedCountUnreadMessage = countUnreadMessage.map((item) => {
          if (item.chat_id === message.chat.id) {
            return {
              ...item,
              unreadMessageCount: (
                Number(item.unreadMessageCount) +
                Number(message.countChatUnreadMessages)
              ).toString(),
            };
          }
          return item;
        });

        setCountUnreadMessage(updatedCountUnreadMessage);
      });
    }
  }, [chatsItem]);

  useEffect(() => {
    if (checkMessageStatus && privateChat) {
      const updatedPrivateChat = privateChat.map((item) => {
        if (Number(item.id) === Number(checkMessageStatus.messsageId)) {
          return {
            ...item,
            ...checkMessageStatus,
          };
        }
        return item;
      });

      setPrivateChat(updatedPrivateChat);
    }
  }, [checkMessageStatus]);

  useEffect(() => {
    if (checkMessagesStatus && privateChat) {
      const updatedPrivateChats = privateChat.map((chat) => {
        if (checkMessagesStatus.messages.includes(chat.id)) {
          return { ...chat, status: 3 };
        }
        return chat;
      });
      setPrivateChat(updatedPrivateChats);
    }
  }, [checkMessagesStatus]);

  return window.innerWidth > 820 ? (
    <div className={s.container}>
      <div className={s.containerChats}>
        <Chats
          getMessageInfo={getMessageInfo}
          setPublicChatSelected={setPublicChatSelected}
          chatsItem={chatsItem}
          setUserName={setUserName}
          userName={userName}
          setUserMyId={setUserMyId}
          setChatId={setChatId}
          lastMessage={lastMessage}
          chatId={chatId}
          setCheckRequest={setCheckRequest}
          setPrivateChatRoute={setPrivateChatRoute}
          countUnreadMessage={countUnreadMessage}
          getChatCount={getChatCount}
          setChatTimeCreate={setChatTimeCreate}
          setCheckRequestChatItems={setCheckRequestChatItems}
          filterRequest={filterRequest}
          publicChat={publicChat}
          publicChatSelected={publicChatSelected}
          setReciveScrol={setReciveScrol}
        />
      </div>
      <div className={s.containerChatPrivate}>
        <ChatPrivate
          setPublicChatSelected={setPublicChatSelected}
          getChats={getChats}
          chatsItem={chatsItem}
          setChatId={setChatId}
          setChatItems={setChatItems}
          setUpScroll={setUpScroll}
          reciveScrol={reciveScrol}
          setReciveScrol={setReciveScrol}
          publicChatItems={publicChatItems}
          lastId={lastId}
          setLastId={setLastId}
          setLastMessage={setLastMessage}
          setPrivateChat={setPrivateChat}
          setPublicChatItems={setPublicChatItems}
          chatId={chatId}
          privateChat={privateChat}
          userName={userName}
          userMyId={userMyId}
          getMessageInfo={getMessageInfo}
          pageLast={pageLast}
          page={page}
          setCheckRequest={setCheckRequest}
          checkRequest={checkRequest}
          setPrivateChatRoute={setPrivateChatRoute}
          userId={userId}
          setCheckMessageStatus={setCheckMessageStatus}
          setCheckMessagesStatus={setCheckMessagesStatus}
          getChatCount={getChatCount}
          chatTimeCreate={chatTimeCreate}
          publicChat={publicChat}
          publicChatSelected={publicChatSelected}
          upScroll={upScroll}
        />
      </div>
    </div>
  ) : (
    <div>
      {!privateChatRoute ? (
        <div className={s.containerChatPrivate}>
          <ChatPrivate
            setPublicChatSelected={setPublicChatSelected}
            getChats={getChats}
            chatsItem={chatsItem}
            setChatId={setChatId}
            setChatItems={setChatItems}
            setUpScroll={setUpScroll}
            reciveScrol={reciveScrol}
            setReciveScrol={setReciveScrol}
            publicChatItems={publicChatItems}
            lastId={lastId}
            setLastId={setLastId}
            setLastMessage={setLastMessage}
            setPrivateChat={setPrivateChat}
            setPublicChatItems={setPublicChatItems}
            chatId={chatId}
            privateChat={privateChat}
            userName={userName}
            userMyId={userMyId}
            getMessageInfo={getMessageInfo}
            pageLast={pageLast}
            page={page}
            setCheckRequest={setCheckRequest}
            checkRequest={checkRequest}
            setPrivateChatRoute={setPrivateChatRoute}
            userId={userId}
            setCheckMessageStatus={setCheckMessageStatus}
            setCheckMessagesStatus={setCheckMessagesStatus}
            getChatCount={getChatCount}
            chatTimeCreate={chatTimeCreate}
            publicChat={publicChat}
            publicChatSelected={publicChatSelected}
            upScroll={upScroll}
            setUserName={setUserName}
          />
        </div>
      ) : (
        <div className={s.containerChats}>
          <Chats
            getMessageInfo={getMessageInfo}
            setPublicChatSelected={setPublicChatSelected}
            chatsItem={chatsItem}
            setUserName={setUserName}
            userName={userName}
            setUserMyId={setUserMyId}
            setChatId={setChatId}
            lastMessage={lastMessage}
            chatId={chatId}
            setCheckRequest={setCheckRequest}
            setPrivateChatRoute={setPrivateChatRoute}
            countUnreadMessage={countUnreadMessage}
            getChatCount={getChatCount}
            setChatTimeCreate={setChatTimeCreate}
            setCheckRequestChatItems={setCheckRequestChatItems}
            filterRequest={filterRequest}
            publicChat={publicChat}
            publicChatSelected={publicChatSelected}
            setReciveScrol={setReciveScrol}
          />
        </div>
      )}
    </div>
  );
};

export default ChatTab;
