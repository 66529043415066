import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    activeBtn: 0,
    searchItem: "",
    userInfoByIdSearch:
      {
        "user_id": null,
        "username": "",
        "avatar": "",
        "online": null,
        "giftCount": "",
        "avgRating": "",
        "withdrawalSum": 0,
        "complaintCount": "0",
        "referralHeartsSum": 0,
        "clientCount": "0",
        "durationAmount": null
    },
    hostesInfoByIdSearch:
    {
      "user_id": null,
      "username": "",
      "avatar": "",
      "online": null,
      "giftCount": "",
      "avgRating": "",
      "withdrawalSum": 0,
      "complaintCount": "0",
      "referralHeartsSum": 0,
      "clientCount": "0",
      "durationAmount": null
  },
  },
  reducers: {
      setActiveBtn: (state, action) => {
        state.activeBtn = action.payload
      },
      setUserInfoByIdSearch: (state, action) => {
        state.userInfoByIdSearch.user_id = action.payload.user_id;
        state.userInfoByIdSearch.username = action.payload.username;
        state.userInfoByIdSearch.avatar = action.payload.avatar;
        state.userInfoByIdSearch.online = action.payload.online;
        state.userInfoByIdSearch.giftCount = action.payload.giftCount;
        state.userInfoByIdSearch.avgRating = action.payload.avgRating;
        state.userInfoByIdSearch.withdrawalSum = action.payload.withdrawalSum;
        state.userInfoByIdSearch.complaintCount = action.payload.complaintCount;
        state.userInfoByIdSearch.referralHeartsSum = action.payload.referralHeartsSum;
        state.userInfoByIdSearch.clientCount = action.payload.clientCount;
        state.userInfoByIdSearch.durationAmount = action.payload.durationAmount;
    },
        setHostesInfoByIdSearch: (state, action) => {
          state.hostesInfoByIdSearch.avatar = action.payload.avatar;
          state.hostesInfoByIdSearch.avgRating = action.payload.avgRating;
          state.hostesInfoByIdSearch.clientCount = action.payload.clientCount;
          state.hostesInfoByIdSearch.complaintCount = action.payload.complaintCount;
          state.hostesInfoByIdSearch.durationAmount = action.payload.durationAmount;
          state.hostesInfoByIdSearch.giftCount = action.payload.giftCount;
          state.hostesInfoByIdSearch.online = action.payload.online;
          state.hostesInfoByIdSearch.referralHeartsSum = action.payload.referralHeartsSum;
          state.hostesInfoByIdSearch.user_id = action.payload.user_id;
          state.hostesInfoByIdSearch.username = action.payload.username;
          state.hostesInfoByIdSearch.withdrawalSum = action.payload.withdrawalSum;
      },
    setSearchItem: (state, action) => {
      state.searchItem = action.payload
    }
  }
})

export const selectActiveBtn = (state) => state.admin.activeBtn;
export const selectUserInfoByIdSearch = (state) => state.admin.userInfoByIdSearch;
export const selectHostesInfoByIdSearch = (state) => state.admin.hostesInfoByIdSearch;
export const selectSearchItem = (state) => state.admin.searchItem;

export const {
  setActiveBtn,
  setUserInfoByIdSearch,
  setSearchItem,
  setHostesInfoByIdSearch,
} = adminSlice.actions

export default adminSlice.reducer