import { http } from './index';
import {
  ADD_AVATAR,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  REGISTRATION,
  REGISTRATION_CONFIRM,
  RESET_PASSWORD,
  TAKE_OTHER_USER,
  USER_EDIT,
  USER_INFO,
  USER_ONLINE,
  USER_VERIFY,
} from './endpoints';

const APP_URL = window.location.origin;

export const registration = async ({ email, password, terms, code }) => {
  return await http().post(REGISTRATION, {
    code,
    email,
    password,
    terms,
    link: APP_URL,
  });
};

export const registration_confirm = async (token) => {
  return await http().post(REGISTRATION_CONFIRM, token);
};

export const login = async ({ email, password }) => {
  return await http('login').post(LOGIN, { email, password });
};

export const add_avatar = async (formData) => {
  return await http().post(
    ADD_AVATAR,
    { file: formData.file },
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
};

export const user_verify = async () => {
  return await http().get(USER_VERIFY);
};

export const user_other = async (id) => {
  return await http().get(`${TAKE_OTHER_USER}${id}`);
};

export const user_info = async () => {
  return await http().get(USER_INFO);
};

export const user_online = async () => {
  return await http().get(USER_ONLINE);
};

export const user_edit = async ({
  referralCode,
  username,
  telegram,
  online,
  about_me,
  interests,
  hasCamera,
  hasMicrophone,
  familiarWithRules,
}) => {
  if (referralCode) {
    return await http().post(USER_EDIT, { referralCode });
  } else {
    return await http().post(USER_EDIT, {
      username,
      telegram,
      online,
      about_me,
      interests,
      hasCamera,
      hasMicrophone,
      familiarWithRules,
    });
  }
};

export const change_password = async ({
  currentPassword,
  newPassword,
  newPasswordConfirmation,
}) => {
  return await http().post(CHANGE_PASSWORD, {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  });
};

export const change_email = async ({ email }) => {
  return await http().post(CHANGE_EMAIL, { email, link: APP_URL });
};

export const forgot_password = async ({ email }) => {
  return await http().post(FORGOT_PASSWORD, {
    email,
    url: `${APP_URL}/reset-password`,
  });
};

export const reset_password = async ({ password, token }) => {
  return await http().post(RESET_PASSWORD, { password, token });
};

export default {
  registration,
  login,
  user_info,
  user_edit,
  user_verify,
  change_password,
  user_other,
  registration_confirm,
};
