import React from 'react';
import styles from "./TableHeader.module.css"

const tableHeaderHostes = ['Хостес', 'Заработано с рефералов', 'Рейтинг', 'Кол-во клиентов', 'Минут в привате', 'Выводы', 'Получено подарков', 'Кол-во жалоб']
const tableHeaderGamers = ['ID транзакции', 'ID пользователя', 'Сумма', 'Кол-во коинов', 'Дата', 'Время', 'Метод', 'Тип']

export const TableHeader = ({ activeBtnState }) => {
    return (
        <div className={styles.head}>
            <div className={styles.row}>
                <div className={styles.rowHeader}>
                    {activeBtnState === 0 ? (
                        tableHeaderHostes.map((head, i) => (
                            <div key={head} className={`${styles.cell} ${i !== tableHeaderHostes.length - 1 ? styles.divider : ''}`}>
                                {head}
                            </div>))
                    ) : (
                        tableHeaderGamers.map((head, i) => (
                            <div key={head} className={`${styles.cell} ${i !== tableHeaderGamers.length - 1 ? styles.divider : ''}`}>
                                {head}
                            </div>))
                    )}

                </div>
            </div>
        </div>
    );
};