import React, {useEffect, useState} from "react";
import s from "./Statistics.module.css";
import getStatistics from "../../api/statistics";
import {useSelector} from "react-redux";
import {selectIsAdminUser} from "../../redux/slices/userSlice";
import {getUserIdStatistics} from "../../api/adminStatistics";
import MyIcon from "../UI/myIcon/MyIcon";

const Statistics = ({isAdmin}) => {
    const isAdminState = useSelector(selectIsAdminUser)
    const [statisticsData, setStatisticsData] = useState([
        {label: "Кол-во клиентов", value: "0"},
        {label: "Кол-во жалоб", value: "0"},
        {label: "Кол-во клиентов", value: "0"},
        {label: "Минут в привате", value: "0"},
    ]);

    const isEmptyStatistics = statisticsData.filter((item) => item.value !== "0" && item.value !== 0).length === 0

    const fetchGetStatistics = async () => {
        try {
            const {data} = await getStatistics();
            const defaultStatisticsData = [
                {label: "Кол-во жалоб", value: data.data.complaintCount || "0"},
                {label: "Кол-во клиентов", value: data.data.clientCount || "0"},
                {label: "Кол-во подарков", value: data.data.giftCount || "0"},
                {label: "Минут в привате", value: data.data.durationAmount || "0"}
            ];
            setStatisticsData(defaultStatisticsData);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    const fetchGetStatisticsHostes = async () => {
        try {
            const {data} = await getUserIdStatistics(localStorage.getItem("hostesId"));
            const defaultStatisticsData = [
                {label: "Получено подарков", value: data.data.giftCount || "0"},
                {label: "Кол-во жалоб", value: data.data.complaintCount || "0"},
                {label: "Кол-во клиентов", value: data.data.clientCount || "0"},
                {label: "Минут в привате", value: data.data.durationAmount || "0"},
                {label: "Заработано с рефералов", value: data.data.referralHeartsSum || "0"},
                {label: "Выведено", value: data.data.withdrawalSum || "0"}
            ];
            setStatisticsData(defaultStatisticsData);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        isAdmin || isAdminState ? fetchGetStatisticsHostes() : fetchGetStatistics();
    }, [isAdmin, isAdminState]);

    return (
        <div className={s.container}>
            <div className={s.headerText}>Статистика</div>
            <div className={s.statisticsBlocks}>
                {!isEmptyStatistics ? statisticsData.map((item, index) => {
                    return (
                        <div key={index} className={isAdmin || isAdminState ? s.blockAdmin : s.block}>
                            {item.label === "Заработано с рефералов" ? (
                                <>
                                    <div className={s.blockCountLogo}>
                                        <MyIcon image={"miniLogo"} width={"53px"} height={"53px"}/>
                                        {item.value}
                                    </div>
                                    <div className={s.blockText}>{item.label}</div>
                                </>
                            ) : (
                                <>
                                    <div className={s.blockCount}>
                                        {+item.value === 0 ? 0 : (Number.isInteger(+item.value) ? +item.value : (+item.value).toFixed(2))}
                                    </div>
                                    <div className={s.blockText}>{item.label}</div>
                                </>
                            )}

                        </div>
                    )
                }) : (
                    <div className={s.emptyBlock}>
                        <p className={s.emptyText}>Для получение статистики вам нужно провести хотя бы один стрим</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Statistics;