import s from "./UIButton.module.css"

const UIButton = ({type, text, background, color, border, width, onClick}) => {

    return (
        <button
            type={type}
            onClick={onClick}
            className={s.button}
            style={{
                background: background ? background : "#FFDC64",
                border: border ? border : "",
                color: color ? color : "#051423",
                width: width ? width : ""
            }}
        >
            {text}
        </button>
    )
}

export default UIButton;