import {http} from './index'
import { BALANCY_HISTORY, BALANCY_REFERRAL, TEAMS_COMPLAINTS, TEAMS_CREATE, TEAMS_JOIN, TEAMS_LIST, TEAMS_MY, TEAMS_REFERRAL_COUNTS, TEAMS_REQUEST_JOIN, TEAMS_TOP_CURRENT_MONTH, TEAMS_TOP_LAST_MONTH, TEAMS_USER_DEL } from './endpoints'

export const get_referral_balance_history = async (page) => {
    return await http().get(`${BALANCY_HISTORY}?method=refferal&page=${page ? page : 1}&limit=20`)
}

export const get_referral_balance = async () => {
    return await http().get(`${BALANCY_REFERRAL}`)
}

export const get_top_current_month = async (page) => {
    return await http().get(`${TEAMS_TOP_CURRENT_MONTH}?page=${page ? page : 1}&limit=20`)

}

export const get_top_last_month = async () => {
    return await http().get(`${TEAMS_TOP_LAST_MONTH}`)
}

export const get_my_team = async () => {
    return await http().get(`${TEAMS_MY}`)
}

export const get_my_referral_count = async () => {
    return await http().get(`${TEAMS_REFERRAL_COUNTS}`)
}

export const get_team_list = async (page) => {
    return await http().get(`${TEAMS_LIST}?page=${page ? page : 1}&limit=20`)
}

export const get_team_request_join = async () => {
    return await http().get(`${TEAMS_REQUEST_JOIN}`)
}

export const post_create_team = async ({ name }) => {
    return await http().post(TEAMS_CREATE, { name })
}

export const post_create_team_complaints = async ({ type, userId, text }) => {
    return await http().post(TEAMS_COMPLAINTS, { type, userId, text })
}

export const post_join_team = async ({ teamId }) => {
    return await http().post(TEAMS_JOIN, { teamId })
}

export const delete_create_team = async () => {
    return await http().delete(TEAMS_CREATE)
}

export const delete_user_from_team = async ({ memberId }) => {
    return await http().delete(`${TEAMS_USER_DEL}/${memberId}`)
}

export default {
    get_referral_balance_history,
    get_referral_balance,
    get_top_current_month,
    get_top_last_month,
    get_my_team,
    get_team_list,
    post_create_team,
    delete_create_team,
    post_join_team,
    get_team_request_join,
    post_create_team_complaints,
    delete_user_from_team,
    get_my_referral_count,
}