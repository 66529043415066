import { createSlice } from "@reduxjs/toolkit";

export const adminStatSlice = createSlice({
  name: 'adminStatisctics',
  initialState: {
    statistics: [],
    gamersStatistics: []
},
  reducers: {
    resetStatistics: (state, action) => {
        state.statistics = action.payload
    },
    setStatistics: (state, action) => {
        state.statistics = [...state.statistics, ...action.payload]
    },
    setGamersStatistics: (state, action) => {
        state.gamersStatistics = [...state.gamersStatistics, ...action.payload]
    },
    resetGamersStatistics: (state, action) => {
        state.gamersStatistics = action.payload
    }
  }
})

export const selectAdminStatistics = (state) => {
    return state.adminStat.statistics
}

export const selectGamersStatistics = (state) => {
    return state.adminStat.gamersStatistics
}

export const {
  setStatistics,
  resetStatistics,
  setGamersStatistics,
  resetGamersStatistics
} = adminStatSlice.actions

export default adminStatSlice.reducer