import { combineReducers } from '@reduxjs/toolkit';
import registrationSlice from '../slices/registrationSlice';
import loginLogOutSlice from '../slices/loginLogOutSlice';
import userSlice from '../slices/userSlice';
import searchSlice from "../slices/searchSlice"
import webSocketSlice from '../slices/webSocketSlice';
import adminSlice from '../slices/adminSlice';
import adminStatSlice from '../slices/adminStatSlice';

const rootReducer = combineReducers({
  registration: registrationSlice,
  loginLogOut: loginLogOutSlice,
  user: userSlice, 
  search: searchSlice, 
  webSocket: webSocketSlice, 
  admin: adminSlice,
  adminStat: adminStatSlice 
});

export default rootReducer;
