
import React from 'react'
import ScheduleTab from '../../components/schedule/ScheduleTab'

const Schedule = () => {
  return (
      <ScheduleTab />
  )
}

export default Schedule