export const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;
  };

export const isLink = (str) => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(str);
};

export const addSpaceAfterTwoChars = (str) => {
    const strNew = str.toString();
    if (strNew.length > 6) {
        return strNew.substring(0, 1) + ' ' + strNew.substring(1, 4) + ' ' + strNew.substring(4);
    } else if (strNew.length > 5) {
        return strNew.substring(0, 3) + ' ' + strNew.substring(3);
    } else if (strNew.length > 4) {
        return strNew.substring(0, 2) + ' ' + strNew.substring(2);
    } else if (strNew.length > 3) {
        return strNew.substring(0, 1) + ' ' + strNew.substring(1);
    } else {
        return strNew;
    }
};

export const sortedData = (data) => {
    data.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
  
    return dateA - dateB;
    });
    return data;
} 

export const createUniqueArray = (arr, prop1) => {
    const seen = new Set();
    const uniqueArray = [];
    
    arr.forEach(obj => {
      const key = `${obj[prop1]}`;
      if (!seen.has(key)) {
        seen.add(key);
        uniqueArray.push(obj);
      }
    });
    
    return uniqueArray;
  };


const getDayOfWeek = (date) => {
    const daysOfWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    return daysOfWeek[date.getDay()];
}

const formatDate = (date) => {
    const dayOfWeek = getDayOfWeek(date);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substring(2);
    return `${dayOfWeek} ${day}.${month}.${year}`;
};

export const groupMessagesByDay = (messages) => {
    const groupedMessages = {};

    messages.forEach(message => {
      const createdAtDate = new Date(message.createdAt);
      const formattedDate = formatDate(createdAtDate);

      if (!groupedMessages[formattedDate]) {
        groupedMessages[formattedDate] = [];
      }

      groupedMessages[formattedDate].push(message);
    });

    return groupedMessages;
};

export const transformData = (inputData) => {
    const result = {
      "Monday": [],
      "Tuesday": [],
      "Wednesday": [],
      "Thursday": [],
      "Friday": [],
      "Saturday": [],
      "Sunday": []
    };
  
    inputData.forEach(item => {
      const dayOfWeek = new Date(item.timeStart).toLocaleDateString('en-US', { weekday: 'long' });
      const timeRange = `${new Date(item.timeStart).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}-${new Date(item.timeEnd).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  
      const transformedItem = {
        hour: timeRange,
        user: item.user || null
      };
  
      result[dayOfWeek].push(transformedItem);
    });
  
    return result;
  }

const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

export const parseTimeZoneOffset = (timeZoneString) => {
  const offsetString = timeZoneString.replace('UTC ', '');
  const [hours, minutes] = offsetString.split(':').map(part => parseInt(part, 10));
  const totalMinutes = (hours * 60 + minutes);
  return totalMinutes;
};

const formatDateSchedule = (dateString, userUtcOffset) => {
  const date = new Date(dateString);
  const localTime = date.setMinutes(userUtcOffset > 0 ? date.getMinutes() + userUtcOffset : date.getMinutes() - userUtcOffset * -1)

  const localDate = new Date(localTime);

  const daysOfWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  
  const dayOfWeek = daysOfWeek[localDate.getDay()];
  const dayOfMonth = addLeadingZero(localDate.getDate());
  const month = addLeadingZero(localDate.getMonth() + 1);

  return `${dayOfWeek} ${dayOfMonth}.${month}`;
};

export const groupDataByDate = (scheduleData, userUtcOffset) => {
  if (!Array.isArray(scheduleData)) {
      console.error('scheduleData не является массивом');
      return {};
  }

  const groupedByDate = scheduleData.reduce((acc, item) => {
      const formattedDate = formatDateSchedule(item.timeStart, userUtcOffset);

      if (!acc[formattedDate]) {
          acc[formattedDate] = [];
      }

      const startTime = new Date(item.timeStart)
      const endTime = new Date(item.timeEnd)

      startTime.setMinutes(userUtcOffset > 0 ? startTime.getMinutes() + userUtcOffset : startTime.getMinutes() - userUtcOffset * -1)
      endTime.setMinutes(userUtcOffset > 0 ? endTime.getMinutes() + userUtcOffset : endTime.getMinutes() - userUtcOffset * -1)

      item.timeStart = startTime
      item.timeEnd = endTime

      acc[formattedDate].push(item);
      return acc;
  }, {});

  return groupedByDate;
};


export const takeLocalTime = () => {
  const localDate = new Date();

  const dateTimeFormat = new Intl.DateTimeFormat();
  const timeZoneString = dateTimeFormat.resolvedOptions().timeZone;
  const country = timeZoneString.split('/')[0] || timeZoneString;

  const localHours = localDate.getHours();
  const localMinutes = localDate.getMinutes();
  const utcOffsetMinutes = localDate.getTimezoneOffset();
  
  const offsetHours = -utcOffsetMinutes / 60;
  
  const formattedHours = String(localHours).padStart(2, '0');
  const formattedMinutes = String(localMinutes).padStart(2, '0');
  
  const localTimeFormatted = `${formattedHours}:${formattedMinutes} (UTC${offsetHours >= 0 ? '+' : ''}${offsetHours}) ${country}`;
  
  return localTimeFormatted;
}

export const formatTimeToHHMMSS = (inputTime) => {
  const date = new Date(inputTime);

  const hours = addLeadingZero(date.getUTCHours());
  const minutes = addLeadingZero(date.getUTCMinutes());

  return `${hours}:${minutes}`;
}
