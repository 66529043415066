import s from "./MyButton.module.css"

const MyButton = ({type, onHandelSubmit, text, background, color, border, width, disabled}) => {

    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onHandelSubmit}
            className={s.button}
            style={{
                background: background ? background : "#FFDC64",
                border: border ? border : "",
                color: color ? color : "#051423",
                width: width ? width : ""
              }}
        >
            {text}
        </button>
    )
}

export default MyButton;