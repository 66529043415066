import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import MyIcon from "../../UI/myIcon/MyIcon";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import s from "./Convert.module.css";
import { convertValidate } from "../../../settings/validation";
import {
  post_balancy_exchange,
  post_balancy_settings_show_key,
} from "../../../api/balance";
import { useToast } from "rc-toastr";

const validationSchema = convertValidate;

const Convert = ({
  userBalance,
  setUserBalance,
  setGetExchangeHistoryData,
}) => {
  const { toast } = useToast();
  const formikRef = useRef(null);
  const [result, setResult] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorsCount, setErrorsCount] = useState({
    count: "",
  });

  const initialValues = {
    count: "",
  };

  const convertHandler = async (values) => {
    const { count } = values;

    const isNumeric = /^[0-9]+$/.test(count);

    if (!isNumeric) {
      console.error("Неверный формат числа");
      return;
    }

    try {
      const { data } = await post_balancy_settings_show_key("exchange_price");
      const val = Number(count) * Number(data.data.value);
      setResult(val);
    } catch (error) {
      console.error("convertHandler error", error);
    }
  };

  const convertCourseHandler = async () => {
    try {
      const { data } = await post_balancy_settings_show_key("exchange_price");
      setCourse(data.data.value);
    } catch (error) {
      console.error("convertCourseHandler error", error);
    }
  };

  const convertHandlerSubmit = async (values) => {
    if (isLoading) {
      return;
    }

    const { count } = values;

    if (count) {
      try {
        setIsLoading(true);
        const { data } = await post_balancy_exchange(Number(count));
        setUserBalance(data.data);
        setGetExchangeHistoryData(true);
        changeHandler();
        formikRef.current.resetForm();
        setResult(0);
        toast.success("Конвертация прошла успешно!");
      } catch (error) {
        error.response.data.message ===
        "Access denied. Your account is not verified."
          ? toast.error("Ваша анкета на проверке")
          : toast.error(error.response.data.message);

        console.error(
          "convertHandlerSubmit error",
          error.response.data.message
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const changeHandler = () => {
    setErrorsCount({
      count: "",
    });
  };

  useEffect(() => {
    convertCourseHandler();
  }, []);

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.convert}>
          <div className={s.convertRow}>
            <div className={s.convertTitle}>Баланс коинов</div>
            <div className={s.convertRowIcon}>
              <MyIcon image={"miniLogo"} width={"29px"} height={"29px"} />
              <div>х {userBalance?.hearts.toFixed(2)}</div>
            </div>
          </div>
          <div className={s.line}></div>
          <div className={s.convertRow}>
            <div className={s.convertTitle}>Баланс фиатной валюты</div>
            <div className={s.convertRowIcon}>
              {/* <MyIcon
                                image={"dollar"}
                                width={"31px"}
                                height={"31px"}
                            /> */}
              <p style={{ fontFamily: "system-ui" }}>₽</p>
              <div>{userBalance?.dollars.toFixed(2)}</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => convertHandlerSubmit(values)}
              innerRef={formikRef}
            >
              {({ errors, touched }) => {
                let err = {
                  count: "",
                };

                if (errorsCount.count) {
                  err = errorsCount;
                } else {
                  err = errors;
                }

                const countError = err.count && touched.count;

                return (
                  <Form>
                    <div
                      className={`${
                        touched.count && errors.count
                          ? s.convertInputsM
                          : s.convertInputs
                      }`}
                    >
                      <div className={s.convertHeader} htmlFor="count">
                        <div>Обменник</div>
                        <div className={s.convertHeaderPrice}>
                          <MyIcon
                            image={"miniLogo"}
                            width={"20px"}
                            height={"20px"}
                          />
                          <div>
                            x 1 <span className={s.convertHeaderEqual}>=</span>
                          </div>
                          <p
                            style={{
                              fontFamily: "system-ui",
                              color: "#FADC77",
                            }}
                          >
                            ₽
                          </p>
                          <div>{course}</div>
                        </div>
                      </div>
                      <div className={s.inputsBlock}>
                        <div className={s.input}>
                          <MyInput
                            type="number"
                            name="count"
                            placeholder="0.00"
                            isError={countError}
                            errorText={err.count}
                            resetErrors={changeHandler}
                            width={"100%"}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                convertHandler({ count: e.target.value });
                              } else {
                                if (timeoutId) {
                                  clearTimeout(timeoutId);
                                }
                                const newTimeoutId = setTimeout(() => {
                                  convertHandler({ count: e.target.value });
                                }, 500);
                                setTimeoutId(newTimeoutId);
                              }
                            }}
                          />
                          <div className={s.inputIcon}>
                            <MyIcon
                              image={"miniLogo"}
                              width={"20px"}
                              height={"20px"}
                            />
                          </div>
                        </div>

                        <div className={s.arrow}>
                          <MyIcon
                            image={"rightArrowRound"}
                            width={"24px"}
                            height={"24px"}
                          />
                        </div>

                        <div className={s.input}>
                          <MyInput
                            isInput
                            type="text"
                            id="inputValue"
                            notReset
                            width={"100%"}
                            value={result ? result : ""}
                            placeholder={
                              !err.count ? (result ? result : "0.00") : "0.00"
                            }
                            isdisabled
                          />
                          <div className={s.inputIcon}>
                            <p
                              style={{
                                fontFamily: "system-ui",
                                color: "#FADC77",
                              }}
                            >
                              ₽
                            </p>
                          </div>
                        </div>
                      </div>
                      {touched.count && errors.count && (
                        <div style={{ color: "darkred", marginTop: "5px" }}>
                          {errors.count}
                        </div>
                      )}
                    </div>
                    <div className={s.btn} onClick={convertHandlerSubmit}>
                      <MyButton
                        type="submit"
                        width={"100%"}
                        text={"Конвертировать"}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Convert;
