import { isLink } from '../../settings/helpers'
import styles from './Message.module.css'
import check from './img/checked.svg'
import notCheck from './img/notCheck.svg'

export const Message = ({text, isMyMessage, time, isChecked}) => (
    <div className={`${styles.message} ${isMyMessage ? styles.myMsg : styles.notMy}`}>
            {isLink(text) 
                ? (
                    <a href={text}>{text}</a>
                ) : text
            }
        {time && (
            <div className={styles.time}>
                <img src={isChecked ? check : notCheck} alt="isChecked"/>
                <span>{time}</span>
            </div>
        )}
    </div>
)
