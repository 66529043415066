import React, { useState } from 'react';
import styles from './ButtonsBlock.module.css'
import MyIcon from "../../UI/myIcon/MyIcon";
import { useDispatch } from 'react-redux';
import { setActiveBtn } from '../../../redux/slices/adminSlice';
import MyCalendar from '../../calendar/MyCalendar';

const TAB_NAME = {
    0: 'HOSTES',
    1: 'GAMERS'
}

const ButtonsBlock = ({ userId }) => {
    const dispatch = useDispatch() 
    const [activeBtnIndex, setActiveBtnIndex] = useState(0);
    const [calendarOpen, setCalendarOpen] = useState(false);

    const activeButtonHandler = (i)=> {
        setActiveBtnIndex(i)
        dispatch(setActiveBtn(i))
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.mainButtons}>
                <button className={`${styles.optionButton} ${ activeBtnIndex === 0 ? styles.active : ' '}`} onClick={()=> activeButtonHandler(0)}>Хостес</button>
                <div className={styles.divider} />
                <button className={`${styles.optionButton} ${ activeBtnIndex === 1 ? styles.active : ' '}`} onClick={()=> activeButtonHandler(1)}>Игроки</button>
            </div>
            <button onClick={() => setCalendarOpen(!calendarOpen)} className={styles.calendarBtn}>
                <MyIcon image={"calendar"} width={"17px"} height={"17px"} color={'#62708E'} />
            </button>

            {calendarOpen && (
                <div className={styles.calendar}><MyCalendar userId={userId} setCalendarOpen={setCalendarOpen} type={TAB_NAME[activeBtnIndex]} /></div>
            )}
        </div>
    );
};

export default ButtonsBlock;