import React, {useEffect, useRef, useState} from "react";

import s from "./PaginationReferral.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon";
import {get_top_current_month} from "../../../../api/referral";
import {addSpaceAfterTwoChars} from "../../../../settings/helpers";

const PaginationReferral = ({ratesListIsUpdate, setRatesListIsUpdate}) => {
    const containerRef = useRef(null);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [pageLast, setPageLast] = useState(1);

    useEffect(() => {
        if (ratesListIsUpdate) {
            setPage(1);
            setTransactions([])
            loadTransactions()
        }
    }, [ratesListIsUpdate])

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page, pageLast, transactions]);

    const handleScroll = () => {
        const container = containerRef.current;
        let timer = 0;

        if (page <= pageLast) {
            if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
                timer = setTimeout(() => {
                    loadTransactions()
                }, 300);
            }
        }

        return () => {
            clearTimeout(timer);
        };
    };

    const loadTransactions = async () => {
        try {
            const {data} = await get_top_current_month(ratesListIsUpdate ? 1 : page);

            setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
            setPage((prevPage) => prevPage + 1);
            setPageLast(data.pagination.lastPage);
            setRatesListIsUpdate(false)
        } catch (error) {
            console.error("ratesListIsUpdate error", error)
        }
    };

    useEffect(() => {
        loadTransactions()
    }, [])

    return (
        <div ref={containerRef} className={s.paginationReferral} style={{
            height: window.innerWidth >= 1280 ? window.innerHeight / 2 - 130 : 'max-content',
            overflow: 'auto',
            maxHeight: '300px'
        }}>
            {transactions.map((item, index) => (
                <div
                    className={`${s.container} ${index % 2 === 0 ? s.evenBackground : s.oddBackground}`}
                    key={index}
                >
                    <div className={s.flex}>
                        <div>{index + 1}</div>
                        <div
                            className={s.username}>{item.team_name.length > 10 ? `${item.team_name.slice(0, 10)}...` : item.team_name}</div>
                    </div>
                    <div className={s.flex}>
                        <div className={s.count}>
                            <MyIcon
                                image={"miniLogo"}
                                width={"19"}
                                height={"19"}
                            />
                            <div>x</div>
                            <div className={s.amount}>{addSpaceAfterTwoChars(item?.totalHearts.toFixed(2))}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PaginationReferral;
