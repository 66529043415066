import React from 'react';
import s from "./Download.module.css"

class Download extends React.Component {
  render() {
    return (
      <div className={s.download}>
        <div className={s.circle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 48 48" fill="none">
                <mask id="path-1-inside-1_3473_25672" fill="white">
                    <path d="M24 1.21796C24 0.545299 24.5457 -0.00322403 25.2175 0.0308932C29.5369 0.250253 33.7251 1.63355 37.3337 4.04473C41.2805 6.68188 44.3566 10.4302 46.1731 14.8156C47.9896 19.201 48.4649 24.0266 47.5388 28.6822C46.6128 33.3377 44.327 37.6141 40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.63355 33.7251 0.250253 29.5369 0.0308932 25.2175C-0.00322403 24.5457 0.545299 24 1.21796 24C1.89062 24 2.43233 24.5458 2.4703 25.2173C2.68723 29.0546 3.92664 32.7724 6.07012 35.9804C8.43961 39.5266 11.8075 42.2905 15.7478 43.9226C19.6881 45.5547 24.0239 45.9818 28.2069 45.1497C32.39 44.3177 36.2323 42.2639 39.2481 39.2481C42.2639 36.2323 44.3177 32.39 45.1497 28.2069C45.9818 24.0239 45.5547 19.6881 43.9226 15.7478C42.2905 11.8075 39.5266 8.43962 35.9804 6.07012C32.7724 3.92664 29.0546 2.68724 25.2173 2.4703C24.5458 2.43233 24 1.89062 24 1.21796Z"/>
                </mask>
                <path d="M24 1.21796C24 0.545299 24.5457 -0.00322403 25.2175 0.0308932C29.5369 0.250253 33.7251 1.63355 37.3337 4.04473C41.2805 6.68188 44.3566 10.4302 46.1731 14.8156C47.9896 19.201 48.4649 24.0266 47.5388 28.6822C46.6128 33.3377 44.327 37.6141 40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.63355 33.7251 0.250253 29.5369 0.0308932 25.2175C-0.00322403 24.5457 0.545299 24 1.21796 24C1.89062 24 2.43233 24.5458 2.4703 25.2173C2.68723 29.0546 3.92664 32.7724 6.07012 35.9804C8.43961 39.5266 11.8075 42.2905 15.7478 43.9226C19.6881 45.5547 24.0239 45.9818 28.2069 45.1497C32.39 44.3177 36.2323 42.2639 39.2481 39.2481C42.2639 36.2323 44.3177 32.39 45.1497 28.2069C45.9818 24.0239 45.5547 19.6881 43.9226 15.7478C42.2905 11.8075 39.5266 8.43962 35.9804 6.07012C32.7724 3.92664 29.0546 2.68724 25.2173 2.4703C24.5458 2.43233 24 1.89062 24 1.21796Z" stroke="url(#paint0_angular_3473_25672)" strokeWidth="3" mask="url(#path-1-inside-1_3473_25672)"/>
                <defs>
                    <linearGradient id="paint0_angular_3473_25672" x1="100%" y1="0" x2="0" y2="0">
                    <stop offset="0%" stopColor="#457CE9"/>
                    <stop offset="100%" stopColor="rgba(69, 124, 233, 0.00)"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
      </div>
    );
  }
}

export default Download;
