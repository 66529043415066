import {http} from './index'
import { CHATS_COUNT, CHATS_FILTER, CHAT_CREATE, CHAT_ID, CHAT_LIST, CHAT_PUBLIC, CHECK_FRIEND, FRIEND_ADD, FRIEND_DEL } from './endpoints'

export const get_chat_public = async () => {
    return await http().get(CHAT_PUBLIC)
}

export const get_chat_list = async () => {
    return await http().get(CHAT_LIST)
}

export const get_chat_id = async ({id, page}) => {
    return await http().get(`${CHAT_ID}${id}?page=${page}&limit=30`)
}

export const post_create_chat = async ({ userId }) => {
    return await http().post(CHAT_CREATE, { userId })
}

export const get_count_unread_message_for_chat_id = async (chatId) => {
    return await http().get(`${CHATS_COUNT}?chatId=${chatId}`)
}

export const user_friend_check = async ({ userId }) => {
    return await http().post(CHECK_FRIEND, { userId })
}

export const friend_del = async ({ userId }) => {
    return await http().post(FRIEND_DEL, { userId })
}

export const friend_add = async ({ userId }) => {
    return await http().post(FRIEND_ADD, { userId })
}

export const chat_filter_and_search = async ({filter, username}) => {
    if (filter && !username) {
        return await http().get(`${CHATS_FILTER}?filter=${filter}`)
    } else if (!filter && username) {
        return await http().get(`${CHATS_FILTER}?username=${username}`)
    } else if (filter && username) {
        return await http().get(`${CHATS_FILTER}?filter=${filter}&username=${username}`)
    } else if (!filter && !username) {
        return await http().get(`${CHATS_FILTER}`)
    } else return "Have not filter or username"
}

export default {
   get_chat_list,
   get_chat_id,
   get_count_unread_message_for_chat_id,
   chat_filter_and_search,
   post_create_chat,
   get_chat_public,
   friend_del,
   friend_add,
}