
import React, { useState } from 'react'
import s from "./NotificationType.module.css"
import MyIcon from '../../UI/myIcon/MyIcon'
import MyButton from '../../UI/myButton/MyButton'
import { approve_notification, delete_notification } from '../../../api/notifications'
import { useToast } from "rc-toastr"
import { CLOSE_USER_POPUP, OPEN_USER_POPUP, modifyStringAdmin, modifyStringWithLink } from '../../../settings/transformToLink'
import ModalUserProfileInfo from '../../modals/modalProfile/ModalUserProfileInfo'
import { post_create_chat } from '../../../api/chats'
import { useNavigate } from 'react-router-dom'
import { formatDateTime } from '../../../settings/formatDate'
import { useDispatch, useSelector } from 'react-redux'
import { setChatIdRoom, setIsVideoPublic, setUserTime } from '../../../redux/slices/webSocketSlice'
import { selectUserProfile, setCallerInfo, setIsMyStream, setIsPrivateVideo, setIsStream } from '../../../redux/slices/userSlice'

const NotificationType = ({ notificationByType, page, notificationItem, getNotificationType }) => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userOtherId, setUserOtherId] = useState(null);

  const approve = (link, id, name) => {
    if (isLoading) {
        return Promise.reject("Already loading");
    }

    setIsLoading(true);

    const pageNeed = page > 1 ? page - 1 : 1;

    const processApproval = async () => {
        const { data } = await approve_notification(link);
        const res = data.data.participants;
        if (data.data.error && data.data.error === "The invitation was canceled or out of date") {
          toast.success("Приглашение было отменено или устарело.")
        } else {
            dispatch(setIsPrivateVideo(true));
            dispatch(setCallerInfo({
                callerId: res.callerId,
                receiverId: res.receiverId,
            }));          
        }

        await delete_notification({ id });
        await getNotificationType(notificationItem.type, pageNeed);

        const takeItem = notificationByType.find((item) => item.id === id);
        takeItem.actions = null;
    };

    const processRejection = async () => {
        await approve_notification(link);

        if (name === "Начать трансляцию") {
            dispatch(setIsMyStream(true));
            dispatch(setIsStream(true));
            dispatch(setIsVideoPublic(true))
        }

        await delete_notification({ id });
        await getNotificationType(notificationItem.type, pageNeed);

        const takeItem = notificationByType.find((item) => item.id === id);
        takeItem.actions = null;
    };

    return Promise.all([
        name === "Принять приглашение" ? processApproval() : processRejection(),
    ])
    .catch((error) => {  
        error.response.data.message === "You cannot start a stream because you are currently in a conversation" 
          && toast.error("Вы не можите начать стрим пока находитесь в приватном звонке!");
        toast.error("Заявка не принята");
        console.error("Error in approval process:", error);
        // throw error;
    })
    .finally(() => setIsLoading(false));
};


  const createMessage = async (userId) => {
    try {
      const { data } = await post_create_chat(JSON.parse(userId))
      dispatch(setChatIdRoom(data.data.id))
      dispatch(setUserTime(data.data.createdAt))
      navigate("/chat")
    } catch (error) {
      
    }
  }
  
  const takeUserDataOther = (id) => {
    setUserOtherId(JSON.parse(id).userId)
  }

  return (
    <div>
        <div className={s.header}>
          <MyIcon
            image={notificationItem.type} 
            width={'20px'} 
            height={'20px'}
          />
          <div>{notificationItem.name}</div>
        </div>

        <div className={s.line}></div>

        <div className={s.itemMessage}>
          {
            notificationByType.map((notification, index) => (
              <div key={index}>
                <div className={s.item}>
                  <div className={s.headerBlock}>
                    <div className={s.headerMessage}>
                      {!notification.readAt &&
                        <MyIcon
                          image={"notReadAt"} 
                          width={'9px'} 
                          height={'9px'}
                        />
                      }

                      <div>{notification.title}</div>
                    </div>
                    <div className={s.time}>{formatDateTime(notification.createdAt)}</div>
                  </div>  

                  {notification.message.includes("<checkMarkGreenText>") && (
                    <div className={s.textFromAdmin}>
                      <div className={s.textIcon}>
                          <MyIcon image={"successFromAdmin"} width={"18px"} height={"18px"} />
                      </div>                      

                      {modifyStringAdmin(notification.message)}
                    </div>
                  )} 

                  {notification.message.includes("<crossRedTex>") && (
                    <div className={s.textFromAdmin}>
                      <div className={s.textIcon}>
                          <MyIcon image={"error"} width={"18px"} height={"18px"} />
                      </div>                      

                      {modifyStringAdmin(notification.message)}
                    </div>
                  )} 
                  
                  {notification.message.includes("<openUserPopup>") && (
                    <div onClick={() => takeUserDataOther(notification.data)} className={s.text}>{modifyStringWithLink(notification.message, OPEN_USER_POPUP, CLOSE_USER_POPUP, setIsModalOpen)}</div>
                  )} 

                  {!notification.message.includes("<openUserPopup>") && !notification.message.includes("<crossRedTex>") && !notification.message.includes("<checkMarkGreenText>") && (
                    <div className={s.text}>{notification.message}</div>
                  )} 

                  {isModalOpen && JSON.parse(notification.data)?.userId === userOtherId && (
                    <ModalUserProfileInfo 
                      userData={userOtherId} 
                      isOpen={isModalOpen} 
                      onClose={() => setIsModalOpen(false)} 
                      requestName={createMessage} 
                    />
                  )}

                  {notification.actions && (
                    <div className={s.actionsButton}>
                      <div className={s.actionsButtonEvent}>
                        <MyButton 
                          onHandelSubmit={() => approve(JSON.parse(notification.actions).accept.action, notification.id, JSON.parse(notification.actions).accept.name)}
                          text={JSON.parse(notification.actions).accept?.name}
                        />                        
                      </div>
                      {JSON.parse(notification.actions).cancel && (
                        <div className={s.actionsButtonEvent}>
                          <MyButton 
                            onHandelSubmit={() => approve(JSON.parse(notification.actions).cancel.action, notification.id)}
                            text={JSON.parse(notification.actions).cancel?.name}
                            background={"#051423"}
                            color={"#62708E"}  
                            border={"1px solid var(--dark-theme-text-second-text, #62708E)"}
                          />                        
                        </div>
                      )}


                    </div>
                  )}     

                </div>

                {index !== notificationByType.length - 1 && (
                  <div className={s.line}></div>
                )}
              </div>
             
            ))
          }
        </div>
    </div>
  )
}

export default NotificationType