import React from 'react'
import s from "./ReferralBalance.module.css"
import MyIcon from "../../../UI/myIcon/MyIcon"
import PaginationHistoryReferral from '../paginationHistoryReferral/PaginationHistoryReferral'

const ReferralBalance = ({ balanceCount, balanceListIsUpdate, setBalanceListIsUpdate }) => {

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerName}>
            <MyIcon 
              image={"balance"}
              width={"20px"}
              height={"20px"}
            />
            Ваш реферальный баланс          
        </div>
        <div className={s.headerCount}>
            <MyIcon 
              image={"miniLogo"}
              width={"24px"}
              height={"24px"}
            />
            x {balanceCount > 0 ? balanceCount.toFixed(2) : 0}          
        </div>
      </div>
      <div className={s.listHeader}>
          Список начислений
      </div>
      <PaginationHistoryReferral balanceListIsUpdate={balanceListIsUpdate} setBalanceListIsUpdate={setBalanceListIsUpdate} />
    </div>
  )
}

export default ReferralBalance