import React, { useEffect, useState } from 'react';
import s from './Chats.module.css';
import MyIcon from '../../UI/myIcon/MyIcon';
import { formatTime } from '../../../settings/formatDate';
import { truncateText } from '../../../settings/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFromPublicChat,
  selectUserNikNameFromPublic,
  selectUserProfile,
  setFromPublicChat,
  setUpdateCountChats,
  setUserProfile,
} from '../../../redux/slices/userSlice';
import { user_info } from '../../../api/user';
import {
  selectChatIdRoom,
  selectUserNikName,
  selectUserTime,
  setChatIdRoom,
  setIsPublicChat,
} from '../../../redux/slices/webSocketSlice';

import usernameImg from '../../../images/username.svg';
import { useWebConnectionSocket } from '../../../socket/webSocketConnection';
import { setFilters, setUserSearch } from '../../../redux/slices/searchSlice';

const Chats = ({
  setPrivateChatRoute,
  setCheckRequestChatItems,
  setCheckRequest,
  chatId,
  getMessageInfo,
  setChatId,
  chatsItem,
  setUserName,
  setUserMyId,
  countUnreadMessage,
  getChatCount,
  userName,
  setChatTimeCreate,
  publicChat,
  setReciveScrol,
  setPublicChatSelected,
  publicChatSelected,
  filterRequest,
}) => {
  const dispatch = useDispatch();
  const socketConnection = useWebConnectionSocket();
  const userIdMy = useSelector(selectUserProfile);
  const userNikNameState = useSelector(selectUserNikName);
  const chatIdState = useSelector(selectChatIdRoom);
  const chatTime = useSelector(selectUserTime);
  const selectFromPublicChatState = useSelector(selectFromPublicChat);
  const selectUserNikNameFromPublicState = useSelector(
    selectUserNikNameFromPublic,
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermError, setSearchTermError] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const filters = {
    'Все чаты': 'allChats',
    Друзья: 'friends',
    Команда: 'teams',
    Рефералы: 'referrals',
    Администратор: 'admin',
  };

  const userMy = async () => {
    try {
      const { data } = await user_info();
      dispatch(setUserProfile(data.data));
    } catch (error) {
      console.error('userMy', error);
    }
  };

  const handelClick = (item) => {
    setReciveScrol(true);
    setPublicChatSelected(false);
    dispatch(setFromPublicChat(null));
    if (Number(chatId) !== item.chat_id) {
      getMessageInfo(item?.chat_id, 1);
      setUserName(item.username);
      setChatId(item.chat_id.toString());
      setUserMyId(userIdMy.id);
      setCheckRequest(true);
      setPrivateChatRoute(false);
      dispatch(setChatIdRoom(item.chat_id));
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        getChatCount(item.chat_id);
      }, 1000);

      setTimeoutId(id);
      dispatch(setUpdateCountChats(true));
    }

    if (
      socketConnection &&
      Number(chatId) !== item.chat_id &&
      !publicChatSelected
    ) {
      socketConnection.emit('leave_room', chatId);
    }
  };

  const handelClickPublic = () => {
    setReciveScrol(true);
    if (!publicChatSelected && publicChat && publicChat.id) {
      setChatId(publicChat.id);
      setCheckRequest(true);
      setPrivateChatRoute(false);
      setPublicChatSelected(true);
      dispatch(setChatIdRoom(publicChat.id));
      dispatch(setIsPublicChat(true));
      getMessageInfo(publicChat.id, 1, true);
      setUserName(publicChat?.latestMessage?.messageSender);

      if (socketConnection && Number(chatId) !== publicChat.id) {
        chatId && socketConnection.emit('leave_room', chatId);
      }
    }
  };

  const handelOpenClick = (chatIdState) => {
    setReciveScrol(true);
    // setPublicChatSelected(false);
    dispatch(setIsPublicChat(false));
    if (selectFromPublicChatState) {
      getMessageInfo(chatIdState, 1);
      setUserName(selectUserNikNameFromPublicState);
      // setChatId(chatIdState.toString());
      setUserMyId(selectFromPublicChatState);
      setCheckRequest(true);
      setPrivateChatRoute(true);
      dispatch(setChatIdRoom(chatIdState));
      setCheckRequestChatItems && setCheckRequestChatItems(true);
      setChatTimeCreate(chatTime);
    } else {
      getMessageInfo(chatIdState, 1);
      setUserName(userNikNameState);
      // setChatId(chatIdState.toString());
      setUserMyId(userIdMy.id);
      setCheckRequest(true);
      setPrivateChatRoute(true);
      dispatch(setChatIdRoom(chatIdState));
      setChatTimeCreate(chatTime);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      getChatCount(chatIdState);
    }, 1000);

    setTimeoutId(id);
    dispatch(setUpdateCountChats(true));
  };

  const handleInputChange = (e) => {
    setSearchTerm(e);
    dispatch(setUserSearch(e));

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (e === '') {
      setSearchTermError('');
      filterRequest(e, selectedFilter);
    }

    if (e.length >= 2 || e === '') {
      setSearchTermError('');
      const newTimeoutId = setTimeout(() => {
        filterRequest(e, selectedFilter);
      }, 500);

      setTimeoutId(newTimeoutId);
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    dispatch(setFilters(filter));
    setFilterVisible(false);
    filterRequest(searchTerm, filter);
  };

  useEffect(() => {
    !userName && handelOpenClick(chatIdState);
  }, [userName]);

  useEffect(() => {
    selectFromPublicChatState && handelOpenClick(chatIdState);
  }, [selectFromPublicChatState]);

  useEffect(() => {
    userMy();
  }, []);

  return (
    <div className={s.container}>
      {filterVisible ? (
        <div
          onClick={() => filterVisible && setFilterVisible(false)}
          className={s.backdrop}
        />
      ) : null}
      <div className={s.header}>
        <div className={s.title}>
          <MyIcon image={'chat'} width={'20px'} height={'20px'} />
          <div>Чаты</div>
        </div>
        <div className={s.block}>
          <div
            className={`${s.filter} ${
              filterVisible ? s.filterVisible : s.filterNotVisible
            }`}
            onClick={() => setFilterVisible(!filterVisible)}
          >
            {selectedFilter
              ? truncateText(
                  Object.keys(filters).find(
                    (key) => filters[key] === selectedFilter,
                  ),
                  8,
                )
              : 'Все чаты'}
            <MyIcon
              image={filterVisible ? 'upArrow' : 'downArrow'}
              width={'16px'}
              height={'16px'}
            />

            {filterVisible && (
              <div className={s.filterList}>
                {Object.keys(filters).map((filter) => (
                  <div
                    key={filters[filter]}
                    className={
                      selectedFilter === filters[filter]
                        ? s.selectedFilter
                        : s.filterItem
                    }
                    onClick={() => handleFilterSelect(filters[filter])}
                  >
                    {filter}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={s.searchContainer}>
            <div className={s.search}>
              <input
                id="inputValueName"
                type="text"
                name="inputValueName"
                placeholder={'Поиск по имени'}
                value={searchTerm}
                onChange={(e) => handleInputChange(e.target.value)}
              />
              <div className={s.icon}>
                <MyIcon image={'search'} width={'20px'} height={'20px'} />
              </div>
            </div>
            {searchTermError && (
              <div className={s.errorText}>{searchTermError}</div>
            )}
          </div>
        </div>
      </div>

      <div
        onClick={handelClickPublic}
        className={`${s.chatsSocial} ${
          publicChatSelected ? s.selectedChat : ''
        }`}
      >
        <div style={{ fontWeight: '600' }} className={s.titlePublic}>
          Чат общего стрима
        </div>
        <div className={s.messagePublic}>
          <div className={s.userBlock}>
            <img src={usernameImg} alt="usernameImg" />
            <div>
              {publicChat?.latestMessage?.messageSender
                ? publicChat?.latestMessage?.messageSender
                : '-'}
            </div>
          </div>
          <div>
            {publicChat?.latestMessage?.lastMessage
              ? truncateText(publicChat?.latestMessage?.lastMessage, 10)
              : '...'}
          </div>
        </div>
      </div>

      {chatsItem &&
        chatsItem.map((item, index) => (
          <div
            key={index}
            onClick={() => handelClick(item)}
            className={`${s.chatsSocial} ${
              !publicChatSelected && chatId === item.chat_id.toString()
                ? s.selectedChat
                : ''
            }`}
          >
            <div className={s.titleSocial}>
              <div>{item.username ? item.username : '-'}</div>
              {(item.messageDate || chatTime) && (
                <div style={{ fontWeight: '600' }} className={s.time}>
                  {formatTime(item.messageDate ? item.messageDate : chatTime)}
                </div>
              )}
            </div>
            <div className={s.messageSocial}>
              <div>{truncateText(item.lastMessage, 25)}</div>
              {countUnreadMessage.map(
                (itemCount, ind) =>
                  itemCount.chat_id === item.chat_id &&
                  itemCount.unreadMessageCount > 0 && (
                    <div key={ind} className={s.count}>
                      {itemCount.unreadMessageCount}
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Chats;
