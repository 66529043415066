import {http} from './index'
import { ADMIN_MAIN_STATISTICS, ADMIN_LIST_HOSTES } from './endpoints'

export const getMainAdminStatistics = async () => {
    return await http().get(ADMIN_MAIN_STATISTICS)
}

export const getUserIdStatistics = async ({userId, startDate, endDate}) => {
    if (startDate && endDate) {
        return await http().get(`${ADMIN_LIST_HOSTES}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`)
    } else if (startDate && !endDate) {
        return await http().get(`${ADMIN_LIST_HOSTES}?userId=${userId}?startDate=${startDate}`)
    } else if (!startDate && !endDate) {
        return await http().get(`${ADMIN_LIST_HOSTES}?userId=${userId}`)
    }
}

export default {
    getMainAdminStatistics,
    getUserIdStatistics
};