import React, { useEffect, useState } from 'react';
import s from "./UnicodeList.module.css";
import { useDispatch } from 'react-redux';
import { setTimeZone } from '../../redux/slices/userSlice';
import { takeLocalTime } from '../../settings/helpers';
import icon from './img/selectIcon.svg'
const allTimeZones = () => {
  const timeZones = [];
  for (let i = -12; i <= 12; i++) {
    for (let j = 0; j < 60; j += 15) {
      const offsetHours = i + j / 60;
      if (Number.isInteger(offsetHours)) {
        const sign = offsetHours >= 0 ? '+' : '-';
        const offsetFormatted = `${sign}${String(Math.abs(offsetHours)).padStart(2, '0')}:00`;

        timeZones.push(`UTC ${offsetFormatted}`);
      }
    }
  }
  return timeZones;
};

const UnicodeList = () => {
  const dispatch = useDispatch()
  const [openUtf, setOpenUtf] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(localStorage.getItem("timeZones") ? localStorage.getItem("timeZones") : "");

  const handleChange = (event) => {
    setSelectedTimeZone(event.target.value);
    dispatch(setTimeZone(event.target.value))
    localStorage.setItem("timeZones", event.target.value )
  };

  const timeZones = allTimeZones();

  useEffect(() => {
    setSelectedTimeZone(localStorage.getItem("timeZones") ? localStorage.getItem("timeZones") : takeLocalTime())
  }, [])

  return (
    <div className={s.openUtf}>
        <div className={s.settingsText}>ЧАСОВОЙ ПОЯС</div>
        <div onClick={() => setOpenUtf(!openUtf)} className={s.timeInput}>
          <img className={s.icon} src={icon} alt="icon"/>
            <select className={s.select} value={selectedTimeZone} onChange={handleChange}>
                  <option value="">{takeLocalTime()}</option>
                  {timeZones.map((zone, index) => (
                    <option key={index} value={zone}>
                      {zone}
                    </option>
                  ))}
            </select>
        </div>
    </div>
  );
};

export default UnicodeList;
