import {http} from './index'
import { VIDEO_ACTIVE_ID } from './endpoints'
import { VIDEO_ACTIVE } from './endpoints'
import { VIDEO_REINVITE } from './endpoints'


export const get_video_active_id = async () => {
    return await http().get(VIDEO_ACTIVE_ID)
}

export const reinvite_chat = async (url) => {
    return await http().get(url)
}

export const get_active_video_chat = async ({userId}) => {
    return await http().get(`${VIDEO_ACTIVE}?userId=${userId}`)
}

export const post_reinvite_request = async ({ chatId, userId }) => {
    return await http().post(VIDEO_REINVITE, { chatId, userId })
}


export default {
    get_video_active_id,
    reinvite_chat,
    get_active_video_chat,
    post_reinvite_request
}
