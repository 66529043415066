export const formatDateTime = (dateTimeString, withoutMinuts, onlyMinutes) => {
    const date = new Date(dateTimeString);
      
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    if (onlyMinutes) {
      return `${hours}:${minutes}`;
    } else {
      return withoutMinuts ? `${day}.${month}.${year}` : `${day}.${month}.${year} ${hours}:${minutes}`;
    }
  }

  export const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${hours}:${minutes}`;
  }

  export const formatTimeSecond = (inputTime) => {
    const timeParts = inputTime.split(':');
    
    if (timeParts.length >= 2) {
      const hours = timeParts[0];
      const minutes = timeParts[1];
      
      const formattedHours = hours.padStart(2, '0');
      const formattedMinutes = minutes.padStart(2, '0');
      
      return `${formattedHours}:${formattedMinutes}`;
    } else {
      return 'Invalid time format';
    }
}

  export const formatDateWithOutYear = (inputDate) => {
      const dateParts = inputDate.split('-');
      
      if (dateParts.length === 3) {
      //   const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        const formattedMonth = month.padStart(2, '0');
        const formattedDay = day.padStart(2, '0');
        
        return `${formattedDay}.${formattedMonth}`;
      } else {
        return 'Invalid date format';
      }
  }