import React, { useState } from 'react';
import UIButton from "../../UI/uiButton/UIButton";
import MyIcon from "../../UI/myIcon/MyIcon";
import ButtonsBlock from "../buttonsBlock/ButtonsBlock";

import styles from './StatsControl.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getUserIdStatistics } from '../../../api/adminStatistics';
import { selectActiveBtn, setSearchItem, setUserInfoByIdSearch } from '../../../redux/slices/adminSlice';
import { useToast } from 'rc-toastr';
import { get_gamers_export, get_hostes_export } from '../../../api/getHostesList';
import { useEffect } from 'react';

const StatsControl = () => {
    const dispatch = useDispatch()
    const { toast } = useToast()
    const activeBtnState = useSelector(selectActiveBtn)
    const [searchTermError, setSearchTermError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [timeoutId, setTimeoutId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const buttonHandler = () => {
        activeBtnState === 0 ? exportHostesRequest() : exportGamersRequest()
    }

    const exportHostesRequest = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const { data } = await get_hostes_export(searchTerm && searchTerm)
            const url = data.data.file;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_file.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("Успешно экспортированно")
        } catch (error) {
            console.error("searchRequest", error)
            toast.error("Ошибка экспорта")
        } finally {
            setIsLoading(false);
        }
    }

    const exportGamersRequest = async () => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            const { data } = await get_gamers_export(searchTerm && searchTerm)
            const url = data.data.file;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_file.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("Успешно експортированно")
        } catch (error) {
            console.error("searchRequest", error)
            toast.error("Ошибка экспорта")
        } finally {
            setIsLoading(false);
        }
    }

    const searchRequest = async (e) => {
        if (activeBtnState === 0) {
            try {
                const { data } = await getUserIdStatistics({userId: e})
                dispatch(setUserInfoByIdSearch(data.data))
            } catch (error) {
                setSearchTermError("Данных нет")
                console.error("searchRequest", error)
            }            
        } else if (activeBtnState === 1) {
            try {
                const { data } = await getUserIdStatistics({userId: e})
                dispatch(setUserInfoByIdSearch(data.data))
            } catch (error) {
                setSearchTermError("Данных нет")
                console.error("searchRequest", error)
            }   
        }

    }

    const handleInputChange = (e) => {
        setSearchTerm(e);   
    
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        if (e === "") {
            dispatch(setSearchItem(e))
            setSearchTermError("")
            dispatch(setUserInfoByIdSearch({
                "user_id": null,
                "username": "",
                "avatar": "",
                "online": null,
                "giftCount": "",
                "avgRating": "",
                "withdrawalSum": 0,
                "complaintCount": "0",
                "referralHeartsSum": 0,
                "clientCount": "0",
                "durationAmount": null
            }))
        }
    
        const isNumeric = /^[0-9]+$/.test(e);
    
        if (!isNumeric && e !== "") {
            setSearchTermError("Неверный формат числа");
          return;
        }

        if (e !== "") {
          setSearchTermError("");
          const newTimeoutId = setTimeout(() => {
            activeBtnState === 0 ? searchRequest(e) : dispatch(setSearchItem(e));
          }, 1000)
          setTimeoutId(newTimeoutId);
        }
      };

      useEffect(() => {
        setSearchTerm("")
      }, [activeBtnState])

    return (
        <div className={styles.statsWrapper}>
            <UIButton type="button" width={"100%"} text={"Экспортировать статистику"} color="var(--white)" background="var(--pink-main)" onClick={buttonHandler}/>
           <div className={styles.inputWrapper}>
               <input
                   id="inputValue"
                   width={"100%"}
                   value={searchTerm}
                   onChange={(e) => handleInputChange(e.target.value)}
                   type="text"
                   name="count"
                   placeholder="Поиск по ID"
               />               
               <button className={styles.inputButton}>
                   <MyIcon image={"search"} width={"17px"} height={"17px"} />
               </button>
           </div>
           {searchTermError && <div style={{ color: 'darkred', marginTop: "-13px", marginBottom: "-13px" }}>{searchTermError}</div>}          
            <ButtonsBlock userId={searchTerm} />
        </div>
    );
};

export default StatsControl;