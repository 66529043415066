import React, { useEffect, useRef, useState } from "react";

import s from "./PrivateStream.module.css";

import { socket } from "../../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCallerInfo,
  selectIsPrivateVideo,
  selectUserProfile,
  setCallerInfo,
} from "../../redux/slices/userSlice";
import { useLocation } from "react-router-dom";
import {
  selectChatIdRoomInVideoCall,
  setChatIdRoomInVideoCall,
  setVideoCallId,
} from "../../redux/slices/webSocketSlice";
import { useToast } from "rc-toastr";
import { selectAuth } from "../../redux/slices/loginLogOutSlice";

function PrivateStream({
  isModal,
  setActiveItem,
  microphoneBlock,
  openFullScreen,
  setIsPrivateVideo,
  privateHostes,
  updatePrivateHostes,
  initialClient,
  remoteVideoRef
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const isAuth = useSelector(selectAuth);
  const userMy = useSelector(selectUserProfile);
  const chatIdRoomInVideoState = useSelector(selectChatIdRoomInVideoCall);
  const localVideoRef = useRef<any>(null);
  const videoContainerRef = useRef<any>(null);

  const getCallInfo = useSelector(selectCallerInfo);
  const getIsPrivateVideo = useSelector(selectIsPrivateVideo);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [selfSockId, setSelfSockId] = useState<string>("");
  const [activeSockets, setActiveSockets] = useState<any[]>([]);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    if (openFullScreen && videoContainerRef.current) {
      enterFullscreen(videoContainerRef.current);
    }
  }, [openFullScreen]);

  const enterFullscreen = (element: any) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  useEffect(() => {
    getIsPrivateVideo &&
      getCallInfo.callerId &&
      getCallInfo.receiverId &&
      callUser(getCallInfo.callerId, getCallInfo.receiverId, userMy.username);
  }, [getIsPrivateVideo, getCallInfo]);

  useEffect(() => {
    privateHostes.events.on("local-stream", async (stream: MediaStream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = await stream;
      }
    });

    privateHostes.events.on("remote-stream", async (stream: MediaStream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = await stream;
      }
    });

    return () => {};
  }, [privateHostes, localVideoRef.current, remoteVideoRef.current]);

  /**
   * Handle mute/unmute micro
   */
  useEffect(() => {
    if (!initialClient) return
    initialClient.toggleAudio(!microphoneBlock);
  }, [microphoneBlock, initialClient]);

  useEffect(() => {
    const onUpdateHostesRooms = (list: any[]) => setActiveSockets(list);
    socket.on("updateActiveSockets", onUpdateHostesRooms);
    return () => {
      socket.off("updateActiveSockets", onUpdateHostesRooms);
    };
  }, [activeSockets]);

  useEffect(() => {
    const onConnect = () => {
      setSelfSockId(socket.id);
      setIsConnected(true);
    };
    const onDisconnect = () => {
      setIsConnected(false)
      onEnd()
      setIsPrivateVideo(false)
    };

    const onAnswer = (data: {
      socketId: string;
      description: RTCSessionDescriptionInit;
      videochat: any;
    }) => {
      if (data.videochat) {
        dispatch(setChatIdRoomInVideoCall(data.videochat.chat.id));
        dispatch(setVideoCallId(data.videochat.id));
        setActiveItem(0);
        socket.emit("videochat", {
          participants: {
            caller: {
              socketId: data.socketId,
              userId: getCallInfo.callerId,
            },
            receiver: {
              socketId: data.socketId,
              userId: getCallInfo.receiverId,
            },
          },
          videochat: data.videochat,
        });
      } else {
        toast.error("Клиент не доступен!");
        dispatch(setIsPrivateVideo(false));
        chatIdRoomInVideoState &&
          socket.emit("leave_room", chatIdRoomInVideoState);
        dispatch(setChatIdRoomInVideoCall(0));

        privateHostes.events.emit("close");
        updatePrivateHostes()
      }
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("answer", onAnswer);

    socket.on("end", onEnd);
    socket.on("endNotify", onEndNotidy);

    return () => {
      socket.off("connect", onConnect);
      socket.off("end", onEnd);
      socket.off("endNotify", onEndNotidy);
      socket.off("answer", onAnswer);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  useEffect(() => {
    if (!isAuth) {
      onEnd();
    }
  }, [isAuth]);

  const onEnd = async () => {
    setIsPrivateVideo( false );
    chatIdRoomInVideoState && socket.emit("leave_room", chatIdRoomInVideoState);
    dispatch(setChatIdRoomInVideoCall(0));
    dispatch(
      setCallerInfo({
        callerId: null,
        receiverId: null,
      })
    );

    privateHostes.events.emit("close");
    updatePrivateHostes()

    remoteVideoRef.current = null;
    localVideoRef.current = null;
  };

  const onEndNotidy = async () => {
    alert('Потеря соединения с собеседником!')
    setIsPrivateVideo( false );

    dispatch(setChatIdRoomInVideoCall(0));
    dispatch(
      setCallerInfo({
        callerId: null,
        receiverId: null,
      })
    );

    privateHostes.events.emit("close");
    updatePrivateHostes()

    remoteVideoRef.current = null;
    localVideoRef.current = null;
  };

  async function callUser(
    callerId: number,
    receiverId: number,
    username: string
  ) {
    try {
      await privateHostes.callUser(callerId, receiverId, username);
    } catch (error) {
      toast.error(
        "Вы не можете подключиться к звонку потому что у вас заблокирован микрофон или камера"
      );
      console.log(error)
    }
  }

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className={s.container}
      ref={videoContainerRef}
      onClick={handleFullscreenToggle}
    >
      <div className={s.wrapper}>
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection:'column' }}>
          <div>
            {/* Local video
             muted={microphoneBlock}
            */}
            {location.pathname === "/video-chat" && (
              <video
                ref={localVideoRef}
                autoPlay
                style={{ width: isModal ? "0%" : "50%", height: "250px" }}
              ></video>
            )}

            {/* Remote video */}
            {/**
              muted={mutedRemote}
             */}
            <video
              ref={remoteVideoRef}
              autoPlay
              controls
              style={{
                width: isModal || openFullScreen ? "100%" : "50%",
                height: openFullScreen ? "100%" : "250px",
              }}
            ></video>
          </div>

          {/* <div style={{ marginLeft: "20px" }}>
            <div>
              <div>
                <input
                  type="checkbox"
                  checked={mutedRemote}
                  onChange={() => setMutedRemote(!mutedRemote)}
                />
                Mute remote video
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PrivateStream;
