import MyInput from "../UI/myInput/MyInput"
import MyButton from "../UI/myButton/MyButton"
import MyIcon from "../UI/myIcon/MyIcon"

import mainImg from "../../images/register_image.png"
import mobileMainImg from "../../images/register_image_mobile.png"

import s from "./LoginUser.module.css"
import {Link, useNavigate} from "react-router-dom"

import {Form, Formik} from 'formik';
import {useToast} from "rc-toastr";

import {signInValidate} from "../../settings/validation"
import {useState} from "react"
import {setAccessToken, setLogin,} from "../../redux/slices/loginLogOutSlice"
import {useDispatch} from "react-redux"
import {login} from "../../api/user";

const validationSchema = signInValidate;

const LoginUser = () => {
    const {toast} = useToast();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [signInErrors, setSignInErrors] = useState({
        email: '',
        password: ''
    });

    const initialValues = {
        email: '',
        password: '',
    };

    const signInHandler = async (signInValues) => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            const {data} = await login(signInValues)
            localStorage.setItem("access_token", data.access_token);
            dispatch(setAccessToken(data.access_token))
            dispatch(setLogin(signInValues));
            navigate("/")

        } catch (error) {
            console.error("err login", error.response.data.message)
            error.response.data.message === "Unauthorized" ? toast.error("Введите корректно данные") : toast.error(error.response.data.message)
        } finally {
            setIsLoading(false);
        }
    }

    const changeHandler = () => {
        setSignInErrors({
            email: '',
            password: '',
        });
    }

    const showToggle = () => {
        setPasswordVisible(!passwordVisible)
    }

    return (
        <div className={s.mainContainer}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => signInHandler(values)}
            >
                {({errors, touched}) => {
                    let err = {
                        email: '',
                        password: ''
                    };

                    if (signInErrors.email || signInErrors.password) {
                        err = signInErrors
                    } else {
                        err = errors
                    }

                    const emailError = err.email && touched.email;
                    const passwordError = err.password && touched.password;

                    return (
                        <Form className={s.form}>
                            <div className={s.container}>
                                <picture className={s.picture}>
                                    <source media="(max-width: 912px)" srcSet={mobileMainImg}/>
                                    <img className={s.background_image} src={mainImg} alt="img"/>
                                </picture>
                                <div className={s.items}>
                                    <div className={s.item}>
                                        <div className={s.itemHeader}>Добро пожаловать</div>
                                        <div style={{display: 'flex', gap: '10px', flexDirection:'column'}}>
                                            <div className={s.inputSettings}>
                                                <MyInput
                                                    type="text"
                                                    name="email"
                                                    placeholder="Почта"
                                                    label={'Email'}
                                                    isError={emailError}
                                                    errorText={err.email}
                                                    resetErrors={changeHandler}
                                                />

                                                <div className={s.icon}>
                                                    <MyIcon image={'email'} width={'22px'} height={'22px'}/>
                                                </div>
                                                {touched.email && errors.email &&
                                                    <div style={{color: 'darkred'}}>{errors.email}</div>}
                                            </div>

                                            <div className={s.inputSettings}>
                                                <MyInput
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    name="password"
                                                    placeholder="Пароль"
                                                    label={'password'}
                                                    isError={passwordError}
                                                    errorText={err.password}
                                                    resetErrors={changeHandler}
                                                />
                                                <div className={s.icon}>
                                                    <MyIcon
                                                        onClick={showToggle}
                                                        image={'password'}
                                                        width={'22px'}
                                                        height={'22px'}
                                                    />
                                                </div>
                                                {touched.password && errors.password &&
                                                    <div style={{color: 'darkred'}}>{errors.password}</div>}
                                            </div>
                                        </div>
                                        <Link className={s.forgotPass} to={"/forgot"}>Забыли пароль?</Link>


                                        <MyButton type="submit" text="Войти" background="" color={"var(--dark-blue)"}/>


                                        <div className={s.notAccountBlock}>
                                            <div>Еще нет аккаунта?</div>
                                            <div className={s.regLink}>
                                                <Link className={s.regLink} to={"/register"}>
                                                    Зарегистрируйтесь
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={s.infoText}>Заходя на сайт, я подтверждаю, что мне исполнилось
                                            18 лет и я прочитал <span>Условия использования</span></div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default LoginUser;