import { Field } from "formik";
import s from "./MyInput.module.css"

const MyInput = ({
    isTime,
    type, 
    placeholder,
    isError,
    resetErrors,
    name,
    onKeyDown,
    onMyChange,
    width,
    color,
    height,
    component,
    rows,
    isInput,
    notReset,
    isMaxLength,
    isdisabled,
    value
}) => {
    const inputHandler = () => {
        !notReset && resetErrors();
    }

    return (
        isTime || isInput ? (
            <input 
            id={name}
            type={type}
            className={`${isInput ? s.inputH : s.inputTime}${isError ? ' error' : ''}`}
            style={{
                width: width ? width : "",
                height: height ? height : "",
                color: color ? color : "#475063"
            }}    
            placeholder={placeholder}
            name={name}
            onInput={inputHandler}
            autoComplete="off"
            disabled={isdisabled}
            onKeyDown={onKeyDown}
            onChange={onMyChange}
            value={value}
        />
        ) : (
            <Field 
                id={name}
                type={type}
                className={`${height ? s.inputH : s.input}${isError ? ' error' : ''}`}
                style={{
                    width: width ? width : "",
                    height: height ? height : "",
                    color: color ? color : "#475063"
                }}       
                maxLength={isMaxLength ? isMaxLength : "500"}         
                placeholder={placeholder}
                name={name}
                onInput={inputHandler}
                onKeyDown={onKeyDown}
                autoComplete="off"
                component={component}
                rows={rows}
                disabled={isdisabled}
            />
        )
    )
}

export default MyInput;