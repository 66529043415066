import MyInput from "../UI/myInput/MyInput";
import MyButton from "../UI/myButton/MyButton";
import MyIcon from "../UI/myIcon/MyIcon";

import mainImg from "../../images/register_image.png"
import mobileMainImg from "../../images/register_image_mobile.png"


import s from "./CreateUser.module.css";

import {Link, useLocation} from "react-router-dom";
import {useState} from "react";
import {signUpValidate} from "../../settings/validation";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {registration} from "../../api/user";
import {useDispatch} from "react-redux";
import {setUserRegister} from "../../redux/slices/registrationSlice";
import {useToast} from "rc-toastr";


const SignUpSchema = signUpValidate;

const CreateUser = () => {
    const dispatch = useDispatch();
    const {search} = useLocation()
    const {toast} = useToast()
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [signUpErrors, setSignUpErrors] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        terms: false
    });

    const initialValues = {
        email: '',
        password: '',
        password_confirmation: '',
        terms: false
    };

    const signUpHandler = async (values) => {
        if (isLoading) {
            return;
        }

        const getReferralFromSearch = (search) => {
            const searchParams = new URLSearchParams(search);
            return searchParams.get("referral-link");
        };

        const referral = getReferralFromSearch(search);
        const {
            email,
            password,
            password_confirmation,
            terms
        } = values;

        const regValues = {
            email: email.trim(),
            password,
            password_confirmation,
            terms,
            code: referral ? referral : null
        }

        try {
            setIsLoading(true);
            const {data} = await registration(regValues)
            dispatch(setUserRegister(regValues))
            toast.success(data.message)
        } catch (error) {
            toast.error(error.response.data.message)
            console.error("res.data.message", error.response.data.message)
        } finally {
            setIsLoading(false);
        }

    };

    const changeHandler = () => {
        setSignUpErrors({
            email: '',
            password: '',
            terms: false,
            password_confirmation: '',
        });
    }

    const showToggle = () => {
        setPasswordVisible(!passwordVisible)
    }

    return (
        <div style={{height: '100%', flex: 1, display: "flex"}}>
            <Formik
                initialValues={initialValues}
                validationSchema={SignUpSchema}
                onSubmit={values => signUpHandler(values)}
            >
                {({errors, touched}) => {

                    let err = {
                        email: '',
                        password: '',
                        password_confirmation: '',
                        terms: false
                    };

                    if (signUpErrors.email || signUpErrors.password || signUpErrors.password_confirmation) {
                        err = signUpErrors
                    } else {
                        err = errors
                    }

                    const emailError = err.email && touched.email;
                    const passwordError = err.password && touched.password;
                    const passwordConfirmError = err.password_confirmation && touched.password_confirmation;

                    return (
                        <Form style={{flex: 1, height: '100%', display: 'flex'}}>
                            <div className={s.container}>
                                <picture className={s.picture}>
                                    <source media="(max-width: 912px)" srcSet={mobileMainImg}/>
                                    <img className={s.background_image} src={mainImg} alt="img"/>
                                </picture>
                                <div className={s.items}>
                                    <div className={s.item}>
                                        <div className={s.itemHeader}>Регистрация</div>
                                        <div className={s.inputWrap}>
                                            <div className={s.inputSettings}>
                                                <MyInput
                                                    type="text"
                                                    name="email"
                                                    placeholder="Почта"
                                                    label={'Email'}
                                                    isError={emailError}
                                                    errorText={err.email}
                                                    resetErrors={changeHandler}
                                                />

                                                <div className={s.icon}>
                                                    <MyIcon image={'email'} width={'22px'} height={'22px'}/>
                                                </div>
                                                {touched.email && errors.email &&
                                                    <div style={{color: 'darkred'}}>{errors.email}</div>}
                                            </div>

                                            <div className={s.inputSettings}>
                                                <MyInput
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    name="password"
                                                    placeholder="Пароль"
                                                    label={'password'}
                                                    isError={passwordError}
                                                    errorText={err.password}
                                                    resetErrors={changeHandler}
                                                />
                                                <div className={s.icon}>
                                                    <MyIcon
                                                        onClick={showToggle}
                                                        image={'password'}
                                                        width={'22px'}
                                                        height={'22px'}
                                                    />
                                                </div>
                                                {touched.password && errors.password &&
                                                    <div style={{color: 'darkred'}}>{errors.password}</div>}
                                            </div>

                                            <div className={s.inputSettings}>
                                                <MyInput
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    name="password_confirmation"
                                                    placeholder="Повторите пароль"
                                                    label={'password_confirmation'}
                                                    isError={passwordConfirmError}
                                                    errorText={err.password_confirmation}
                                                    resetErrors={changeHandler}
                                                />
                                                <div className={s.icon}>
                                                    <MyIcon
                                                        onClick={showToggle}
                                                        image={'password'}
                                                        width={'22px'}
                                                        height={'22px'}
                                                    />
                                                </div>
                                                {touched.password_confirmation && errors.password_confirmation && <div
                                                    style={{color: 'darkred'}}>{errors.password_confirmation}</div>}
                                            </div>
                                        </div>
                                        <div className={s.infoText}>
                                            <label className={s.checkboxLabel}>
                                                <Field type="checkbox" name="terms"/>
                                                <span className={s.checkboxText}>
                                            Я подтверждаю, что мне исполнилось 18 лет и я прочитал{" "}
                                                    <span>Условия использования</span>
                                            </span>
                                            </label>
                                            <ErrorMessage name="terms" component="div" style={{color: 'darkred'}}/>
                                        </div>

                                        <MyButton type="submit" text="Зарегистрироваться" background=""
                                                  color={"var(--dark-blue)"}/>

                                        <div className={s.notAccountBlock}>
                                            <div>Уже есть аккаунт?</div>
                                            <div>
                                                <Link className={s.regLink} to={"/"}>Войдите</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default CreateUser;