import MyInput from "../UI/myInput/MyInput"
import MyButton from "../UI/myButton/MyButton"
import MyIcon from "../UI/myIcon/MyIcon"

import mainImg from "../../images/register_image.png"
import mobileMainImg from "../../images/register_image_mobile.png"

import s from "./ForgotPass.module.css"
import { Link } from "react-router-dom"
import { useState } from "react"
import { Form, Formik } from "formik"
import { forgotPasswordValidate } from "../../settings/validation"
import { forgot_password } from "../../api/user"
import { useToast } from "rc-toastr"

const RessetPasswordSchema = forgotPasswordValidate;

const ForgotPass = () => {
  const { toast } = useToast()
    const [isLoading, setIsLoading] = useState(false);
    const [forgotErrors, setForgotErrors] = useState({
        email: '',
      });

      const initialValues = {
        email: '',
      };

      const forgotHandler = async (values) => {
        if (isLoading) {
          return;
        }
        const {
          email,
        } = values;
    
    
        const emailVal = {
          email: email.trim(),
        };

        try {
          setIsLoading(true);
          await forgot_password(emailVal)
          toast.success("Для восстановления перейдите на почту")
        } catch (error) {
          console.error("forgotHandler", error)
        } finally {
          setIsLoading(false);
      }
      };

      const changeHandler = () => {
        setForgotErrors({
          email: '',
        });
      } 

      return (
        <div className={s.mainContainer}>
            <Formik
              initialValues={initialValues}
              validationSchema={RessetPasswordSchema}
              onSubmit={values => forgotHandler(values)}
            >
              {({ errors, touched }) => {

                let err = {
                  email: '',
                };

                if (forgotErrors.email) {
                  err = forgotErrors
                } else {
                  err = errors
                }

                const emailError = err.email && touched.email;

                return (
                    <Form className={s.form}>
                        <div className={s.container}>
                            <picture className={s.picture}>
                                <source media="(max-width: 912px)" srcSet={mobileMainImg} />
                                <img className={s.background_image} src={mainImg} alt="img" />
                            </picture>
                            <div className={s.items}>
                                <div className={s.item}>
                                    <div className={s.itemHeader}>Восстановить пароль</div>
                                    <div className={s.infoTextContainer}>
                                        <div className={s.infoText}>Укажите вашу эллектронную почту для получения пароля</div>
                                    </div>
    
                                    <div className={s.inputSettings}>
                                        <MyInput 
                                            type="text"
                                            name="email"
                                            placeholder="Почта"
                                            label={'Email'}
                                            isError={emailError}
                                            errorText={err.email}
                                            resetErrors={changeHandler}                                        
                                        />
    
                                        <div className={s.icon}>
                                            <MyIcon image={'email'} width={'22px'} height={'22px'} />
                                        </div>
                                        {touched.email && errors.email && <div style={{ color: 'darkred' }}>{errors.email}</div>}
                                    </div>
    
                                    <MyButton type="submit" text="Отправить" background="" color={"var(--dark-blue)"} />
    
                                    <Link to={"/"}>
                                        <MyButton 
                                            text="Назад"
                                            border={"1px solid #62708E"}
                                            color={"#62708E"}
                                            background={"#051423"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
    
                    </Form>
                )
              }}
            </Formik>
        </div>
    )
}

export default ForgotPass;