
import React from 'react'
import ReferralTab from '../../components/referralTab/ReferralTab'


const index = () => {

  return (
      <ReferralTab />
  )
}

export default index