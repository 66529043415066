import React, { useState } from "react";
import s from "./ModalChangeEmail.module.css";
import MyIcon from "../../UI/myIcon/MyIcon";
import { Form, Formik } from "formik";
import MyInput from "../../UI/myInput/MyInput";
import MyButton from "../../UI/myButton/MyButton";
import { resetEmailValidate } from "../../../settings/validation";
import { change_email } from "../../../api/user";
import { useToast } from "rc-toastr";
import { useDispatch } from "react-redux";
import { setLogOut } from "../../../redux/slices/loginLogOutSlice";
import { useNavigate } from "react-router-dom";

const changePasswordSchema = resetEmailValidate;

const ModalChangeEmail = ({ isOpen, onClose }) => {
  //
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailErr, setEmailErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changeEmailErrors, setChangeEmailErrors] = useState({
    email: "",
  });

  const initialValues = {
    email: "",
  };

  const changeEmailHandler = async (values) => {
    if (isLoading) {
      return;
    }

    const { email } = values;

    try {
      setIsLoading(true);
      await change_email({ email });
      localStorage.removeItem("access_token");
      dispatch(setLogOut());
      navigate("/");
      closeModal();
    } catch (error) {
      console.error("error changeEmailHandler", error.response.data.message);
      setEmailErr(error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const changeHandler = () => {
    setChangeEmailErrors({
      email: "",
    });
  };

  if (!isOpen) return null;

  const closeModal = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePasswordSchema}
      onSubmit={(values) => changeEmailHandler(values)}
    >
      {({ errors, touched }) => {
        let err = {
          email: "",
        };

        if (changeEmailErrors.email) {
          err = changeEmailErrors;
        } else {
          err = errors;
        }

        const emailError = err.email && touched.email;

        return (
          <Form>
            <div className={s.modalOverlay} onClick={handleOverlayClick}>
              <div className={s.modal}>
                <button
                  type="button"
                  className={s.modalClose}
                  onClick={onClose}
                >
                  <MyIcon image={"close"} width={"16px"} height={"16px"} />
                </button>
                <div>
                  <h2 className={s.modalHeader}>Изменить почту</h2>
                  <div className={s.modalText}>
                    Мы вышлем вам письмо на указаную почту для ее подтверждения
                  </div>

                  <div className={s.inputSettings}>
                    <MyInput
                      type={"text"}
                      name="email"
                      placeholder="Новая почта"
                      label={"email"}
                      isError={emailError}
                      errorText={err.email}
                      resetErrors={changeHandler}
                    />
                    <div className={s.icon}>
                      <MyIcon image={"email"} width={"22px"} height={"22px"} />
                    </div>
                    {touched.email && errors.email && (
                      <div style={{ color: "darkred" }}>{errors.email}</div>
                    )}
                  </div>

                  <div className={s.button}>
                    <MyButton
                      width={"100%"}
                      text={"Изменить почту"}
                      background={"#FFDC64"}
                      color={"#051423"}
                    />
                  </div>

                  {emailErr !== "" && (
                    <div style={{ color: "darkred" }}>{emailErr}</div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ModalChangeEmail;
