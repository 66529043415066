import {useEffect, useState} from "react";
import cross from "./img/cross.svg";
import s from "./SubMenu.module.css";
import {friend_add, friend_del, post_create_chat, user_friend_check,} from "../../api/chats";
import {useDispatch, useSelector} from "react-redux";
import {setChatIdRoom, setUserNikName, setUserTime,} from "../../redux/slices/webSocketSlice";
import { selectUserProfile, setFromPublicChat, setUserNikNameFromPublic } from "../../redux/slices/userSlice";

export const SubMenu = (props) => {
    const {
        userId,
        setModalComplainingOpen,
        openProfileInfo,
        setIsOpenMiniMenu,
        isPublic,
        userName,
        submenuRef,
        withOutProfile,
        isLastMsg,
        setPrivateChatRoute,
        setPublicChatSelected,
        setChatId,
        setUserName,
        getMessageInfo
    } = props;
    const dispatch = useDispatch();
    const userMy = useSelector(selectUserProfile)
    const [isLoading, setIsLoading] = useState(false);
    const [checkFriend, setCheckFriend] = useState(false);
    const isOpen = () => setModalComplainingOpen(true);

    const getFriends = async () => {
        try {
            const {data} = await user_friend_check({userId});
            setCheckFriend(data.data.status);
        } catch (error) {
            console.error("error tafe friends", error);
        }
    };

    useEffect(() => {
        getFriends();
    }, [userId]);

    const deleteFriend = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            await friend_del({userId});
            setCheckFriend(0);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };
    const addToFriend = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            await friend_add({userId});
            setCheckFriend(2);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
   
    const createChat = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true)
            const { data } = await post_create_chat({ userId })
            setPrivateChatRoute && setPrivateChatRoute(false)
            setPublicChatSelected && setPublicChatSelected(false)
            dispatch(setChatIdRoom(data.data.id))
            setChatId && setChatId(data.data.id)
            setUserName && setUserName(userName)
            getMessageInfo && getMessageInfo(data.data.id, 1)
            dispatch(setUserNikNameFromPublic(userName))
            dispatch(setUserTime(data.data.createdAt))
            dispatch(setFromPublicChat(userId))
        } catch (error) {
            console.error("createChat error", error)
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <div
            ref={submenuRef}
            style={{display: isOpen ? "block" : "none", top: isLastMsg ? '-143px' : '33px'}}
            className={s.modalOverlay}
        >
            <div className={s.modalContent}>
                {isPublic && (
                    <div>
                        <div className={s.username}>{userName}</div>
                        <div className={s.idNum}>
                            ID: <span>{userId}</span>
                        </div>
                        <div className={s.line}></div>
                    </div>
                )}

                {Number(userMy.id) !== Number(userId) && (
                    <>
                        <img
                        onClick={() => {
                            setIsOpenMiniMenu(false);
                        }}
                        src={cross}
                        alt="cross"
                    />

                    {checkFriend === 0 && <p onClick={addToFriend}>Добавить в друзья</p>}
                    {checkFriend === 1 && <p onClick={deleteFriend}>Удалить из друзей</p>}
                    {checkFriend === 2 && <p>Ожидает подтверждение</p>}
                    {!isPublic && !withOutProfile && (
                        <p onClick={openProfileInfo}>Посмотреть профиль</p>
                    )}
                    <p onClick={createChat}>Отправить сообщение</p>
                    <p onClick={isOpen}>Пожаловаться</p>
                        </>
                    )}
            </div>
        </div>
    );
};
