import { memo } from 'react';

import Sprite from '../../../images/sprite.svg';

import s from './MyIcon.module.css';

const MyIcon = ({ image, width, height, color, onClick, fill, style }) => (
  <svg style={style} className={`${onClick ? s.clicked : ''}`} width={width} height={height} fill={fill} color={color} onClick={onClick}>
    <use href={Sprite + `#${image}`} />
  </svg>
);

export default memo(MyIcon);