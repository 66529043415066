import React, {useEffect, useRef, useState} from 'react'
import s from "./Chat.module.css"
import {useWebConnectionSocket} from '../../../socket/webSocketConnection';
import {Message} from '../../message/Message'
import send from '../img/send.svg'
import voice from '../img/voice.svg'
import microphoneOff from '../img/microphone-off.svg'
import {createUniqueArray} from '../../../settings/helpers';
import {useSelector} from 'react-redux';
import {selectUserId} from '../../../redux/slices/userSlice';

const Chat = ({
                  getMessageInfo,
                  setPrivateChat,
                  privateChat,
                  chatId,
                  pageLast,
                  checkRequest,
                  page,
                  setCheckMessageStatus,
                  setCheckMessagesStatus,
                  getChatCount,
                  setReciveScrol,
                  upScroll,
                  setUpScroll,
                  reciveScrol,
                  setMicrophoneBlock,
                  microphoneBlock,
              }) => {
    const containerRef = useRef(null);
    const userMyIdState = useSelector(selectUserId)
    const [messageInput, setMessageInput] = useState('');
    const [checkMessage, setCheckMessage] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const socketConnection = useWebConnectionSocket();
    const [voiceActive, setVoiceActive] = useState(false)

    const MAX_CHARACTER_LIMIT = 160;

    useEffect(() => {
        if (socketConnection && chatId) {
            socketConnection.emit('join_room', chatId);
            socketConnection.on('receive_message', (message) => {
                if (Number(chatId) === Number(message.chat.id)) {
                    message.sender.id !== userMyIdState && socketConnection.emit('read_message', {
                        messageId: message.id,
                        chatId
                    });
                    const res = {
                        "id": message.id,
                        "text": message.text,
                        "status": message.status,
                        "createdAt": message.createdAt,
                        "updatedAt": message.updatedAt,
                        "sender": message.sender
                    };
                    setPrivateChat((prev) => [...prev, res]);
                }
            });

            socketConnection.on("message_readed", (message) => {
                setCheckMessageStatus(message)
            })

            socketConnection.on("messages_readed", (message) => {
                setCheckMessagesStatus(message)
            })
        }

    }, [chatId, containerRef.current]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }

    }, [page, pageLast, privateChat]);

    useEffect(() => {
        if (reciveScrol && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }

        if (upScroll && containerRef.current) {
            containerRef.current.scrollTop = 443;
        }

    }, [reciveScrol, upScroll, privateChat])

    useEffect(() => {
        if (checkMessage && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [checkMessage])

    useEffect(() => {
        if (messageInput !== '') {
            setCheckMessage(false)
            setUpScroll(false)
            setReciveScrol(false)
        }
    }, [messageInput])


    const handleScroll = () => {
        const container = containerRef.current;
        let timer = 0;

        if (page <= pageLast && !checkRequest) {
            if (container && container.scrollTop === 0) {
                setCheckMessage(false)
                timer = setTimeout(() => {
                    getMessageInfo(chatId, page)
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }

                    const id = setTimeout(() => {
                        getChatCount(chatId);
                    }, 1000);

                    setTimeoutId(id);
                }, 300);
            }
        }

        return () => {
            clearTimeout(timer);
        };
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            sendMessage();
        }
    };

    const sendMessage = () => {
        setCheckMessage(true)
        setReciveScrol(true)
        if (socketConnection && messageInput.trim() !== '') {
            const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');
            socketConnection.emit('send_message', {chatId, message: cleanedMessageInput});
            setMessageInput('');
        }
    };


    const microPhoneToggle = () => setMicrophoneBlock(!microphoneBlock)

    return (
        <div className={s.messagesList}>
            <div ref={containerRef} className={s.container}>
                {privateChat &&
                    createUniqueArray(privateChat, "id").map((item, index) => (
                        <Message
                            text={item.text}
                            isMyMessage={userMyIdState === item.sender?.id}
                            key={index}/>
                    ))}
            </div>
            <div className={s.newMsg}>
                <input
                    type="text"
                    style={{marginRight: 10}}
                    placeholder="Введите сообщение"
                    value={messageInput}
                    onChange={(e) => {
                        if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                            setMessageInput(e.target.value);
                        }
                    }}
                    onKeyDown={handleKeyDown}
                />
                <img onClick={microPhoneToggle} src={microphoneBlock ? microphoneOff : voice}
                     alt="microphoneOff"/>
                <p>{messageInput.length}/{MAX_CHARACTER_LIMIT}</p>
                <img onClick={sendMessage} src={send} alt="send"/>
            </div>
        </div>

    )
}

export default Chat