import React, { useEffect, useState } from 'react';
import s from './CommandsList.module.css';
import MyIcon from '../../../UI/myIcon/MyIcon';
import {
  delete_create_team,
  delete_user_from_team,
  post_create_team_complaints,
} from '../../../../api/referral';
import PaginationTeamsList from '../paginationTeamsList/PaginationTeamsList';
import ModalUserProfileInfo from '../../../modals/modalProfile/ModalUserProfileInfo';
import { useDispatch } from 'react-redux';
import { post_create_chat } from '../../../../api/chats';
import {
  setChatIdRoom,
  setUserNikName,
  setUserTime,
} from '../../../../redux/slices/webSocketSlice';
import { useNavigate } from 'react-router-dom';
import { user_info } from '../../../../api/user';
import {
  setUserProfile,
  setUserReferalId,
} from '../../../../redux/slices/userSlice';
import ModalInfo from '../../../modals/modalInfo/ModalInfo';

const CommandsList = ({
  teamsListIsUpdate,
  setTeamsListIsUpdate,
  setRatesListIsUpdate,
  myTeam,
  team,
  hasTeam,
  setHasTeam,
  getTeams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProfileInfo, setIsProfileInfo] = useState(false);
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userOther, setUserOther] = useState({});
  const [myUser, setMyUser] = useState({});
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState('');

  const openProfileInfo = () => {
    setIsProfileInfo(true);
  };

  const closeProfileInfo = () => {
    setIsProfileInfo(false);
  };

  const openModalInfo = () => {
    setIsModalInfo(true);
  };

  const closeModalInfo = () => {
    setIsModalInfo(false);
  };

  const userMy = async () => {
    try {
      const { data } = await user_info();
      setMyUser(data.data);
      dispatch(setUserProfile(data.data));
    } catch (error) {
      console.error('userMy', error);
    }
  };

  const createMessage = async (userId, userName) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await post_create_chat({ userId });
      dispatch(setChatIdRoom(data.data.id));
      dispatch(setUserNikName(userName));
      dispatch(setUserTime(data.data.createdAt));
      dispatch(setUserReferalId(userId));
      navigate('/chat');
    } catch (error) {
      console.error('createMessage error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTeam = (teamName) => {
    setModalInfo(
      'Распустить команду',
      `Вы уверены что хотите распустить команду ${teamName}`,
      'Да, распустить',
    );
  };

  const handleDeleteTeam = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await delete_create_team();
      setHasTeam(false);
      getTeams();
      setRatesListIsUpdate(true);
    } catch (error) {
      console.error('deleteTeam ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFromTeam = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await delete_user_from_team({ memberId: userOther.userId });
      getTeams();
    } catch (error) {
      console.error('deleteFromTeam ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const reportToUser = async (val) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await post_create_team_complaints({
        type: text,
        userId: userOther.userId,
        text: val,
      });
    } catch (error) {
      console.error('reportToUser ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserProfile = async (user) => {
    setUserOther(user);
    setIsProfileInfo(true);
  };

  const setModalInfo = (title, text, buttonText) => {
    setButtonText(buttonText);
    setTitle(title);
    setText(text);
    openModalInfo();
  };

  const handleSubmit = (val) => {
    closeModalInfo();
    setIsProfileInfo(false);
    title === 'Распустить команду' && handleDeleteTeam();
    title === 'Удалить из команды' && deleteFromTeam();
    title === 'Пожаловаться' && reportToUser(val);
  };

  useEffect(() => {
    userMy();
  }, []);

  return (
    <div className={s.container}>
      {isModalInfo && (
        <ModalInfo
          isOpen={openModalInfo}
          onClose={closeModalInfo}
          title={title}
          text={text}
          buttonText={buttonText}
          handleSubmit={handleSubmit}
          placeholder={title === 'Пожаловаться' ? 'Опишите свою жалобу' : ''}
        />
      )}

      {isProfileInfo ? (
        <div className={s.profile}>
          <div className={s.wrapperProfileHeader}>
            <div
              onClick={() => setIsProfileInfo(false)}
              className={s.iconClick}
            >
              <MyIcon image="leftArrow" width={'20px'} height={'20px'} />
            </div>
            {hasTeam &&
              myUser.id !== userOther.userId &&
              myTeam.team?.owner?.id === myUser?.id &&
              (window.innerWidth > 1100 ? (
                <div className={s.profileButtons}>
                  <div
                    onClick={() =>
                      setModalInfo(
                        'Пожаловаться',
                        `Укажите причину жалобы на участницу ${userOther.username}`,
                        'Пожаловаться',
                      )
                    }
                    className={s.profileButton}
                  >
                    Пожаловаться
                  </div>
                  {/* <div className={s.profileButton}>Выписать штраф</div> */}
                  <div
                    onClick={() =>
                      setModalInfo(
                        'Удалить из команды',
                        `Вы уверены что хотите удалить ${userOther.username} из команды “${myTeam.team.name}”?`,
                        'Да, удалить',
                      )
                    }
                    className={s.profileButton}
                  >
                    Удалить из команды
                  </div>
                </div>
              ) : (
                <div
                  onClick={() =>
                    createMessage(userOther.userId, userOther.username)
                  }
                  className={s.iconClick}
                >
                  <MyIcon
                    image="createMessage"
                    width={'32px'}
                    height={'32px'}
                  />
                </div>
              ))}
          </div>
          <div className={s.wrapperProfile}>
            <ModalUserProfileInfo
              userData={userOther.userId}
              hasTeam={hasTeam}
              userDataMyId={myUser.id}
              isNotModal
              isOpen={openProfileInfo}
              onClose={closeProfileInfo}
              requestName={createMessage}
            />
          </div>
        </div>
      ) : (
        <>
          {hasTeam ? (
            <div className={s.wrapperHeader}>
              <div className={s.header}>
                <div className={s.headerName}>
                  Ваша команда "{myTeam?.team?.name}"
                </div>
                {myTeam.team?.owner?.id === myUser?.id &&
                  window.innerWidth > 1100 && (
                    <div
                      onClick={() => deleteTeam(myTeam?.team?.name)}
                      className={s.headerText}
                    >
                      Распустить команду
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div className={s.teams}>
              <MyIcon image={'teamAdd'} width={'20px'} height={'15px'} />
              Присоединится к команде
            </div>
          )}

          {hasTeam && (
            <div className={s.wrapperHeaderSecond}>
              <div className={s.headerSecond}>
                {window.innerWidth > 1100 ? (
                  <div className={s.headerCountFirst}>
                    <div>Всего:</div>
                    <div>
                      {myTeam && myTeam.members ? myTeam.members.length : 0}/10
                      участниц
                    </div>
                  </div>
                ) : (
                  <div className={s.headerCountFirst}>
                    <div>Всего:</div>
                  </div>
                )}

                <div className={s.headerCount}>
                  <MyIcon image={'starFill'} width={'16px'} height={'16px'} />
                  {myTeam.teamTotalStats?.avgRating.toFixed(2)}
                </div>
                <div className={s.headerCount}>
                  <MyIcon image={'miniLogo'} width={'19px'} height={'19px'} />x{' '}
                  {myTeam.teamTotalStats?.totalHearts.toFixed(2)}
                </div>
                <div className={s.headerCount}>
                  <MyIcon image={'likeBad'} width={'19px'} height={'19px'} />
                  {myTeam.teamTotalStats?.countComplaints}
                </div>
              </div>
            </div>
          )}

          {hasTeam && window.innerWidth <= 1100 && (
            <div className={s.addHeader}>
              {myTeam && myTeam.members ? myTeam.members.length : 0}/10 участниц
            </div>
          )}

          {!hasTeam && team && team.length !== 0 && (
            <div className={s.pagination}>
              <PaginationTeamsList
                teamsListIsUpdate={teamsListIsUpdate}
                setTeamsListIsUpdate={setTeamsListIsUpdate}
              />
            </div>
          )}

          {myTeam &&
            myTeam.members &&
            myTeam.members.map((user, index) => (
              <div key={index + user?.username} className={s.wrapperMain}>
                <div onClick={() => getUserProfile(user)} className={s.main}>
                  {window.innerWidth > 1100 ? (
                    <div className={s.mainT}>
                      <div className={s.mainFirst}>
                        <div>{index + 1}</div>
                        <div className={s.avatarContainer}>
                        {user.avatar ? (
                            <img className={s.avatar} style={{ objectFit: "cover" }} src={user.avatar} alt="ava"/>
                          ) : (
                            <MyIcon
                              image={"avatar"}
                              width={"33.5px"}
                              height={"33.5px"}
                            />
                          )}
                          </div>
                        <div className={s.mainUser}>
                          <MyIcon
                            image={'starFill'}
                            width={'43px'}
                            height={'43px'}
                          />
                          {user?.username}
                        </div>
                      </div>

                      <div className={s.headerCount}>
                        <MyIcon
                          image={'starFill'}
                          width={'16px'}
                          height={'16px'}
                        />
                        {Number(user?.avgRating).toFixed(1)}
                      </div>
                      <div className={s.headerCount}>
                        <MyIcon
                          image={'miniLogo'}
                          width={'19px'}
                          height={'19px'}
                        />
                        x {user?.totalHearts.toFixed(2)}
                      </div>
                      <div className={s.headerCount}>
                        <MyIcon
                          image={'likeBad'}
                          width={'19px'}
                          height={'19px'}
                        />
                        {user?.countComplaints}
                      </div>
                    </div>
                  ) : (
                    <div className={s.mainT}>
                      <div className={s.mainFirstAdd}>
                        <div>{index + 1}</div>
                        <div className={s.avatarContainer}>
                          {user.avatar ? (
                            <img className={s.avatar} style={{ objectFit: "cover" }} src={user.avatar} alt="ava"/>
                          ) : (
                            <MyIcon
                              image={"avatar"}
                              width={"43.5px"}
                              height={"43.5px"}
                            />
                          )}
                        </div>
                        <div className={s.mainUser}>
                          <MyIcon
                            image={'starFill'}
                            width={'53px'}
                            height={'53px'}
                          />
                        </div>
                      </div>

                      <div className={s.mainSecond}>
                        <div>{user?.username}</div>
                        <div className={s.mainMobile}>
                          <div className={s.headerCountAdd}>
                            <MyIcon
                              image={'starFill'}
                              width={'16px'}
                              height={'16px'}
                            />
                            {Number(user?.avgRating).toFixed(1)}
                          </div>
                          <div className={s.headerCountAdd}>
                            <MyIcon
                              image={'miniLogo'}
                              width={'19px'}
                              height={'19px'}
                            />
                            x {user?.totalHearts.toFixed(2)}
                          </div>
                          <div className={s.headerCountAdd}>
                            <MyIcon
                              image={'likeBad'}
                              width={'19px'}
                              height={'19px'}
                            />
                            {user?.countComplaints}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

          {hasTeam &&
            myTeam.team?.owner?.id === myUser?.id &&
            window.innerWidth <= 1100 && (
              <div className={s.headerTextFooterContainer}>
                <div
                  onClick={() => deleteTeam(myTeam?.team?.name)}
                  className={s.headerTextFooter}
                >
                  Распустить команду
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default CommandsList;
