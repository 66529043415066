import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    filters: "allChats",
    userSearch: ""
  },
  reducers: {
    setFilters: (state, action) => {
        state.filters = action.payload;
    },
    setUserSearch: (state, action) => {
      state.userSearch = action.payload
    }
  }
})

export const selectFilters = (state) => state.search.filters;
export const selectUserSearch = (state) => state.search.userSearch;

export const {
  setFilters,
  setUserSearch,
} = searchSlice.actions

export default searchSlice.reducer