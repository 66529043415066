import styles from './styles.module.css'

export const Tooltip = ({text, position}) => (
    <div style={{
        position: 'absolute',
        right: position === 'top' ? '-40px' : '-14px',
        bottom: position === 'top' ? '34px' : '-131px',
    }}
         className={styles.wrap}>
        <img style={{
            width: '20px',
            position: 'absolute',
            top: position === 'top' ? '115px' : '-14px',
            right: position === 'top' ? '38px' : '16px',
            rotate: position === 'top' ? '' : '180deg'
        }}
             src="/img/cross.svg"
             alt="cross"
        />
        {text}
    </div>
)