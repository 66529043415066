import React, { useEffect, useState } from "react";
import s from "./ScheduleTab.module.css";
import ScheduleItem from "./scheduleItem/ScheduleItem";
import { getSchedule } from "../../api/schedule";
import { user_info } from "../../api/user";
import { groupDataByDate, parseTimeZoneOffset } from "../../settings/helpers";
import { useSelector } from "react-redux";
import { selectTimeZone } from "../../redux/slices/userSlice";

const ScheduleTab = () => {
  const selectTimeZoneState = useSelector(selectTimeZone);
  const takeZoneIfCheck = localStorage.getItem("timeZones");
  const [scheduleData, setScheduleData] = useState(null);
  const [userDataMyId, setUserDataMyId] = useState(null);
  const [userDataRating, setUserDataRating] = useState(null);
 
    const getScheduleData = async (isUTC) => {
        try {
            const { data } = await getSchedule()
            const selectTimeZoneOffset = isUTC ? parseTimeZoneOffset(selectTimeZoneState) : 0;
            const userUtcOffset = isUTC ? 0 : new Date().getTimezoneOffset() * -1;
            const today = new Date();
            const twoWeeksLater = new Date(today);

            twoWeeksLater.setDate(today.getDate() + 13);
            if (isUTC) {
                today.setMinutes(selectTimeZoneOffset > 0 ? today.getMinutes() + selectTimeZoneOffset : today.getMinutes() - selectTimeZoneOffset * -1)
                twoWeeksLater.setMinutes(selectTimeZoneOffset > 0 ? twoWeeksLater.getMinutes() + selectTimeZoneOffset : twoWeeksLater.getMinutes() - selectTimeZoneOffset * -1)
            } else {
                today.setMinutes(userUtcOffset > 0 ? today.getMinutes() + userUtcOffset : today.getMinutes() - selectTimeZoneOffset * -1)
                twoWeeksLater.setMinutes(userUtcOffset > 0 ? twoWeeksLater.getMinutes() + userUtcOffset : twoWeeksLater.getMinutes() - userUtcOffset * -1)
            }
            const filteredData = data.data.filter(item => {
                const startTime = new Date(item.timeStart);

                if (isUTC) {
                    startTime.setMinutes(selectTimeZoneOffset > 0 ? startTime.getMinutes() + selectTimeZoneOffset : startTime.getMinutes() - selectTimeZoneOffset * -1)
                } else {
                    startTime.setMinutes(userUtcOffset > 0 ? startTime.getMinutes() + userUtcOffset : startTime.getMinutes() - userUtcOffset * -1)
                }

                const isToday = startTime.toLocaleDateString() === today.toLocaleDateString()
                const isLastDay = startTime.toLocaleDateString() === twoWeeksLater.toLocaleDateString();

                if (isToday) {
                    return true
                }
                
                return isLastDay || (startTime > today && startTime < twoWeeksLater);
              });

              const scheduleDataWithTimeZone = isUTC ? groupDataByDate(filteredData, selectTimeZoneOffset) : groupDataByDate(filteredData, userUtcOffset);
            
              setScheduleData(scheduleDataWithTimeZone)
        } catch (error) {
      console.error("getScheduleData error");
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await user_info();
      setUserDataMyId(data.data.id);
      setUserDataRating(data.data.rating);
    } catch (error) {
      console.error("getUserData error");
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

    useEffect(() => {
        if (selectTimeZoneState) {
            getScheduleData(true)           
        } else {
            getScheduleData(false)
        }
    }, [selectTimeZoneState])

  return (
    <div className={s.container}>
      <ScheduleItem
        items={scheduleData}
        getScheduleData={getScheduleData}
        userDataMyId={userDataMyId}
        userDataRating={userDataRating}
      />
    </div>
  );
};

export default ScheduleTab;
